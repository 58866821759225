import { RefObject, useEffect, useRef, useState } from 'react';

export default function useClickOutside<T extends HTMLElement>(): [
  RefObject<T>,
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>,
] {
  const ref = useRef<T>(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleOutsideClick = (event: MouseEvent) => {
    if (!ref.current?.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return [ref, isOpen, setIsOpen];
}
