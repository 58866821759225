/* eslint-disable @typescript-eslint/ban-ts-comment */
import { SerializerError } from 'errors';
import { VisitsByInjuryTypeResponse } from 'types';

import { VisitsByInjuryType } from '../visits-by-injury-type.model';

export class VisitsByInjurySerializer {
  deserialize(target: VisitsByInjuryTypeResponse): VisitsByInjuryType {
    const {
      info: { labels, data },
    } = target;

    const labelsAreString = labels.every((label) => typeof label === 'string');
    if (!labelsAreString) {
      throw new SerializerError(
        'VisitsByInjurySerializer',
        'label items are expected to be string',
      );
    }

    if (Object.keys(data).length !== labels.length) {
      throw new SerializerError(
        'VisitsByInjurySerializer',
        'data items are expected to be of the same length as labels',
      );
    }
    const dataIsNumeric = Object.keys(data).every((injury) =>
      Object.keys(data[injury]).every((category) =>
        // @ts-ignore
        Object.keys(data[injury][category]).every(
          // @ts-ignore
          (val) => typeof data[injury][category][val] === 'number',
        ),
      ),
    );
    if (!dataIsNumeric) {
      throw new SerializerError(
        'VisitsByInjurySerializer',
        'all data fields are expected to be numeric',
      );
    }
    const lessThanFiveVisitsDataset: number[] = [];
    const fromSixToTenVisitsDataset: number[] = [];
    const moreThanTenVisitsDataset: number[] = [];
    Object.keys(data).forEach((injuryName) => {
      lessThanFiveVisitsDataset.push(
        data[injuryName].less_than_five.percentage,
      );
      fromSixToTenVisitsDataset.push(
        data[injuryName].between_five_and_ten.percentage,
      );
      moreThanTenVisitsDataset.push(
        data[injuryName].greater_than_ten.percentage,
      );
    });
    return new VisitsByInjuryType({
      labels,
      data: [
        lessThanFiveVisitsDataset,
        fromSixToTenVisitsDataset,
        moreThanTenVisitsDataset,
      ],
      rawData: data,
    });
  }
}
