import './Overlay.css';
import clsx from 'clsx';
import React from 'react';
import ReactDOM from 'react-dom';

export const Overlay: React.FC<JSX.IntrinsicElements['div']> =
  function Overlay({ className, children, ...rest }) {
    const container = window.document.getElementById('overlay');

    if (!container) {
      throw new Error('#modal container is not present on DOM');
    }

    return ReactDOM.createPortal(
      <>
        <div className="progress">
          <h2 hidden>Please wait...</h2>
          {children}
        </div>

        <div className={clsx(['overlay', className])} {...rest} />
      </>,
      container,
    );
  };
