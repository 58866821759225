import clsx from 'clsx';
import React from 'react';
import { useVirtual } from 'react-virtual';

import { Button } from 'components/Button';
import { MinusCircleIcon, PlusCircleIcon } from 'components/Icons';
import { Typography } from 'components/Typography';
import { computeInjuryName } from 'utils';

import { SuccessProps } from '../types';

const TABLE_ROW_HEIGHT = 52;

export const OpenTask: React.FC<SuccessProps> = function Success(props) {
  const {
    model,
    mode,
    onChangeViewMode,
    generalDashboard,
    handlePdfModal,
    handleSignModal,
  } = props;

  const newModel = mode === 'resume' ? model.slice(0, 3) : model;

  // The parent element whose inner-content is scrollable
  const listContainerRef = React.useRef<HTMLUListElement>(null);

  const rowVirtualizer = useVirtual({
    parentRef: listContainerRef,
    size: newModel.length,
    overscan: 10,
    estimateSize: React.useCallback(() => TABLE_ROW_HEIGHT, []),
  });

  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

  return (
    <div className="open-tasks__table-container">
      <div className="open-tasks__table">
        <div className="open-tasks__table-header">
          <div className="open-tasks__table-head">
            <Typography variant="caption" color="light" component="p">
              Patient
            </Typography>
          </div>
          <div className="open-tasks__table-head">
            <Typography variant="caption" color="light" component="p">
              Plan of Care
            </Typography>
          </div>
          <div className="open-tasks__table-head">
            <Typography variant="caption" color="light" component="p">
              Initial Visit
            </Typography>
          </div>
          <div className="open-tasks__table-head">
            <Typography variant="caption" color="light" component="p">
              Visit Type
            </Typography>
          </div>
          <div className="open-tasks__table-head">
            <Typography variant="caption" color="light" component="p">
              Approval
            </Typography>
          </div>
        </div>

        <ul className="open-tasks__table-body" ref={listContainerRef}>
          {virtualRows.map((v) => {
            const openTask = newModel[v.index];
            return (
              <li
                key={openTask.key}
                className={clsx(['open-tasks__table-row'])}
              >
                <Typography variant="caption" component="p" color="abbey">
                  {openTask.patientName}
                </Typography>

                <Typography
                  variant="link"
                  component="p"
                  color="blue-link"
                  onClick={() => handlePdfModal(openTask)}
                >
                  {computeInjuryName(openTask.planOfCare)}
                </Typography>

                <Typography variant="caption" component="p" color="abbey">
                  {openTask.initialVisit}
                </Typography>

                <Typography variant="caption" component="p" color="abbey">
                  {openTask.visitType}
                </Typography>

                <Button
                  variant="primary"
                  className="open-tasks__button"
                  disabled={!generalDashboard}
                  onClick={() => handleSignModal(openTask)}
                >
                  Sign
                </Button>
              </li>
            );
          })}
        </ul>
      </div>

      {model.length > 3 && (
        <div className="open-tasks__show-more-container">
          <button
            type="button"
            className="open-tasks__show-more-button"
            onClick={onChangeViewMode}
          >
            {mode === 'resume' ? (
              <PlusCircleIcon className="open-tasks__show-more-icon" />
            ) : (
              <MinusCircleIcon className="open-tasks__show-more-icon" />
            )}
            <Typography variant="caption" component="p" color="abbey">
              Show {mode === 'resume' ? 'more' : 'less'}
            </Typography>
          </button>
        </div>
      )}
    </div>
  );
};
