import { PropsWithChildren } from 'react';
import './ChartDescription.css';

import { Typography } from 'components/Typography';

export function ChartDescription({
  children,
  ...rest
}: PropsWithChildren<JSX.IntrinsicElements['div']>) {
  return (
    <div className="chart-description" {...rest}>
      <Typography variant="caption" component="p" color="abbey">
        {children}
      </Typography>
    </div>
  );
}
