import { captureException } from '@sentry/browser';
import platform from 'platform';

// eslint-disable-next-line import/no-cycle
import { AppStore } from 'app/routes/Dashboard/app.store';
import { formatClinicName } from 'utils';

import api from './api';

type UserTimeEventKind =
  | 'plan_of_care_click'
  | 'patient_chart'
  | 'opened_charts_modal'
  | 'opened_sessions_modal'
  | 'requested_patient_data_download'
  | 'signed_poc'
  | 'error';

type PocSignKind = 'sign' | 'sign with comment' | 'reject';

interface ExtraFieldsEvent {
  chart_id: string;
  poc_id: string;
  patient_id: string;
  text: string;
  context: string;
  care_plan_id: string;
  poc_sign_kind: PocSignKind;
  poc_comment: string;
}

interface UserTimeEvent {
  dashboard_id: string;
  provider_email: string;
  date: string;
  browser: string;
  provider_name: string;
  patient_name: string;
  provider_id: string;
  provider_kind: string;
  kind: UserTimeEventKind;
  extras?: Partial<ExtraFieldsEvent>;
}

export class MetricLogger {
  constructor(private appStore: AppStore) {}

  // build the metrics object based on local overrides
  async sendMetrics(overrides?: Partial<UserTimeEvent>) {
    if (this.appStore.providerKind === '') {
      return;
    }
    const date = new Date();
    let providerId = '';
    let providerName = '';
    const { providerKind } = this.appStore;
    if (providerKind === 'partner') {
      providerId = this.appStore.partnerId!;
      providerName = this.appStore.partnerName!;
    }
    if (providerKind === 'physician_group') {
      providerName = this.appStore.groupName!;
      providerId = this.appStore.groupId!;
    }

    if (providerKind === 'clinic') {
      providerId = this.appStore.clinicId!;
      providerName = formatClinicName(this.appStore.clinicName!);
    }

    if (providerKind === 'physician') {
      providerId =
        overrides?.provider_id || this.appStore.selectedTherapists![0].value;
      providerName =
        overrides?.provider_name || this.appStore.selectedTherapists![0].label;
    }
    // report any error to sentry
    try {
      const payload: UserTimeEvent = {
        dashboard_id: this.appStore.dashboardId || '',
        provider_email: this.appStore.providerEmail || '',
        browser: `${platform.name} ${platform.version}`,
        date: date.toISOString(),
        provider_id: providerId,
        provider_name: providerName,
        provider_kind: providerKind,
        kind: overrides?.kind || 'error',
        patient_name: overrides?.patient_name || '',
        ...(overrides?.extras &&
          Object.keys(overrides.extras).length > 0 && {
            extras: overrides.extras,
          }),
      };
      await api.post(`/activities`, {
        data: payload,
      });
    } catch (e) {
      captureException(e);
    }
  }
}
