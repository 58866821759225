import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';

Chart.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
  Tooltip,
  ArcElement,
);

export function addChartDefaults() {
  setTimeout(() => {
    Chart.defaults.font.family = "'CircularStd', Arial, Helvetica, sans-serif";
  }, 4);
}
