import { SerializerError } from 'errors';
import { CaseDistributionResponse } from 'types';

import { CaseDistribution } from '../case-distribution.model';

export class CaseDistributionSerializer {
  deserialize(target: CaseDistributionResponse): CaseDistribution {
    const {
      info: { labels, data },
    } = target;

    const labelsAreString = labels.every((label) => typeof label === 'string');
    if (!labelsAreString) {
      throw new SerializerError(
        'CaseDistributionSerializer',
        'label items are expected to be string',
      );
    }

    const dataIsNumeric = data.every((item) =>
      item.every((nestedItem) => typeof nestedItem === 'number'),
    );
    if (!dataIsNumeric) {
      throw new SerializerError(
        'CaseDistributionSerializer',
        'data items are expected to be numeric',
      );
    }
    const chartData: number[] = [];
    const visits: number[] = [];
    data.forEach((d) => {
      chartData.push(d[0]);
      visits.push(d[1]);
    });
    return new CaseDistribution({
      labels,
      data: chartData,
      visits,
    });
  }
}
