import { SerializerError } from 'errors';
import { ChangeInPainLevelResponse } from 'types';

import { ChangeInPainLevel } from '../change-in-pain-level.model';

export class ChangeInPainLevelSerializer {
  deserialize(target: ChangeInPainLevelResponse): ChangeInPainLevel {
    const {
      info: { data, decrease_percentage: counter },
    } = target;

    const dataIsNumeric = data.every((datum) => typeof datum === 'number');
    if (!dataIsNumeric) {
      throw new SerializerError(
        'ChangeInPainLevelSerializer',
        'data items are expected to be numeric',
      );
    }

    const counterIsNumeric = typeof counter === 'number';
    if (!counterIsNumeric) {
      throw new SerializerError(
        'ChangeInPainLevelSerializer',
        'decrease_percentage is expected to be numeric',
      );
    }

    return new ChangeInPainLevel({
      data,
      counter,
    });
  }
}
