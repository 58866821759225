import { format } from 'date-fns';
import React, { useState } from 'react';
import { useVirtual } from 'react-virtual';

import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionItemBody,
  AccordionItemBodyRow,
  AccordionItemHeader,
  AccordionItems,
} from 'components/Accordion';
import { DropdownArrowIcon } from 'components/Icons';
import { Typography } from 'components/Typography';
import {
  EMPTY_CONTAINER_HEIGHT,
  EmptyBody,
} from 'features/recent-patients/views/RecentPatientsTreatedMobile';

import { SuccessProps } from '../types';

import { hubspotMapping } from './utils';

const PATIENTS_TABLE_ROW_HEIGHT = 52;
const ACCORDION_BODY_ROW_HEIGHT = 65;
const PATIENTS_TABLE_ROW_FULL_OPEN =
  PATIENTS_TABLE_ROW_HEIGHT + ACCORDION_BODY_ROW_HEIGHT * 2;

export function DowngradedPatientsMobileView({ model }: SuccessProps) {
  const [indexOpen, setIndexOpen] = useState(-1);
  // dynamic measurement works with react-virtual but only if item size doesn't change after render,
  // with the accordion this is not the case so we declare explicitly the size of given item
  const estimateItemSize = React.useCallback(
    (i: number) => {
      if (i === indexOpen) {
        return PATIENTS_TABLE_ROW_FULL_OPEN;
      }
      return PATIENTS_TABLE_ROW_HEIGHT;
    },
    [indexOpen],
  );

  const containerRef = React.useRef<HTMLDivElement>(null);

  const { virtualItems, totalSize } = useVirtual({
    parentRef: containerRef,
    size: model.length,
    overscan: 10,
    estimateSize: estimateItemSize,
  });

  const isEmpty = virtualItems.length === 0;

  return (
    <Accordion className="recent-patients__accordion" onItemOpen={setIndexOpen}>
      <AccordionHeader className="downgraded-extra-space">
        <Typography variant="caption" color="gray-chateau" component="span">
          Patient
        </Typography>
        <Typography variant="caption" color="gray-chateau" component="span">
          Date of
          <br />
          Referral
        </Typography>
      </AccordionHeader>
      <AccordionItems
        ref={containerRef}
        height={isEmpty ? EMPTY_CONTAINER_HEIGHT : totalSize}
      >
        {isEmpty ? (
          <EmptyBody />
        ) : (
          virtualItems.map((v) => {
            const patient = model[v.index];
            return (
              <AccordionItem
                key={patient.key}
                index={v.index}
                style={{
                  position: 'absolute',
                  top: v.start,
                  left: 0,
                  width: '100%',
                  height: v.size,
                }}
              >
                <AccordionItemHeader>
                  <div className="patient-name-container">
                    <Typography
                      variant="caption"
                      component="p"
                      color="darkness"
                    >
                      {patient.name}
                    </Typography>
                  </div>
                  <div className="patient-session-contaniner">
                    <Typography
                      variant="caption"
                      component="p"
                      color="darkness"
                    >
                      {format(new Date(patient.dateOfReferral), 'MM/dd/yyyy')}
                    </Typography>
                  </div>
                  <DropdownArrowIcon className="arrow-icon" />
                </AccordionItemHeader>
                <AccordionItemBody>
                  <AccordionItemBodyRow>
                    <Typography
                      variant="caption"
                      color="gray-chateau"
                      component="p"
                    >
                      Reason Not
                      <br />
                      Booked
                    </Typography>
                    <Typography
                      variant="caption"
                      component="p"
                      color="darkness"
                    >
                      {hubspotMapping[patient.reasonNotBooked] || ''}
                    </Typography>
                  </AccordionItemBodyRow>
                  <AccordionItemBodyRow>
                    <Typography
                      variant="caption"
                      color="gray-chateau"
                      component="p"
                    >
                      Date of
                      <br />
                      Birth
                    </Typography>
                    <Typography
                      variant="caption"
                      component="p"
                      color="darkness"
                    >
                      {patient.dob
                        ? format(
                            new Date(`${patient.dob}T00:00:00`),
                            'MM/dd/yyyy',
                          )
                        : ''}
                    </Typography>
                  </AccordionItemBodyRow>
                </AccordionItemBody>
              </AccordionItem>
            );
          })
        )}
      </AccordionItems>
    </Accordion>
  );
}
