import './Typography.css';
import clsx from 'clsx';

const defaultVariantMapping = {
  displayMedium: 'h1',
  displaySmall: 'h2',
  heading1: 'h1',
  heading2: 'h2',
  heading3: 'h3',
  subtitle: 'h2',
  body: 'p',
  parragraph: 'p',
  caption: 'small',
  link: 'small',
  captionSmall: 'small',
  inherit: 'p',
};

export type TypographyVariant = keyof typeof defaultVariantMapping;

type TypographyColor =
  | 'initial'
  | 'inherit'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'alert'
  | 'error'
  | 'darkness'
  | 'dark'
  | 'medium'
  | 'light'
  | 'gray-chateau'
  | 'abbey'
  | 'blue-link'
  | 'light-dark';

type FontWeight = 'normal' | 'bold' | 'medium';

export type TypographyProps = JSX.IntrinsicElements['h1'] &
  JSX.IntrinsicElements['h2'] &
  JSX.IntrinsicElements['h3'] &
  JSX.IntrinsicElements['p'] &
  JSX.IntrinsicElements['a'] &
  JSX.IntrinsicElements['label'] &
  JSX.IntrinsicElements['small'] & {
    color?: TypographyColor;
    component?:
      | React.ElementType<React.HTMLAttributes<HTMLElement>>
      | keyof JSX.IntrinsicElements;
    paragraph?: boolean;
    variant?: TypographyVariant;
    fontWeight?: FontWeight;
  };

export const Typography: React.FC<TypographyProps> = function Typography(
  props,
) {
  const {
    className,
    color = 'initial',
    component,
    paragraph = false,
    variant = 'body',
    fontWeight = 'initial',
    ...rest
  } = props;

  const Component =
    component || (paragraph ? 'p' : defaultVariantMapping[variant] || 'span');

  return (
    <Component
      className={clsx(
        [
          'typography',
          variant !== 'inherit' && variant,
          color !== 'initial' && `color--${color}`,
          fontWeight !== 'initial' && `font-weight--${fontWeight}`,
          paragraph && 'parragraph',
        ],
        className,
      )}
      {...rest}
    />
  );
};
