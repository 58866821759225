/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { format } from 'date-fns/format';
import React, { useRef } from 'react';
import { useVirtual } from 'react-virtual';

import {
  Accordion,
  AccordionItem,
  AccordionItemBody,
  AccordionItemHeader,
  AccordionItems,
  AccordionItemState,
} from 'components/Accordion';
import { MinusCircleIcon, PlusCircleIcon } from 'components/Icons';
import { ThumbsIcon } from 'components/ThumbsIcon';
import { Typography } from 'components/Typography';
import { computeInjuryName } from 'utils';

import {
  ChartAttributes,
  ChartBox,
  ChartBoxVisit,
  DownloadMode,
} from '../types';

interface ChartPanelProps {
  groupedCharts: ChartBox[];
  handleChartModal: (url: string, id: string, carePlanId: string) => void;
  handleChartSelected: (c: ChartAttributes, m: 'add' | 'remove') => void;
  downloadMode: DownloadMode;
  canDownload: boolean;
  chartIsSelected: (id: string) => boolean;
}

interface SharedChartBoxProps {
  chart: ChartBoxVisit;
  carePlanId: string;
  selected: boolean;
  onCheck: () => void;
  onUnCheck: () => void;
  downloadMode: DownloadMode;
  handleChartModal: (url: string, id: string, carePlanId: string) => void;
  canDownload: boolean;
}

interface CheckMarkProps {
  selected: boolean;
  onCheck: () => void;
  onUnCheck: () => void;
}

const CheckMark: React.FC<CheckMarkProps> = function Checkbox({
  selected,
  onCheck,
  onUnCheck,
}) {
  if (selected) {
    return (
      <span
        className="checkmark checkmark-blue selected"
        onClick={onUnCheck}
        role="checkbox"
        aria-checked
        tabIndex={0}
      >
        <span className="check" />
      </span>
    );
  }
  return (
    <span
      className="checkmark blank"
      onClick={onCheck}
      aria-checked={false}
      role="checkbox"
      tabIndex={0}
    />
  );
};

const SharedChartBox: React.FC<SharedChartBoxProps> = function SharedChartBox(
  props,
) {
  const {
    chart,
    selected,
    onCheck,
    onUnCheck,
    downloadMode,
    handleChartModal,
    canDownload,
    carePlanId,
  } = props;
  // only if theres an url and can download display the blue link
  const shouldDisplayLink = canDownload && !!chart.pdf_url;
  const hasNoScore =
    chart.satisfaction_score === 'null' || !chart.satisfaction_score;
  return (
    <div className="chart-box">
      <Typography
        variant={shouldDisplayLink ? 'link' : 'parragraph'}
        component="p"
        color={shouldDisplayLink ? 'blue-link' : 'dark'}
        onClick={
          shouldDisplayLink
            ? () => handleChartModal(chart.pdf_url || '', chart.id, carePlanId)
            : undefined
        }
        className="chart-box__date"
      >
        {chart.date}
      </Typography>
      {downloadMode !== DownloadMode.None ? (
        <CheckMark
          selected={selected}
          onCheck={onCheck}
          onUnCheck={onUnCheck}
        />
      ) : hasNoScore ? null : (
        <ThumbsIcon liked={chart.satisfaction_score === 'thumbs_up'} />
      )}
    </div>
  );
};

const CHARTS_ROW_HEIGHT = 52;

function ChartsAccordion(props: ChartPanelProps) {
  const {
    groupedCharts,
    downloadMode,
    canDownload,
    handleChartSelected,
    handleChartModal,
    chartIsSelected,
  } = props;
  function onChartChecked(c: ChartBoxVisit) {
    handleChartSelected(
      {
        id: c.id,
        url: c.pdf_url || '',
        date: format(new Date(c.date), 'dd-MM-yyyy'),
      },
      'add',
    );
  }
  function onChartUnChecked(c: ChartBoxVisit) {
    handleChartSelected(
      {
        id: c.id,
        url: c.pdf_url || '',
        date: format(new Date(c.date), 'dd-MM-yyyy'),
      },
      'remove',
    );
  }
  const containerRef = useRef<HTMLDivElement>(null);

  const { virtualItems, totalSize } = useVirtual({
    parentRef: containerRef,
    size: groupedCharts.length,
    overscan: 10,
  });

  if (groupedCharts.length === 1) {
    const carePlan = groupedCharts[0];
    return (
      <div className="charts-group__accordion">
        <div className="charts-group__title-container">
          <Typography color="primary">
            {computeInjuryName(carePlan.injury_name)}
          </Typography>
          {carePlan.surgery_date && carePlan.surgery_date !== 'null' && (
            <Typography color="gray-chateau" variant="caption">
              DOS: {carePlan.surgery_date}
            </Typography>
          )}
        </div>
        <div className="charts-table-container">
          {carePlan.charts.map((chart) => (
            <SharedChartBox
              carePlanId={carePlan.id}
              key={chart.id}
              chart={chart}
              downloadMode={downloadMode}
              canDownload={canDownload}
              handleChartModal={handleChartModal}
              selected={chartIsSelected(chart.id)}
              onCheck={() => onChartChecked(chart)}
              onUnCheck={() => onChartUnChecked(chart)}
            />
          ))}
        </div>
      </div>
    );
  }

  return (
    <Accordion className="charts-group__accordion" defaultIndexOpen={0}>
      <AccordionItems ref={containerRef} height={totalSize}>
        {virtualItems.map((v) => {
          const gc = groupedCharts[v.index];
          return (
            <AccordionItem index={v.index} key={gc.id} ref={v.measureRef}>
              <AccordionItemHeader>
                <div className="charts-group__header">
                  <AccordionItemState>
                    {({ expanded }) =>
                      expanded ? (
                        <MinusCircleIcon
                          fill="#974EBC"
                          className="charts-group__header-icon charts-group__icon-open"
                        />
                      ) : (
                        <PlusCircleIcon
                          fill="#974EBC"
                          className="charts-group__header-icon"
                        />
                      )
                    }
                  </AccordionItemState>
                  <div className="charts-group__title-container">
                    <Typography color="primary">
                      {computeInjuryName(gc.injury_name)}
                    </Typography>
                    <AccordionItemState>
                      {({ expanded }) =>
                        expanded &&
                        gc.surgery_date &&
                        gc.surgery_date !== 'null' ? (
                          <Typography color="gray-chateau" variant="caption">
                            DOS: {gc.surgery_date}
                          </Typography>
                        ) : null
                      }
                    </AccordionItemState>
                  </div>
                </div>
              </AccordionItemHeader>
              <AccordionItemBody>
                <div className="charts-table-container">
                  {gc.charts.map((chart) => (
                    <SharedChartBox
                      carePlanId={gc.id}
                      key={chart.id}
                      chart={chart}
                      downloadMode={downloadMode}
                      canDownload={canDownload}
                      handleChartModal={handleChartModal}
                      selected={chartIsSelected(chart.id)}
                      onCheck={() => onChartChecked(chart)}
                      onUnCheck={() => onChartUnChecked(chart)}
                    />
                  ))}
                </div>
              </AccordionItemBody>
            </AccordionItem>
          );
        })}
      </AccordionItems>
    </Accordion>
  );
}

export { ChartsAccordion };
