/* eslint-disable @typescript-eslint/ban-ts-comment */
import './VisitsByInjuryType.css';
import { createId as cuid } from '@paralleldrive/cuid2';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Bar } from 'react-chartjs-2';

import { Button } from 'components/Button';
import { Card } from 'components/Card';
// eslint-disable-next-line import/no-cycle
import { ErrorBoundary } from 'components/ErrorBoundary';
import { Progress } from 'components/Progress';
import { Typography } from 'components/Typography';
import { BarProps } from 'types';
import { formatNumber } from 'utils';

import { VisitsByInjuryAdapter } from '../store';
import {
  VisitsByInjuryTypeCategory,
  VisitsByInjuryTypeViewModel,
} from '../view-model';

const Fetching: React.FC = function FetchingVisitsByInjuryType() {
  return (
    <Card className="visits-by-injury-type">
      <section>
        <Typography
          variant="body"
          component="h3"
          color="darkness"
          className="visits-by-injury-type__title"
          fontWeight="normal"
        >
          Visits by Injury Type
        </Typography>

        <Progress
          show
          className="visits-by-injury-type__placeholder"
          data-testid="progress"
        />
      </section>
    </Card>
  );
};

interface ChartLegend {
  category: VisitsByInjuryTypeCategory;
}

const Legend: React.FC<ChartLegend> = function Legend(props) {
  const { category } = props;

  return (
    <li className="visits-by-injury-type__legend">
      <div
        className="visits-by-injury-type__legend-color"
        style={{ backgroundColor: category.color }}
      />

      <Typography variant="caption" color="darkness">
        {category.label}
      </Typography>
    </li>
  );
};

interface SuccessProps {
  viewModel: VisitsByInjuryTypeViewModel;
  order: string[];
}

const Success: React.FC<SuccessProps> = function SuccessVisitsByInjuryType(
  props,
) {
  const { viewModel, order } = props;
  const [labels, dataset] = viewModel.getOrderedData(order);

  const datasetIndexToKey = {
    0: 'less_than_five',
    1: 'between_five_and_ten',
    2: 'greater_than_ten',
  };

  const mapLabelNameToDataKey = (label: string) =>
    label === 'Neuro/Geriatrics' ? 'Other' : label;

  const barProps: BarProps = {
    data: {
      labels: labels.map((t) =>
        t.toLowerCase() === 'other' ? 'Neuro/Geriatrics' : t,
      ),
      datasets: dataset,
    },
    height: 210,
    options: {
      maintainAspectRatio: false,

      scales: {
        x: {
          stacked: true,
          grid: {
            display: false,
          },
          ticks: {
            color: '#52575C',
          },
        },
        y: {
          stacked: true,
          grid: {
            drawBorder: false,
          },
          ticks: {
            stepSize: 20,
            callback(value) {
              return `${value}%`;
            },
            padding: 12,
            color: '#52575C',
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          titleMarginBottom: 7,
          bodySpacing: 7,
          footerFont: {
            style: 'normal',
          },
          callbacks: {
            beforeBody(tooltipItems) {
              const tooltipItem = tooltipItems[0];
              return `Avg: ${formatNumber(
                viewModel.data[mapLabelNameToDataKey(tooltipItem.label)].total
                  .average,
              )} visits`;
            },
            label(tooltipItem) {
              return `${tooltipItem.parsed.y}%`;
            },
            afterBody(tooltipItems) {
              const tooltipItem = tooltipItems[0];
              return `Care Plans: ${formatNumber(
                // @ts-ignore
                viewModel.data[mapLabelNameToDataKey(tooltipItem.label)][
                  // @ts-ignore
                  datasetIndexToKey[tooltipItem.datasetIndex]
                ].care_plan_count,
              )}`;
            },
          },
        },
      },
    },
  };

  return (
    <Card className="visits-by-injury-type">
      <section>
        <Typography
          variant="body"
          component="h3"
          color="darkness"
          className="visits-by-injury-type__title"
          fontWeight="normal"
        >
          Visits by Injury Type
        </Typography>

        <ul className="visits-by-injury-type__legends">
          {viewModel.categories.map((category) => (
            <Legend key={cuid()} category={category} />
          ))}
        </ul>

        <div className="visits-by-injury-type__chart-container">
          <Bar {...barProps} />
        </div>
      </section>
    </Card>
  );
};

interface FailedProps {
  onRetry: VoidFunction;
  error?: Error;
}

const Failed: React.FC<FailedProps> = function Failed(props) {
  const { onRetry, error } = props;

  return (
    <Card className="visits-by-injury-type">
      <section>
        <Typography
          variant="body"
          component="h3"
          color="darkness"
          className="visits-by-injury-type__title"
          fontWeight="normal"
        >
          Visits by Injury Type
        </Typography>

        <div className="visits-by-injury-type__placeholder">
          <Typography
            variant="body"
            component="p"
            color="dark"
            className="visits-by-injury-type__title"
          >
            An error prevented us from loading this chart
          </Typography>

          <Button variant="primary" onClick={onRetry}>
            Try Again
          </Button>
        </div>
      </section>
    </Card>
  );
};

const VisitsByInjuryTypeComponent: React.FC = observer(() => {
  const store = React.useContext(VisitsByInjuryAdapter);
  if (store.fetched) {
    return (
      <Success
        viewModel={new VisitsByInjuryTypeViewModel(store.cache!)}
        order={store.labelOrder!}
      />
    );
  }
  if (store.failure) {
    return <Failed onRetry={() => store.refetch()} error={store.error} />;
  }
  return <Fetching />;
});

function FallbackUI() {
  return (
    <Card className="fallback-ui visits-by-injury-type">
      <Typography
        variant="body"
        component="h3"
        color="darkness"
        className="visits-by-injury-type__title"
        fontWeight="normal"
      >
        Something went wrong.
      </Typography>
    </Card>
  );
}

export function VisitsByInjuryTypeView() {
  return (
    <ErrorBoundary place="Visits By Injury Type" renderError={<FallbackUI />}>
      <VisitsByInjuryTypeComponent />
    </ErrorBoundary>
  );
}
