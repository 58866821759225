import { AgeDistribution } from '../age-distribution.model';

export const COLORS: Array<{
  backgroundColor: string;
  hoverBackgroundColor: string;
}> = [
  {
    // Primary
    backgroundColor: '#974EBC',
    hoverBackgroundColor: '#B168D6',
  },
  {
    // Teal
    backgroundColor: '#2FACBF',
    hoverBackgroundColor: '#4EC2D3',
  },
];

export interface AgeDistributionCategory {
  label: string;
  color: string;
}

interface AgeDistributionDataset {
  data: Array<number>;
  backgroundColor: string;
  hoverBackgroundColor: string;
}

export class AgeDistributionViewModel {
  constructor(private readonly model: AgeDistribution) {}

  public get labels() {
    return this.model.labels;
  }

  public get datasets(): Array<AgeDistributionDataset> {
    return this.model.data.map((dataset, idx) => ({
      ...COLORS[this.model.data.length - (idx + 1)],
      data: idx === 1 ? dataset.map((x) => -x) : dataset,
    }));
  }

  // eslint-disable-next-line class-methods-use-this
  public get categories(): Array<AgeDistributionCategory> {
    return ['Male', 'Female'].map((label, idx) => ({
      label,
      color: COLORS[idx].backgroundColor,
    }));
  }
}
