import React from 'react';

import { RecentPatientsSerializer } from '../serializer';

import { RecentPatientsStore } from './recent-patients.store';

export const recentPatientsStore = new RecentPatientsStore(
  new RecentPatientsSerializer(),
);
export const RecentPatientsStoreAdapter =
  React.createContext(recentPatientsStore);
