import { computed } from 'mobx';

// eslint-disable-next-line import/no-cycle
import { AgeDistributionStore } from 'features/age-distribution';
import { SelectOption, Clinic } from 'types';
import { formatClinicName } from 'utils';

import { AppStore } from './app.store';
import { Partner, Therapist } from './models';

export class DashboardViewModel {
  constructor(
    private readonly store: AppStore,
    private readonly ageDistributionStore: AgeDistributionStore,
  ) {}

  @computed public get totalPatientsFetching() {
    return this.ageDistributionStore.fetching;
  }

  @computed public get totalPatients() {
    if (typeof this.ageDistributionStore.cache === 'undefined') {
      return undefined;
    }

    if (this.ageDistributionStore.fetching) {
      return undefined;
    }

    return this.ageDistributionStore.cache.totalPatients;
  }

  @computed public get canDisplayPatientsTable(): boolean {
    return (
      this.store.selectedTherapists!.length > 0 ||
      !!this.store.partnerName ||
      this.store.selectedPartners!.length > 0 ||
      this.store.selectedClinics!.length > 0
    );
  }

  @computed public get canDisplayDowngradedPatients(): boolean {
    const hasSelectedEntity =
      this.store.selectedTherapists!.length > 0 ||
      this.store.selectedPartners!.length > 0 ||
      this.store.selectedClinics!.length > 0;
    return hasSelectedEntity && this.store.time === '90';
  }

  @computed public get selectedTherapists(): Array<SelectOption<string>> {
    return this.store.selectedTherapists || [];
  }

  @computed public get hasCategoryOptionSelected(): boolean {
    return (
      this.store.selectedTherapists!.length > 0 ||
      this.store.selectedPartners!.length > 0 ||
      this.selectedClinics.length > 0
    );
  }

  @computed public get hasCategoryOptions(): boolean {
    return (
      this.therapistFilerOptions.length >= 1 ||
      this.partnerFilerOptions.length >= 1 ||
      this.clinicFilerOptions.length >= 1
    );
  }

  @computed public get therapists(): Array<Therapist> {
    return this.store.therapists || [];
  }

  @computed public get partners(): Array<Partner> {
    return this.store.partners || [];
  }

  @computed public get clinics(): Array<Clinic> {
    return this.store.clinics || [];
  }

  @computed public get selectedPartners(): Array<SelectOption<string>> {
    return this.store.selectedPartners || [];
  }

  @computed public get selectedClinics(): Array<SelectOption<string>> {
    return this.store.selectedClinics || [];
  }

  @computed public get organizationName() {
    const { providerKind } = this;
    if (providerKind === 'clinic') {
      return this.clinicName;
    }
    if (providerKind === 'partner') {
      return this.partnerName;
    }
    if (providerKind === 'physician_group') {
      return this.groupName;
    }
    if (providerKind === 'physician') {
      return this.selectedTherapists[0].label;
    }
    return '';
  }

  @computed public get groupName(): string {
    return this.store.groupName || '';
  }

  @computed public get partnerName(): string {
    return this.store.partnerName || '';
  }

  @computed public get clinicName(): string {
    return this.store.clinicName || '';
  }

  @computed public get chartPermission(): boolean {
    return this.store.chartPermission || false;
  }

  @computed public get signPermission(): boolean {
    return this.store.signPermission || false;
  }

  @computed public get therapistListPermission(): boolean {
    return this.store.therapistListPermission || false;
  }

  @computed public get isAdmin(): boolean {
    return this.store.isAdmin();
  }

  @computed public get providerKind() {
    return this.store.providerKind;
  }

  @computed public get providerEmail() {
    return this.store.providerEmail;
  }

  public onTherapistSelected(therapistIds: Array<SelectOption<string>>): void {
    this.store.setTherapists(therapistIds);
  }

  public onPartnerSelected(partnerIds: Array<SelectOption<string>>): void {
    this.store.setPartners(partnerIds);
  }

  public onClinicSelected(clinicIds: Array<SelectOption<string>>): void {
    this.store.setClinics(clinicIds);
  }

  public onTimeSelected(time: string): void {
    this.store.setTime(time);
  }

  @computed get therapistFilerOptions(): Array<SelectOption<string>> {
    const therapistsList = this.therapists.map(
      ({ id, name, prefix, hubspotId }) => ({
        value: id,
        label: name,
        prefix,
        hubspotId,
      }),
    );

    const lastIndex = therapistsList.length - 1;

    if (therapistsList.length && therapistsList[lastIndex].label.length === 0) {
      therapistsList[lastIndex] = {
        ...therapistsList[lastIndex],
        prefix: 'Other',
      };
    }
    return therapistsList;
  }

  @computed get partnerFilerOptions(): Array<SelectOption<string>> {
    const partnerList = this.partners.map(({ name, id, code }) => ({
      value: id,
      label: name,
      prefix: '',
      hubspotId: '',
      code,
    }));

    const lastIndex = partnerList.length - 1;

    if (partnerList.length && partnerList[lastIndex].label.length === 0) {
      partnerList[lastIndex] = {
        ...partnerList[lastIndex],
        prefix: 'Other',
      };
    }

    return partnerList;
  }

  @computed get clinicFilerOptions(): Array<SelectOption<string>> {
    const clinicList = this.clinics.map(({ code, id, key }) => ({
      value: id,
      label: formatClinicName(key),
      prefix: '',
      hubspotId: '',
      code,
    }));

    const lastIndex = clinicList.length - 1;

    if (clinicList.length && clinicList[lastIndex].label.length === 0) {
      clinicList[lastIndex] = {
        ...clinicList[lastIndex],
        prefix: 'Other',
      };
    }

    return clinicList;
  }

  @computed get selectedTime(): string {
    return this.store.time!;
  }

  @computed get error(): Error | undefined {
    return this.store.error;
  }
}
