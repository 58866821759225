/* eslint-disable @typescript-eslint/ban-ts-comment */
import './RadioButton.css';
import clsx from 'clsx';
import { FC } from 'react';

type Props = JSX.IntrinsicElements['input'] & {
  valid?: string;
  'data-testid'?: string;
};

export const RadioButton: FC<Props> = function RadioButton({
  className,
  ...rest
}) {
  function onKeyPress(e: React.KeyboardEvent<HTMLDivElement>) {
    e.preventDefault();

    if (e.key === 'Enter' || e.which === 32) {
      if (rest.onChange) {
        // @ts-ignore
        rest.onChange();
      }
    }
  }

  return (
    <div
      className={clsx([
        'radioButton__container',
        rest.checked && 'radioButton__containerChecked',
        rest.valid === 'false' && 'radioButton__containerInvalid',
      ])}
      role="radio"
      aria-checked={rest.checked}
      onKeyUp={onKeyPress}
      tabIndex={0}
    >
      <input
        type="radio"
        tabIndex={-1}
        className={clsx(['radioButton__real', className])}
        {...rest}
      />
    </div>
  );
};
