import LogRocket from 'logrocket';
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { manager, refreshAccessToken } from 'lib/api';
import { getInLocalWithExpiry, getInSessionWithExpiry } from 'utils';
import { getDashboardToken } from 'utils/getDashboardToken';

interface IUser {
  name: string;
  email: string;
}

interface IAuthMethods {
  setAuthIsLoading: (v: boolean) => void;
  setAuthUser: (u?: IUser) => void;
  loading: boolean;
}

type AuthState = { isAuthenticated: boolean; user?: IUser };

type IAuthContext = IAuthMethods & AuthState;

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export function useAuth() {
  const value = useContext(AuthContext);
  if (!value) {
    throw new Error("Can't use useAuth outside an AuthContext Provider");
  }
  return value;
}

export function AuthProvider({ children }: PropsWithChildren) {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<IUser | undefined>();

  const setAuthIsLoading = (v: boolean) => {
    setLoading(v);
  };
  const setAuthUser = (u?: IUser) => {
    setUser(u);
    setIsAuthenticated(typeof u !== 'undefined');
  };

  useEffect(() => {
    const data = JSON.parse(
      getInSessionWithExpiry('user') || getInLocalWithExpiry('user'),
    );
    if (data && getDashboardToken() === '') {
      // Refetch
      refreshAccessToken(data.token, data.refresh_token)
        .then(({ expires, token }) => {
          manager.set(token, data.refresh_token, expires);
          setAuthUser({ email: data.user.email, name: data.user.name });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (user) {
      LogRocket.identify(user.email, {
        name: user.name,
        email: user.email,
        type: 'Admin',
      });
    }
  }, [user]);

  const value = useMemo(
    () => ({
      isAuthenticated,
      setAuthIsLoading,
      setAuthUser,
      user,
      loading,
    }),
    [isAuthenticated, user, loading],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthContext;
