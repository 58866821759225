export interface ChangeInPainLevelOptions {
  data: Array<number>;
  counter: number;
}

export class ChangeInPainLevel {
  public data: ChangeInPainLevelOptions['data'];

  public counter: ChangeInPainLevelOptions['counter'];

  constructor(opts: ChangeInPainLevelOptions) {
    this.data = opts.data;
    this.counter = opts.counter;
  }
}
