/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import './Modal.css';
import clsx from 'clsx';
import { FC, PropsWithChildren } from 'react';

import { Card } from 'components/Card';
import { ModalCloseIcon } from 'components/Icons';
import { Typography } from 'components/Typography';

type Props = {
  show: boolean;
  handleClose: VoidFunction;
  title?: string | undefined;
  size?: 'small' | 'medium' | 'large' | 'full';
  level?: number;
  className?: string;
};

export const Modal: FC<PropsWithChildren<Props>> = function Modal({
  show,
  handleClose,
  title,
  children,
  size,
  level,
  className,
}) {
  return (
    <>
      <div
        className={clsx(
          ['modal', show ? 'modal__dispaly-block' : 'modal__display-none'],
          `modal__level-${level || 2}`,
        )}
        onClick={handleClose}
        onKeyUp={handleClose}
        role="dialog"
      />
      <Card
        className={clsx([
          'modal__main',
          `modal__size-${size || 'medium'}`,
          show ? 'modal__dispaly-block' : 'modal__display-none',
          `modal__level-${level || 2}`,
          className,
        ])}
      >
        <section className="modal__content">
          <div className="modal__main__header">
            <Typography
              variant="body"
              component="h3"
              color="darkness"
              fontWeight="normal"
            >
              {title || null}
            </Typography>
            <ModalCloseIcon
              className="modal__close-icon"
              onClick={handleClose}
            />
          </div>
          {children}
        </section>
      </Card>
    </>
  );
};
