import { Card } from 'components/Card';
import { Typography } from 'components/Typography';

export function FallbackUI() {
  return (
    <Card className="fallback-ui knee">
      <Typography variant="body" color="darkness" className="knee__title">
        Something went wrong.
      </Typography>
    </Card>
  );
}
