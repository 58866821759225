import { withScope, captureException } from '@sentry/browser';
import React, { Component, ErrorInfo, ReactNode } from 'react';

// eslint-disable-next-line import/no-cycle
import { metricLogger } from 'app/routes/Dashboard';
import { Typography } from 'components/Typography';

interface Props {
  children?: React.ReactNode;
  place: string;
  renderError?: ReactNode;
}

interface State {
  hasError: boolean;
}

/**
 * @see https://reactjs.org/docs/error-boundaries.html
 */
export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const { place } = this.props;
    if (process.env.REACT_APP_SENTRY_DSN_FRONTEND) {
      withScope((scope) => {
        scope.setExtra('place', place);
        Object.keys(errorInfo).forEach((key) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          scope.setExtra(key, errorInfo[key]);
        });
        captureException(error);
      });
    }
    metricLogger.sendMetrics({
      patient_name: 'Does not apply',
      kind: 'error',
      extras: {
        text: error.message,
        context: place,
      },
    });
  }

  render() {
    const { hasError } = this.state;
    const { renderError, children } = this.props;
    if (hasError) {
      // You can render any custom fallback UI
      return (
        renderError || (
          <Typography variant="body" component="p" color="dark">
            An unexpected error has occurred.
          </Typography>
        )
      );
    }
    return children;
  }
}
