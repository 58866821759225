/* eslint-disable @typescript-eslint/ban-ts-comment */
import { SerializerError } from 'errors';
import { PatientSatisfactionResponse } from 'types';

import { PatientSatisfaction } from '../patient-satisfaction.model';

export class PatientSatisfactionSerializer {
  deserialize(target: PatientSatisfactionResponse): PatientSatisfaction {
    const {
      info: { data },
    } = target;
    if (typeof data.total_rates !== 'number') {
      throw new SerializerError(
        'PatientSatisfactionSerializer',
        'data.nps should be a number',
      );
    }
    Object.keys(data.thumbs_up).forEach((v) => {
      // @ts-ignore
      if (typeof data.thumbs_up[v] !== 'number') {
        throw new SerializerError(
          'PatientSatisfactionSerializer',
          `data.thumbs_up.${v} should be a number`,
        );
      }
    });
    Object.keys(data.thumbs_down).forEach((v) => {
      // @ts-ignore
      if (typeof data.thumbs_down[v] !== 'number') {
        throw new SerializerError(
          'PatientSatisfactionSerializer',
          `data.thumbs_down.${v} should be a number`,
        );
      }
    });
    return new PatientSatisfaction({
      data: [data.thumbs_up.total, data.thumbs_down.total],
      percentages: [data.thumbs_up.percentage, data.thumbs_down.percentage],
      value: data.total_rates,
    });
  }
}
