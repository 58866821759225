import { createId as cuid } from '@paralleldrive/cuid2';
import React from 'react';
import { Bar } from 'react-chartjs-2';

import { Card } from 'components/Card';
import { ConditionalComponent } from 'components/ConditionalComponent';
import { Typography } from 'components/Typography';
import useMediaQuery from 'hooks/useMediaQuery';
import { BarProps } from 'types';

import { DowngradedPatientViewModel } from '../view-model';

import { EmptyState } from './EmptyState';

type DowngradedPatientsChartProps = {
  onChangeViewMode: VoidFunction;
  model: DowngradedPatientViewModel;
};

interface ChartLegend {
  category: {
    label: string;
    color: string;
  };
}

const Legend: React.FC<ChartLegend> = function Legend(props) {
  const { category } = props;

  return (
    <li className="downgraded-patients__legend">
      <div
        className="downgraded-patients__legend-color"
        style={{ backgroundColor: category.color }}
      />

      <Typography variant="caption" color="darkness">
        {category.label}
      </Typography>
    </li>
  );
};

export function DowngradedPatientsChart({
  onChangeViewMode,
  model,
}: DowngradedPatientsChartProps) {
  const matchesMobileQuery = useMediaQuery('(max-width: 768px)');
  const barProps: BarProps = {
    data: {
      labels: model.labels,
      datasets: model.datasets,
    },
    height: 203,
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          grid: {
            display: false,
          },
          ticks: {
            color: '#3C4858',
          },
          beginAtZero: true,
        },
        y: {
          grid: {
            drawBorder: false,
          },
          ticks: {
            callback(value) {
              return `${value}%`;
            },
            padding: 12,
            color: '#3C4858',
          },
          max: model.maxValue,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          bodySpacing: 10,
          padding: 10,
          callbacks: {
            title: () => '',
            beforeBody(tooltipItems) {
              const tooltipItem = tooltipItems[0];
              return `Total: ${model.values[tooltipItem.dataIndex]}`;
            },
            label() {
              return '';
            },
            afterBody(tooltipItems) {
              const tooltipItem = tooltipItems[0];
              const value = tooltipItem.parsed.y;
              return `Percentage: ${value < 1.0 ? '<1' : value}%`;
            },
          },
        },
      },
    },
  };

  if (model.isEmpty) {
    return <EmptyState />;
  }
  return (
    <Card className="recent-patients">
      <section>
        <div className="downgraded-patients__header downgraded-patients-chart-view">
          <Typography
            variant="body"
            color="darkness"
            component="h3"
            fontWeight="normal"
            className="recent-patients__title downgraded-patients__title"
          >
            Unseen Patients
          </Typography>
          <Typography
            component="p"
            color="blue-link"
            className="downgraded-patients__view-chart"
            onClick={onChangeViewMode}
          >
            View detail
          </Typography>
        </div>
        <ConditionalComponent validate={!matchesMobileQuery}>
          <ul className="downgraded-patients__legends">
            {model.categories.map(({ category }) => (
              <Legend key={cuid()} category={category} />
            ))}
          </ul>
        </ConditionalComponent>
        <div className="downgraded-patients__chart-container">
          <Bar {...barProps} data-testid="chart" />
        </div>
        <div className="downgraded-patients__chart-footer">
          <ConditionalComponent validate={matchesMobileQuery}>
            <ul className="downgraded-patients__legends">
              {model.categories.map(({ category }) => (
                <Legend key={cuid()} category={category} />
              ))}
            </ul>
          </ConditionalComponent>
          <Typography variant="parragraph" color="darkness">
            Patient Status
          </Typography>
        </div>
      </section>
    </Card>
  );
}
