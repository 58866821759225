export interface AgeDistributionOptions {
  labels: Array<string>;
  data: Array<Array<number>>;
  totalPatients: number;
}

export class AgeDistribution {
  public labels: AgeDistributionOptions['labels'];

  public data: AgeDistributionOptions['data'];

  public totalPatients: AgeDistributionOptions['totalPatients'];

  constructor(opts: AgeDistributionOptions) {
    this.labels = opts.labels;
    this.data = opts.data;
    this.totalPatients = opts.totalPatients;
  }
}
