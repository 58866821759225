import './ChangeInPainLevel.css';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Bar } from 'react-chartjs-2';
import Select from 'react-select';

import { Button } from 'components/Button';
import { Card } from 'components/Card';
import { ChartDescription } from 'components/ChartDescrption';
// eslint-disable-next-line import/no-cycle
import { ErrorBoundary } from 'components/ErrorBoundary';
import { Progress } from 'components/Progress';
import { Typography } from 'components/Typography';
import { SelectOption, BarProps } from 'types';

import { ChangeInPainLevelAdapter } from '../store';
import { ChangeInPainLevelViewModel } from '../view-model';

const SELECT_PROPS = {
  isSearchable: false,
  className: 'select',
  classNamePrefix: 'select',
};

interface FetchingProps {
  viewModel: ChangeInPainLevelViewModel;
  onChangeSessionCount: (value: number) => void;
}

const Fetching: React.FC<FetchingProps> = observer((props) => {
  const { viewModel, onChangeSessionCount } = props;

  return (
    <Card className="change-in-pain-level">
      <section>
        <div className="change-in-pain-level__title">
          <Typography
            variant="body"
            color="darkness"
            component="h3"
            fontWeight="normal"
          >
            Change in pain level - 1st {viewModel.sessionCount.label}
          </Typography>
          {/** Used for testing react-select */}
          {/* eslint-disable-next-line */}
          <label htmlFor="change-in-pain-sessions-input" />
          <Select
            inputId="change-in-pain-sessions-input"
            value={viewModel.sessionCount}
            options={viewModel.sessionFilterOptions}
            onChange={(e) => onChangeSessionCount(e!.value)}
            {...SELECT_PROPS}
          />
        </div>

        <Progress
          show
          className="change-in-pain-level__placeholder"
          data-testid="progress"
        />
      </section>
    </Card>
  );
});

interface SuccessProps {
  viewModel: ChangeInPainLevelViewModel;
  onChangeSessionCount: (value: number) => void;
}

const Success: React.FC<SuccessProps> = observer((props) => {
  const { viewModel, onChangeSessionCount } = props;

  const isAnEmptyModel = viewModel.counter === 0;

  if (isAnEmptyModel) {
    return null;
  }

  const chartProps: BarProps = {
    data: {
      labels: [10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0],
      datasets: [
        {
          backgroundColor: '#974EBC',
          hoverBackgroundColor: '#974EBC',
          data: viewModel.labels,
          barPercentage: 1.3,
          // fill: true,
          // stepped: true,
        },
      ],
    },
    height: 256,
    options: {
      indexAxis: 'y',
      elements: {
        point: {
          radius: 0,
        },
      },
      maintainAspectRatio: false,
      scales: {
        x: {
          grid: {
            display: false,
          },
          ticks: {
            color: '#3C4858',
            callback(value) {
              return `${value}%`;
            },
            stepSize: 20,
          },
          beginAtZero: true,
          min: 0,
          max: 100,
          title: {
            display: true,
          },
        },
        y: {
          grid: {
            drawBorder: false,
            display: false,
          },
          ticks: {
            padding: 12,
            color: '#3C4858',
            stepSize: 2,
          },
          beginAtZero: true,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
          position: 'nearest',
          backgroundColor: '#212B36',
          titleAlign: 'center',
          padding: {
            top: 8,
            left: 20,
          },
          titleFont: {
            style: 'normal',
            size: 14,
          },
          callbacks: {
            label: () => '',
            title: (context) =>
              `${context[0].parsed.x}% patients made at least \n ${context[0].label} points of improvement`,
          },
        },
      },
    },
  };

  return (
    <Card className="change-in-pain-level">
      <section>
        <div className="change-in-pain-level__title">
          <Typography
            variant="body"
            color="darkness"
            component="h3"
            fontWeight="normal"
          >
            Change in pain level - 1st {viewModel.sessionCount.label}
          </Typography>
          {/** Used for testing react-select */}
          {/* eslint-disable-next-line */}
          <label htmlFor="change-in-pain-sessions-input" />
          <Select
            inputId="change-in-pain-sessions-input"
            value={viewModel.sessionCount}
            options={viewModel.sessionFilterOptions}
            onChange={(e) => onChangeSessionCount(e!.value)}
            {...SELECT_PROPS}
          />
        </div>

        <div className="change-in-pain-level__chart-container">
          <Typography
            variant="caption"
            color="darkness"
            className="change-in-pain-level__y-label"
          >
            Decrease in Pain Scale
          </Typography>

          <div className="change-in-pain-level__chart">
            <Bar {...chartProps} />
          </div>

          <Typography
            variant="caption"
            color="darkness"
            className="change-in-pain-level__x-label"
          >
            Patient distribution
          </Typography>
        </div>

        <ChartDescription>
          <strong>{viewModel.counter}%</strong> of the patients show clinically
          important difference in pain scale in the first{' '}
          {viewModel.sessionCount.label}.
        </ChartDescription>
      </section>
    </Card>
  );
});

interface FailedProps {
  onRetry: VoidFunction;
  error?: Error;
  viewModel: ChangeInPainLevelViewModel;
  onChangeSessionCount: (value: number) => void;
}

const Failed: React.FC<FailedProps> = observer((props) => {
  const { onRetry, viewModel, onChangeSessionCount } = props;

  return (
    <Card className="change-in-pain-level">
      <section>
        <Typography
          variant="body"
          color="darkness"
          component="h3"
          fontWeight="normal"
          className="change-in-pain-level__title"
        >
          Change in pain level - 1st {viewModel.sessionCount.label}
        </Typography>

        <Select
          value={viewModel.sessionCount}
          options={viewModel.sessionFilterOptions}
          onChange={(e) => onChangeSessionCount(e!.value)}
          {...SELECT_PROPS}
        />

        <div className="change-in-pain-level__placeholder">
          <Typography
            variant="body"
            color="dark"
            className="change-in-pain-level__title"
          >
            An error prevented us from loading this chart
          </Typography>

          <Button variant="primary" onClick={onRetry}>
            Try Again
          </Button>
        </div>
      </section>
    </Card>
  );
});

const ChangeInPainLevelComponent: React.FC = observer(() => {
  const store = React.useContext(ChangeInPainLevelAdapter);

  const viewModel = new ChangeInPainLevelViewModel(store);
  const onChangeSessionCount = (value: number) =>
    store.changeSessionCount(value);

  if (store.fetched) {
    return (
      <Success
        viewModel={new ChangeInPainLevelViewModel(store)}
        onChangeSessionCount={onChangeSessionCount}
      />
    );
  }
  if (store.failure) {
    return (
      <Failed
        viewModel={viewModel}
        onChangeSessionCount={onChangeSessionCount}
        onRetry={() => store.refetch()}
        error={store.error}
      />
    );
  }
  return (
    <Fetching
      viewModel={viewModel}
      onChangeSessionCount={onChangeSessionCount}
    />
  );
});

function FallbackUI() {
  return (
    <Card className="fallback-ui change-in-pain-level">
      <Typography
        variant="body"
        color="darkness"
        component="h3"
        fontWeight="normal"
        className="change-in-pain-level__title"
      >
        Something went wrong.
      </Typography>
    </Card>
  );
}

export function ChangeInPainLevelView() {
  return (
    <ErrorBoundary place="Change In Pain Level" renderError={<FallbackUI />}>
      <ChangeInPainLevelComponent />
    </ErrorBoundary>
  );
}
