import { PropsWithChildren } from 'react';

interface ConditionalProps {
  validate: boolean | (() => boolean);
}

export function ConditionalComponent({
  validate,
  children,
}: PropsWithChildren<ConditionalProps>) {
  const shouldRender = typeof validate === 'function' ? validate() : validate;
  if (!shouldRender) {
    return null;
  }
  return children;
}
