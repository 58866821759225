import { observable, computed } from 'mobx';
import {
  ActorRefFrom,
  DoneActorEvent,
  ErrorActorEvent,
  SnapshotFrom,
  assign,
  createActor,
  fromPromise,
  setup,
} from 'xstate';

import {
  MachineEvent,
  SelectedClinicsEvent,
  SelectedPartnersEvent,
  SelectedTherapistsEvent,
} from 'features/base-chart-store';
import api from 'lib/api';
import {
  SelectOption,
  ChartLoadParams,
  ChartsFetchParams,
  VisitsByInjuryTypeResponse,
} from 'types';

import { VisitsByInjurySerializer } from '../serializer';
import { VisitsByInjuryType } from '../visits-by-injury-type.model';

interface MachineContext {
  data?: VisitsByInjuryType;
  error?: Error;
  selectedTherapists: Array<SelectOption<string>>;
  selectedPartners: Array<SelectOption<string>>;
  selectedClinics: Array<SelectOption<string>>;
  partnerId: string | undefined;
  labelOrder: string[];
  time: string;
}
interface CaseDistributionLoadedEvent {
  type: 'CASE_DISTRIBUTION_LOADED';
  labels: string[];
}

// Extend Events with base ones
type LocalMachineEvent = MachineEvent | CaseDistributionLoadedEvent;

export class VisitsByInjuryTypeStore {
  @observable
  protected current!: SnapshotFrom<typeof this.machine>;

  protected service!: ActorRefFrom<typeof this.machine>;

  constructor(private serializer: VisitsByInjurySerializer) {
    this.service = createActor(this.machine);
    this.service.subscribe((state) => {
      this.current = state;
    });
    this.service.start();
  }

  private machine = setup({
    types: {
      context: {} as MachineContext,
      events: {} as LocalMachineEvent,
    },
    actions: {
      saveData: assign({
        data: ({ event }) => {
          const { output } = event as DoneActorEvent<VisitsByInjuryType>;

          return output;
        },
      }),
      saveError: assign({
        error: ({ event }) => {
          const { error } = event as ErrorActorEvent<Error>;

          return error;
        },
      }),
      deleteError: assign({
        error: undefined,
      }),
      setTherapists: assign({
        selectedTherapists: ({ event }) =>
          (event as SelectedTherapistsEvent).ids,
        partnerId: ({ event }) => (event as SelectedTherapistsEvent).partnerId,
        time: ({ event }) => (event as SelectedTherapistsEvent).time,
      }),
      setPartners: assign({
        selectedPartners: ({ event }) =>
          (event as SelectedPartnersEvent).selected,
      }),
      setLabelOrder: assign({
        labelOrder: ({ event }) =>
          (event as CaseDistributionLoadedEvent).labels,
      }),
      setClinics: assign({
        selectedClinics: ({ event }) =>
          (event as SelectedClinicsEvent).selected,
      }),
    },
    actors: {
      fetchVisitsByInjuryTypeModel: fromPromise<
        VisitsByInjuryType,
        ChartLoadParams
      >(({ input, signal }) =>
        this.doFetch(
          input.therapists,
          input.partnerId,
          input.partners,
          input.clinics,
          input.time,
          signal,
        ),
      ),
    },
  }).createMachine({
    id: 'Visits By Injury Type Machine',
    initial: 'initial',
    context: {
      labelOrder: [],
      data: undefined,
      error: undefined,
      selectedTherapists: [],
      selectedPartners: [],
      selectedClinics: [],
      partnerId: undefined,
      time: '90',
    },
    states: {
      initial: {
        on: {
          THERAPISTS_SELECTED: {
            target: 'fetching',
            actions: ['setTherapists'],
          },
          PARTNERS_SELECTED: { actions: ['setPartners'] },
          CLINICS_SELECTED: { actions: ['setClinics'] },
          CASE_DISTRIBUTION_LOADED: { actions: ['setLabelOrder'] },
        },
      },
      fetching: {
        on: {
          THERAPISTS_SELECTED: {
            reenter: true,
            target: 'fetching',
            actions: ['setTherapists'],
          },
          PARTNERS_SELECTED: { actions: ['setPartners'] },
          CLINICS_SELECTED: { actions: ['setClinics'] },
          CASE_DISTRIBUTION_LOADED: { actions: ['setLabelOrder'] },
        },
        invoke: {
          id: 'fetchVisitsByInjuryTypeModel',
          src: 'fetchVisitsByInjuryTypeModel',
          input: ({ context, event }) => ({
            therapists:
              (event as SelectedTherapistsEvent).ids ||
              context.selectedTherapists,
            partnerId:
              (event as SelectedTherapistsEvent).partnerId || context.partnerId,
            partners: context.selectedPartners,
            clinics: context.selectedClinics,
            time: (event as SelectedTherapistsEvent).time || context.time,
          }),
          onDone: {
            target: 'success',
            actions: ['saveData', 'deleteError'],
          },
          onError: {
            target: 'failure',
            actions: ['saveError'],
          },
        },
      },
      success: {
        on: {
          THERAPISTS_SELECTED: {
            target: 'fetching',
            actions: ['setTherapists'],
          },
          PARTNERS_SELECTED: { actions: ['setPartners'] },
          CLINICS_SELECTED: { actions: ['setClinics'] },
          CASE_DISTRIBUTION_LOADED: { actions: ['setLabelOrder'] },
        },
      },
      failure: {
        on: {
          RETRY_EVENT: 'fetching',
          THERAPISTS_SELECTED: {
            target: 'fetching',
            actions: ['setTherapists'],
          },
          PARTNERS_SELECTED: { actions: ['setPartners'] },
          CLINICS_SELECTED: { actions: ['setClinics'] },
          CASE_DISTRIBUTION_LOADED: { actions: ['setLabelOrder'] },
        },
      },
    },
  });

  private async load(
    payload: ChartLoadParams,
    signal?: AbortSignal,
  ): Promise<VisitsByInjuryTypeResponse> {
    const { time, therapists, partnerId, partners, clinics } = payload;
    const params = new URLSearchParams();
    params.set('from', time);
    let doctors: string[] = [];
    if (therapists.length === 0) {
      if (partnerId) {
        params.set('partner', partnerId);
      }
    } else {
      doctors = therapists.map(({ value }) => value);
    }
    const request = await api.post<VisitsByInjuryTypeResponse>(
      `/visits_by_injury?${params.toString()}`,
      {
        data: {
          doctors,
          partners: partners.map((p) => p.value),
          clinics: clinics.map((c) => c.value),
        },
      },
      { signal },
    );
    return request.data;
  }

  public setPartners(selectedPartners: Array<SelectOption<string>>) {
    this.service.send({
      type: 'PARTNERS_SELECTED',
      selected: selectedPartners,
    });
  }

  public setClinics(selectedClinics: Array<SelectOption<string>>) {
    this.service.send({ type: 'CLINICS_SELECTED', selected: selectedClinics });
  }

  public setLabelOrder(labels: string[]) {
    this.service.send({ type: 'CASE_DISTRIBUTION_LOADED', labels });
  }

  public fetch(params: ChartsFetchParams): void {
    const { therapists, partnerId, time } = params;
    this.service.send({
      type: 'THERAPISTS_SELECTED',
      ids: therapists,
      partnerId,
      time,
    });
  }

  private async doFetch(
    therapists: Array<SelectOption<string>>,
    partnerId: string | undefined,
    partners: Array<SelectOption<string>>,
    clinics: Array<SelectOption<string>>,
    time: string,
    signal?: AbortSignal,
  ) {
    const jsonResponse = await this.load(
      {
        therapists,
        partnerId,
        partners,
        clinics,
        time,
      },
      signal,
    );

    return this.serializer.deserialize(jsonResponse);
  }

  @computed
  public get labelOrder(): string[] | undefined {
    if (typeof this.current === 'undefined') {
      return undefined;
    }
    return this.current.context.labelOrder as string[];
  }

  @computed
  public get cache(): VisitsByInjuryType | undefined {
    if (typeof this.current === 'undefined') {
      return undefined;
    }
    return this.current.context.data as VisitsByInjuryType;
  }

  @computed
  public get fetching(): boolean {
    if (typeof this.current === 'undefined') {
      return false;
    }

    return this.current.matches('fetching');
  }

  @computed
  public get fetched(): boolean {
    if (typeof this.current === 'undefined') {
      return false;
    }

    return this.current.matches('success');
  }

  @computed
  public get failure(): boolean {
    if (typeof this.current === 'undefined') {
      return false;
    }

    return this.current.matches('failure');
  }

  public refetch(): void {
    this.service.send({ type: 'RETRY_EVENT' });
  }

  @computed
  public get error(): Error | undefined {
    if (typeof this.current === 'undefined') {
      return undefined;
    }

    return this.current.context.error;
  }
}
