/* eslint-disable react/no-unescaped-entities */
import './OpenTasks.css';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react';

// eslint-disable-next-line import/no-cycle
import { metricLogger } from 'app/routes/Dashboard';
import { Button } from 'components/Button';
import { Card } from 'components/Card';
import { ConditionalComponent } from 'components/ConditionalComponent';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { Progress } from 'components/Progress';
import { Typography } from 'components/Typography';
import { useToastContext } from 'context/toast-context';
import useMediaQuery from 'hooks/useMediaQuery';
import { ToastType } from 'types';

import { OpenTaskPatient } from '../open-tasks.model';
import OpenTaskContext, {
  OpenTaskContextProvider,
} from '../store/open-tasks.context';

import { OpenTask } from './OpenTasks';
import { OpenTaskMobileView } from './OpenTasksMobile';
import { SignModal } from './SignModal';

const Fetching: React.FC = function Fetching() {
  return (
    <Card className="open-tasks">
      <section>
        <Typography
          variant="body"
          color="primary"
          component="h3"
          fontWeight="normal"
          className="open-tasks__title"
        >
          Open Tasks
        </Typography>

        <Progress show className="open-tasks__placeholder" />
      </section>
    </Card>
  );
};

interface FailedProps {
  onRetry: VoidFunction;
  error?: Error;
}

const Failed: React.FC<FailedProps> = observer((props) => {
  const { onRetry } = props;

  return (
    <Card className="open-tasks">
      <section className="open-tasks__error">
        <Typography
          variant="body"
          component="p"
          color="dark"
          className="open-tasks__error-message"
        >
          An error prevented us from loading this chart
        </Typography>

        <Button variant="primary" onClick={onRetry}>
          Try Again
        </Button>
      </section>
    </Card>
  );
});

export const OpenTasksConsumer: React.FC = observer(() => {
  const { store, reset, currentSign, mode, onChangeViewMode } =
    useContext(OpenTaskContext);

  const [showPdfModal, setShowPdfModal] = useState(false);
  const [showSignModal, setShowSignModal] = useState(false);

  const { addToast } = useToastContext();

  const setOpenTask = (task: OpenTaskPatient): void => store.setOpenTask(task);

  const handlePdfModal = (task: OpenTaskPatient) => {
    setOpenTask(task);
    setShowPdfModal(true);
    metricLogger.sendMetrics({
      patient_name: task.patientName,
      kind: 'plan_of_care_click',
      extras: {
        poc_id: task.planOfCareId || undefined,
      },
    });
  };

  const handleSignModal = (task: OpenTaskPatient) => {
    setOpenTask(task);
    setShowSignModal(true);
  };

  const handleCloseSignModal = () => {
    setShowSignModal(false);
    reset();
  };

  const computedSuccessMessage = () => {
    switch (currentSign.context.signValue) {
      case 'approveSign':
        return 'Plan of Care signed successfully.';
      case 'approveSignWithNote':
        return 'Plan of Care Signed with modifications.';
      case 'noApproveSign':
        return 'Plan of Care Rejected.';
      default:
        return 'Plan of Care signed successfully.';
    }
  };

  const matchesMobileQuery = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    // When the actual sign status changed give corresponding feedback
    // the function closeModal should only be called if sign status is success/failure otherwise
    // the reset function will be called in a uninitialized machine because we are inside the useEffect
    if (store.actualSignStatus === 'success') {
      addToast(computedSuccessMessage(), ToastType.SUCCESS);
      handleCloseSignModal();
    } else if (store.actualSignStatus === 'failure') {
      addToast(
        "We couldn't process your request at this moment",
        ToastType.ERROR,
      );
      handleCloseSignModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.actualSignStatus]);

  if (store.fetched) {
    if (store.cache!.length === 0) return null;
    return (
      <>
        <SignModal
          showPdfModal={showPdfModal}
          showSignModal={showSignModal}
          setShowPdfModal={setShowPdfModal}
          handleCloseSignModal={handleCloseSignModal}
        />
        <Card
          className={clsx([
            'open-tasks',
            mode === 'resume' ? 'container-resume' : 'container-expanded',
          ])}
        >
          <section>
            <Typography
              variant="body"
              color="primary"
              component="h3"
              fontWeight="normal"
              className="open-tasks__title"
            >
              Open Tasks
            </Typography>

            <Typography variant="caption" color="gray-chateau" component="p">
              Review the POC by clicking on the underlined "Plan of Care". Once
              reviewed, press "Sign" to approve or modify the Plan of Care.
            </Typography>
            <ConditionalComponent validate={!matchesMobileQuery}>
              <OpenTask
                model={store.cache!}
                mode={mode}
                handlePdfModal={handlePdfModal}
                handleSignModal={handleSignModal}
                generalDashboard={store.generalDashboard}
                onChangeViewMode={onChangeViewMode}
              />
            </ConditionalComponent>
            <ConditionalComponent validate={matchesMobileQuery}>
              <OpenTaskMobileView
                model={store.cache!}
                mode={mode}
                handlePdfModal={handlePdfModal}
                handleSignModal={handleSignModal}
                generalDashboard={store.generalDashboard}
                onChangeViewMode={onChangeViewMode}
              />
            </ConditionalComponent>
          </section>
        </Card>
      </>
    );
  }
  if (store.failure) {
    return <Failed onRetry={() => store.refetch()} error={store.error} />;
  }
  return <Fetching />;
});

function OpenTasksComponent() {
  return (
    <OpenTaskContextProvider>
      <OpenTasksConsumer />
    </OpenTaskContextProvider>
  );
}

function FallbackUI() {
  return (
    <Card className="fallback-ui open-tasks">
      <Typography
        variant="body"
        component="p"
        color="dark"
        className="open-tasks__error-message"
      >
        Something went wrong.
      </Typography>
    </Card>
  );
}

export function OpenTasksView() {
  return (
    <ErrorBoundary place="Open Tasks" renderError={<FallbackUI />}>
      <OpenTasksComponent />
    </ErrorBoundary>
  );
}
