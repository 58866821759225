/* eslint-disable no-param-reassign */
import { captureException } from '@sentry/browser';
import axios from 'axios';
import { when } from 'mobx';

import { NetworkTracker } from 'utils/requestTracker';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/v1` || 'http://localhost:3000/v1',
});

api.interceptors.response.use(null, (error) => {
  captureException(error);
  return Promise.reject(error);
});

interface AccessTokenFields {
  expires_in: number;
  issued_at: string;
  refresh_token: string;
  token: string;
}

interface AccessTokenResponse {
  data: AccessTokenFields;
}

type AsyncCB = () => Promise<void>;
class FetchRequestManager {
  public queue: AsyncCB[] = [];

  public accessToken = '';

  public refreshToken = '';

  public expires = 0;

  public isRefreshing = false;

  public urlToken = '';

  public isAdmin = false;

  public get isTokenExpired() {
    // admin dashboard is always valid
    if (this.isAdmin) {
      return false;
    }
    const currentUnixTime = Math.floor(Date.now() / 1000);
    return currentUnixTime >= this.expires;
  }

  public set(token: string, refreshToken: string, expires: number) {
    this.accessToken = token;
    this.refreshToken = refreshToken;
    this.expires = expires;
  }

  public reset() {
    this.accessToken = '';
    this.refreshToken = '';
    this.expires = 0;
  }
}

export const manager = new FetchRequestManager();

export async function refreshAccessToken(
  accessToken: string,
  refreshToken: string,
) {
  const request = await api.post<AccessTokenResponse>('/refresh_tokens', {
    data: {
      old_token: accessToken,
      refresh_token: refreshToken,
    },
  });
  const { data } = request.data;
  const newExpireTime = data.expires_in + Math.floor(Date.now() / 1000);
  return {
    token: data.token,
    expires: newExpireTime,
  };
}

export async function getAccessToken(dashboardToken: string) {
  const request = await api.post<AccessTokenResponse>(
    '/access_tokens',
    {},
    {
      headers: {
        Authentication: `Token ${dashboardToken}`,
      },
    },
  );
  const { data } = request.data;
  const newExpireTime = data.expires_in + Math.floor(Date.now() / 1000);

  return {
    token: data.token,
    refreshToken: data.refresh_token,
    expires: newExpireTime,
  };
}
const blacklisted: string[] = [
  '/sessions',
  '/sessions/refresh',
  '/refresh_tokens',
  '/access_tokens',
  '/external/sessions',
  '/activities',
  '/sign_plan_of_care',
  '/plans_of_care',
];

const withOutPOC = blacklisted.filter((_, i) => i < blacklisted.length);

api.interceptors.request.use(async (config) => {
  // Ignore certain routes
  if (blacklisted.includes(config.url || '')) {
    return config;
  }
  if (manager.isTokenExpired) {
    // if not refreshing then i will
    if (!manager.isRefreshing) {
      manager.isRefreshing = true;
      //
      const { token, expires } = await refreshAccessToken(
        manager.accessToken,
        manager.refreshToken,
      );

      manager.accessToken = token;
      manager.expires = expires;
      // Update new defaults
      config.headers.Authentication = `Token ${manager.accessToken}`;
      // token has been refreshed to retry requests from all queues
      manager.queue.forEach((cb) => cb());
      // reset variables
      manager.queue = [];
      manager.isRefreshing = false;
      // token has been refreshed to retry requests from all queues

      return config;
    }
    // Refreshing token, returning a promise that did not perform resolve
    return new Promise((resolve) => {
      manager.queue.push(async () => {
        // Update the config with the new token
        config.headers.Authentication = `Token ${manager.accessToken}`;
        resolve(config);
      });
    });
  }
  config.headers.Authentication = `Token ${manager.accessToken}`;
  return config;
});

api.interceptors.response.use(
  (response) => {
    // Remove endpoints one by one
    NetworkTracker.instance.removeEndpoint(response.config.url || '');
    return response;
  },
  (error) => {
    NetworkTracker.instance.removeEndpoint(error.config.url || '');
    return Promise.reject(error);
  },
);

export default api;
