import { InjuryTypeDataSet } from '../../types';

export interface VisitsByInjuryTypeOptions {
  labels: Array<string>;
  data: Array<Array<number>>;
  rawData: InjuryTypeDataSet;
}

export class VisitsByInjuryType {
  public labels: VisitsByInjuryTypeOptions['labels'];

  public data: VisitsByInjuryTypeOptions['data'];

  public rawData: VisitsByInjuryTypeOptions['rawData'];

  constructor(opts: VisitsByInjuryTypeOptions) {
    this.labels = opts.labels;
    this.data = opts.data;
    this.rawData = opts.rawData;
  }
}
