import React from 'react';

import { DowngradedPatientsSerializer } from '../serializer';

import { DownGradedPatientsStore } from './downgraded-patients.store';

export const downgradedPatientsStore = new DownGradedPatientsStore(
  new DowngradedPatientsSerializer(),
);
export const DowngradedPatientsStoreAdapter = React.createContext(
  downgradedPatientsStore,
);
