import { createBrowserRouter, Navigate } from 'react-router-dom';

export default createBrowserRouter([
  {
    path: '/',
    element: <Navigate to="/dashboard/sign_in" />,
  },
  {
    path: '/dashboard',
    lazy: async () => {
      const { AdminDashboard } = await import('./Admin');
      return {
        Component: AdminDashboard,
      };
    },
  },
  {
    path: '/dashboard/sign_in',
    lazy: async () => {
      const { LoginPage } = await import('./Login');
      return {
        Component: LoginPage,
      };
    },
  },
  {
    path: '/dashboard/:token',
    lazy: async () => {
      const { App } = await import('./App');
      return {
        Component: App,
      };
    },
  },
  {
    path: '*',
    element: <div />,
  },
]);
