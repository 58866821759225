import { RecentPatientFuncionalScale } from 'types';

import { RecentPatient } from '../recent-patient.model';

export function computeFunctionScaleMessage(
  scale: RecentPatientFuncionalScale,
  patient: RecentPatient,
) {
  if (patient.sessions === '0/1') {
    return 'Upcoming Visit';
  }
  return scale.message;
}

export function computePSFSAndPainScaleValue(value: string) {
  if (value === '/') {
    return '-';
  }
  return value;
}
export const isNullRomValue = (v?: string) => !v || v === 'null';
// is invalid if the object is empty or null
// if both fields are "null" or null
export const isInvalidRom = (extension: string, flexion: string) =>
  isNullRomValue(extension) && isNullRomValue(flexion);

export function parseRomValue(extension: string, flexion: string) {
  if (isInvalidRom(flexion, extension)) {
    return '-';
  }
  return `${isNullRomValue(extension) ? '-' : extension}/${isNullRomValue(flexion) ? '-' : flexion}`;
}
export const computePhysicianName = (physicianName?: string) => {
  if (!physicianName || physicianName === 'null' || physicianName === 'N/A') {
    return '';
  }
  return `Dr. ${physicianName}`;
};
