export interface PartnerInstanceOptions {
  name: string;
  id: string;
  code: string;
}

export class Partner {
  public name: PartnerInstanceOptions['name'];

  public code: PartnerInstanceOptions['code'];

  public id: PartnerInstanceOptions['id'];

  constructor(opts: PartnerInstanceOptions) {
    this.name = opts.name;
    this.id = opts.id;
    this.code = opts.code;
  }
}
