import React from 'react';

import { PatientSatisfactionSerializer } from '../serializer';

import { PatientSatisfactionStore } from './patient-satisfaction.store';

export const patientSatisfactionScore = new PatientSatisfactionStore(
  new PatientSatisfactionSerializer(),
);

export const PatientSatisfactionAdapter = React.createContext(
  patientSatisfactionScore,
);
