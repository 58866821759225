import './AgeDistribution.css';
import { createId as cuid } from '@paralleldrive/cuid2';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Bar } from 'react-chartjs-2';

import { Button } from 'components/Button';
import { Card } from 'components/Card';
// eslint-disable-next-line import/no-cycle
import { ErrorBoundary } from 'components/ErrorBoundary';
import { Progress } from 'components/Progress';
import { Typography } from 'components/Typography';
import { BarProps } from 'types';

import { AgeDistributionAdapter } from '../stores';
import {
  AgeDistributionCategory,
  AgeDistributionViewModel,
} from '../view-model';

const Fetching: React.FC = function FetchingAgeDistribution() {
  return (
    <Card className="age-distribution">
      <section>
        <Typography
          variant="body"
          component="h3"
          color="darkness"
          className="age-distribution__title"
          fontWeight="normal"
        >
          Age Distribution
        </Typography>

        <Progress
          show
          className="age-distribution__placeholder"
          data-testid="progress"
        />
      </section>
    </Card>
  );
};

interface ChartLegend {
  category: AgeDistributionCategory;
}

const Legend: React.FC<ChartLegend> = function Legend(props) {
  const { category } = props;

  return (
    <li className="age-distribution__legend">
      <div
        className="age-distribution__legend-color"
        style={{ backgroundColor: category.color }}
      />

      <Typography variant="caption" color="darkness">
        {category.label}
      </Typography>
    </li>
  );
};

interface SuccessProps {
  viewModel: AgeDistributionViewModel;
}

function returnMax(numArray: Array<number>): number {
  const absNumArr = numArray.map((num: number) => Math.abs(num));
  return Math.max(...absNumArr);
}

const Success: React.FC<SuccessProps> = function Success(props) {
  const { viewModel } = props;
  const max = [0, 0];

  viewModel.datasets.forEach((dataset, idx) => {
    max[idx] = returnMax(dataset.data);
  });

  const barProps: BarProps = {
    data: {
      labels: viewModel.labels,
      datasets: viewModel.datasets,
    },
    height: 197,
    options: {
      indexAxis: 'y',
      maintainAspectRatio: false,
      scales: {
        x: {
          stacked: true,
          grid: {
            drawBorder: false,
          },
          ticks: {
            color: '#52575C',
            callback(value) {
              return `${Math.trunc(Math.abs(+value))}%`;
            },
            stepSize: 10,
          },
          min: Math.floor(Math.max(...max) * -1),
          max: Math.ceil(Math.max(...max)),
        },
        y: {
          stacked: true,
          position: 'right',
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            padding: 12,
            color: '#52575C',
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label(tooltipItem) {
              // x because its horizontal bar
              return `${Math.abs(tooltipItem.parsed.x)}%`;
            },
          },
        },
      },
    },
  };
  return (
    <Card className="age-distribution">
      <section>
        <Typography
          variant="body"
          component="h3"
          color="darkness"
          className="age-distribution__title"
          fontWeight="normal"
        >
          Age Distribution
        </Typography>

        <div className="age-distribution__chart-container">
          <Bar {...barProps} />
        </div>

        <ul className="age-distribution__legends">
          {viewModel.categories.map((category) => (
            <Legend key={cuid()} category={category} />
          ))}
        </ul>
      </section>
    </Card>
  );
};

interface FailedProps {
  onRetry: VoidFunction;
  error?: Error;
}

const Failed: React.FC<FailedProps> = function Failed(props) {
  const { onRetry, error } = props;

  return (
    <Card className="age-distribution">
      <section>
        <Typography
          variant="body"
          component="h3"
          color="darkness"
          className="age-distribution__title"
          fontWeight="normal"
        >
          Age Distribution
        </Typography>

        <div className="age-distribution__placeholder">
          <Typography
            variant="body"
            component="p"
            color="dark"
            className="age-distribution__title"
          >
            An error prevented us from loading this chart
          </Typography>

          <Button variant="primary" onClick={onRetry}>
            Try Again
          </Button>
        </div>
      </section>
    </Card>
  );
};

const AgeDistributionComponent: React.FC = observer(() => {
  const store = React.useContext(AgeDistributionAdapter);

  if (store.failure) {
    return <Failed onRetry={() => store.refetch()} error={store.error} />;
  }
  if (store.fetched) {
    return <Success viewModel={new AgeDistributionViewModel(store.cache!)} />;
  }
  return <Fetching />;
});

function FallbackUI() {
  return (
    <Card className="fallback-ui age-distribution">
      <Typography
        variant="body"
        component="h3"
        color="darkness"
        className="age-distribution__title"
        fontWeight="normal"
      >
        Something went wrong.
      </Typography>
    </Card>
  );
}

export function AgeDistributionView() {
  return (
    <ErrorBoundary place="Age Distribution" renderError={<FallbackUI />}>
      <AgeDistributionComponent />
    </ErrorBoundary>
  );
}
