import './BaseStyles.css';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

import { DropdownArrowIcon } from 'components/Icons';
import useClickOutside from 'hooks/useClickOutside';
import { DropdownOption, SingleDropdownProps } from 'types';

export function Dropdown(props: SingleDropdownProps) {
  const {
    placeholder = '',
    options = [],
    onChange,
    selectedOption,
    className,
    MenuItem,
  } = props;
  const [selectedValue, setSelectedValue] = useState<DropdownOption | null>(
    null,
  );
  const [ref, isMenuOpen, setIsMenuOpen] = useClickOutside<HTMLDivElement>();

  // if the selectedOption is in the options array then mark it internally as selected
  useEffect(() => {
    const optionValue = Array.isArray(selectedOption)
      ? options.find((o) => selectedOption.every((d) => o.value.includes(d)))
      : options.find((o) => o.value === selectedOption);

    if (optionValue) {
      setSelectedValue(optionValue);
    }
  }, [selectedOption, options]);

  function onClick() {
    setIsMenuOpen((prev) => !prev);
  }

  return (
    <div
      className={clsx(['select-container dropdown', className])}
      ref={ref}
      onClick={onClick}
      onKeyUp={onClick}
      role="menu"
      tabIndex={0}
    >
      <div
        className="select-box"
        style={{ borderColor: isMenuOpen ? '#974EBC' : '#dfe3e8' }}
      >
        <div className="placeholder">{selectedValue?.label || placeholder}</div>
        <div className="icon">
          <DropdownArrowIcon />
        </div>
      </div>
      <div
        className="select-menu"
        style={{ display: isMenuOpen ? 'flex' : 'none' }}
        role="listbox"
      >
        {options.map((o) => (
          <MenuItem
            tooltip={o.tooltip}
            key={o.label}
            value={o.value}
            label={o.label}
            isSelected={selectedValue?.value === o.value}
            onClick={() => {
              setSelectedValue(o);
              onChange(o.value);
            }}
          />
        ))}
      </div>
    </div>
  );
}
