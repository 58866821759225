import { createId as cuid } from '@paralleldrive/cuid2';
import React, {
  createContext,
  useState,
  useMemo,
  useContext,
  PropsWithChildren,
  useCallback,
} from 'react';

import { IToast, ToastType } from 'types';

interface IToastContext {
  toasts: IToast[];
  addToast: (msg: string, type: ToastType) => void;
  removeToast: (key: string) => void;
}

export const ToastContext = createContext<IToastContext>({} as IToastContext);

interface ToastProviderProps {
  initialItems?: IToast[];
}

export function ToastProvider({
  children,
  initialItems = [],
}: PropsWithChildren<ToastProviderProps>) {
  const [toasts, setToasts] = useState<IToast[]>(initialItems);

  function removeToast(key: string) {
    setToasts((prev) => prev.filter((t) => t.key !== key));
  }

  const addToast = useCallback(
    (msg: string, type: ToastType) => {
      setToasts((prev) => [
        ...prev,
        {
          key: cuid(),
          index: toasts.length,
          msg,
          type,
        },
      ]);
    },
    [toasts.length],
  );

  const value = useMemo(
    () => ({
      toasts,
      addToast,
      removeToast,
    }),
    [toasts, addToast],
  );

  return (
    <ToastContext.Provider value={value}>{children}</ToastContext.Provider>
  );
}

export const useToastContext = () => {
  const value = useContext(ToastContext);
  if (!value) {
    throw new Error("useToastContext can't be used outside of a ToastProvider");
  }
  return value;
};
