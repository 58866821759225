import React from 'react';

type SvgProps = JSX.IntrinsicElements['svg'];

export function PromotersIcon({
  width = 19,
  height = 19,
  fill = '#47CC00',
}: SvgProps) {
  const minWidth = 19;
  const minHeight = 19;
  const xMin =
    minWidth - (typeof width === 'string' ? parseInt(width, 10) : width);
  const yMin =
    minHeight - (typeof height === 'string' ? parseInt(height, 10) : height);
  return (
    <svg
      width={width}
      height={height}
      viewBox={`${xMin} ${yMin} ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3513_7361)">
        <path
          d="M9.49992 17.4167C13.8722 17.4167 17.4166 13.8723 17.4166 9.50001C17.4166 5.12776 13.8722 1.58334 9.49992 1.58334C5.12766 1.58334 1.58325 5.12776 1.58325 9.50001C1.58325 13.8723 5.12766 17.4167 9.49992 17.4167Z"
          stroke={fill}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.33325 11.0833C6.33325 11.0833 7.52075 12.6667 9.49992 12.6667C11.4791 12.6667 12.6666 11.0833 12.6666 11.0833"
          stroke={fill}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.125 7.125H7.1325"
          stroke={fill}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.875 7.125H11.8825"
          stroke={fill}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3513_7361">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function PassivesIcon({
  width = 19,
  height = 19,
  fill = '#FFA633',
}: SvgProps) {
  const minWidth = 19;
  const minHeight = 19;
  const xMin =
    minWidth - (typeof width === 'string' ? parseInt(width, 10) : width);
  const yMin =
    minHeight - (typeof height === 'string' ? parseInt(height, 10) : height);
  return (
    <svg
      width={width}
      height={height}
      viewBox={`${xMin} ${yMin} ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3513_7356)">
        <path
          d="M9.49992 17.4167C13.8722 17.4167 17.4166 13.8723 17.4166 9.50001C17.4166 5.12776 13.8722 1.58334 9.49992 1.58334C5.12766 1.58334 1.58325 5.12776 1.58325 9.50001C1.58325 13.8723 5.12766 17.4167 9.49992 17.4167Z"
          stroke={fill}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.33325 11.875H12.6666"
          stroke={fill}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.125 7.125H7.1325"
          stroke={fill}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.875 7.125H11.8825"
          stroke={fill}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3513_7356">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function DetractorsIcon({
  width = 19,
  height = 19,
  fill = '#F03D3D',
}: SvgProps) {
  const minWidth = 19;
  const minHeight = 19;
  const xMin =
    minWidth - (typeof width === 'string' ? parseInt(width, 10) : width);
  const yMin =
    minHeight - (typeof height === 'string' ? parseInt(height, 10) : height);
  return (
    <svg
      width={width}
      height={height}
      viewBox={`${xMin} ${yMin} ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3513_7351)">
        <path
          d="M9.49992 17.4167C13.8722 17.4167 17.4166 13.8723 17.4166 9.50001C17.4166 5.12776 13.8722 1.58334 9.49992 1.58334C5.12766 1.58334 1.58325 5.12776 1.58325 9.50001C1.58325 13.8723 5.12766 17.4167 9.49992 17.4167Z"
          stroke={fill}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.6666 12.6667C12.6666 12.6667 11.4791 11.0833 9.49992 11.0833C7.52075 11.0833 6.33325 12.6667 6.33325 12.6667"
          stroke={fill}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.125 7.125H7.1325"
          stroke={fill}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.875 7.125H11.8825"
          stroke={fill}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3513_7351">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
