import { SerializerError } from 'errors';
import { RecentPatientsResponse } from 'types';

import { RecentPatient } from '../recent-patient.model';

export class RecentPatientsSerializer {
  deserialize(target: RecentPatientsResponse): Array<RecentPatient> {
    const {
      info: { data: patients },
    } = target;

    patients.forEach((patient) => {
      if (typeof patient.name !== 'string') {
        throw new SerializerError(
          'RecentPatientsSerializer',
          'patient name are expected to be string',
        );
      }

      if (typeof patient.date_of_birth !== 'string') {
        throw new SerializerError(
          'RecentPatientsSerializer',
          'patient date_of_birth are expected to be string',
        );
      }
      if (
        patient.date_of_surgery !== null &&
        typeof patient.date_of_surgery !== 'string'
      ) {
        throw new SerializerError(
          'RecentPatientsSerializer',
          'patient date_of_surgery are expected to be string',
        );
      }

      if (typeof patient.pain_scale !== 'string') {
        throw new SerializerError(
          'RecentPatientsSerializer',
          'patient pain_scale are expected to be string',
        );
      }

      if (typeof patient.psfs_scale !== 'string') {
        throw new SerializerError(
          'RecentPatientsSerializer',
          'patient psfs_scale are expected to be string',
        );
      }

      if (typeof patient.sessions !== 'string') {
        throw new SerializerError(
          'RecentPatientsSerializer',
          'patient sessions are expected to be string',
        );
      }

      if (typeof patient.discharged !== 'string') {
        throw new SerializerError(
          'RecentPatientsSerializer',
          'patient discharged are expected to be a string',
        );
      }

      if (!(patient.discharged === 'false' || patient.discharged === 'true')) {
        throw new SerializerError(
          'RecentPatientsSerializer',
          'patient discharged are expected to be "false" or "true"',
        );
      }

      /* if (typeof patient.escalated_full_message !== 'string') {
        throw new SerializerError('RecentPatientsSerializer', 'patient escalated are expected to be a string');
      }

      if (!(patient.escalated === 'false' || patient.escalated === 'true')) {
        throw new SerializerError('RecentPatientsSerializer', 'patient escalated are expected to be "false" or "true"');
      } */
    });

    return patients.map((patient) => new RecentPatient(patient));
  }
}
