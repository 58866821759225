import { MouseEvent } from 'react';

type SvgProps = JSX.IntrinsicElements['svg'];

export const Logo = function Logo(props: SvgProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="127"
      height="36"
      fill="none"
      viewBox="0 0 127 36"
      className="logo"
      data-testid="logo"
      {...props}
    >
      <title>Physical Therapy at Home | Luna Physical Therapy</title>
      <desc>
        Luna physical therapists come to you! Recover faster and get licensed,
        experienced physical therapists at your home. We now serve the SF Bay,
        Greater Los Angeles, and Orange County areas. Available 6:30am-8:30pm, 7
        days a week.
      </desc>
      <path
        fill="#D5B8E4"
        fillRule="evenodd"
        d="M17.7681 0C27.5651 0 35.5361 7.93044 35.5361 17.6785C35.5361 27.427 27.5651 35.357 17.7681 35.357V30.3044C24.7639 30.3044 30.455 24.6413 30.455 17.681C30.455 10.7208 24.7639 5.05836 17.7681 5.05836V0Z"
        clipRule="evenodd"
      />
      <path
        fill="#974EBC"
        fillRule="evenodd"
        d="M17.7681 0V5.05836C10.7726 5.05836 5.08109 10.7208 5.08109 17.681C5.08109 24.6413 10.7726 30.3044 17.7681 30.3044V35.357C7.97061 35.357 0 27.427 0 17.6785C0 7.93044 7.97061 0 17.7681 0Z"
        clipRule="evenodd"
      />
      <path
        fill="#974EBC"
        fillRule="evenodd"
        d="M49.3474 5.0741H45.6984V30.2622H62.1147V27.2141C62.1147 26.529 61.5535 25.9707 60.865 25.9707H50.5986V6.31898C50.5986 5.63246 50.0374 5.0741 49.3474 5.0741Z"
        clipRule="evenodd"
      />
      <path
        fill="#974EBC"
        fillRule="evenodd"
        d="M78.9063 30.2621H82.0788V11.7913H78.6332C77.9385 11.7913 77.3747 12.3525 77.3747 13.0437V23.9078L77.3353 23.9582C76.891 24.5223 75.2505 26.3597 72.777 26.3597C72.0794 26.3597 70.7642 26.2006 69.7877 25.1321C69.0886 24.343 68.8039 23.3739 68.8039 21.7849V11.7913H65.3191C64.6465 11.7913 64.1002 12.3352 64.1002 13.0034V21.5897C64.1002 24.194 64.6946 26.1311 65.9719 27.6885C67.4771 29.5436 69.8314 30.6509 72.2698 30.6509C74.1675 30.6509 75.8942 30.0091 77.2615 28.7944L77.5708 28.5183V28.9319C77.5708 29.6649 78.1697 30.2621 78.9063 30.2621Z"
        clipRule="evenodd"
      />
      <path
        fill="#974EBC"
        fillRule="evenodd"
        d="M101.662 30.2621H105.119V20.851C105.119 17.8871 104.548 15.9482 103.211 14.3667C101.631 12.4825 99.2928 11.4025 96.7936 11.4025C94.8535 11.4025 93.0936 12.0289 91.5635 13.2647L91.2599 13.5106V11.7913H87.9684C87.2972 11.7913 86.7505 12.3352 86.7505 13.0034V29.0126C86.7505 29.7009 87.3146 30.2621 88.0064 30.2621H91.4546V18.1517L91.4897 18.1028C92.091 17.2697 93.8607 15.6937 96.2469 15.6937C97.4532 15.6937 98.5127 16.0857 99.2287 16.7971C100.06 17.6254 100.415 18.7227 100.415 20.4637V29.0216C100.415 29.7052 100.974 30.2621 101.662 30.2621Z"
        clipRule="evenodd"
      />
      <path
        fill="#974EBC"
        fillRule="evenodd"
        d="M122.202 23.9017L122.169 23.9495C121.116 25.459 119.35 26.3597 117.449 26.3597C114.493 26.3597 112.266 24.0752 112.266 21.0461C112.266 17.9955 114.444 15.6937 117.332 15.6937C119.25 15.6937 121.011 16.5713 122.165 18.0995L122.202 18.1489V23.9017ZM123.734 30.2621H126.907V11.7913H123.665C122.965 11.7913 122.397 12.3568 122.397 13.052V13.5121L122.094 13.2647C120.593 12.0458 118.716 11.4025 116.667 11.4025C111.561 11.4025 107.562 15.6389 107.562 21.0461C107.562 23.465 108.406 25.7672 109.938 27.5279C111.627 29.4842 114.245 30.6509 116.941 30.6509C118.898 30.6509 120.632 30.0249 122.091 28.7901L122.397 28.5313V28.9319C122.397 29.6649 122.997 30.2621 123.734 30.2621Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export function LogoutIcon(props: SvgProps) {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 1C4 1.55 3.55 2 3 2H2V14H3C3.55 14 4 14.45 4 15C4 15.55 3.55 16 3 16H1C0.45 16 0 15.55 0 15V1C0 0.45 0.45 0 1 0H3C3.55 0 4 0.45 4 1ZM14.0039 3.4248L16.8179 7.4248C17.0679 7.7788 17.0599 8.2538 16.7999 8.5998L13.7999 12.5998C13.6039 12.8618 13.3029 12.9998 12.9989 12.9998C12.7909 12.9998 12.5799 12.9348 12.3999 12.7998C11.9579 12.4688 11.8689 11.8418 12.1999 11.4008L14.0009 8.9998H13.9999H5.9999C5.4479 8.9998 4.9999 8.5528 4.9999 7.9998C4.9999 7.4468 5.4479 6.9998 5.9999 6.9998H13.9999C14.0164 6.9998 14.0317 7.00436 14.0472 7.00895C14.0598 7.01269 14.0724 7.01645 14.0859 7.0178L12.3679 4.5748C12.0499 4.1238 12.1589 3.4998 12.6109 3.1818C13.0619 2.8628 13.6859 2.9728 14.0039 3.4248Z"
        fill="#454F5B"
      />
    </svg>
  );
}

export function PlusCircleIcon(props: SvgProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="20"
      height="20"
      {...props}
    >
      <g data-name="Layer 2">
        <g data-name="plus-circle">
          <rect width="24" height="24" opacity="0" />
          <path d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8z" />
          <path d="M15 11h-2V9a1 1 0 0 0-2 0v2H9a1 1 0 0 0 0 2h2v2a1 1 0 0 0 2 0v-2h2a1 1 0 0 0 0-2z" />
        </g>
      </g>
    </svg>
  );
}
export function MinusCircleIcon(props: SvgProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="20"
      height="20"
      {...props}
    >
      <g data-name="Layer 2">
        <g data-name="minus-circle">
          <rect width="24" height="24" opacity="0" />
          <path d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8z" />
          <path d="M15 11H9a1 1 0 0 0 0 2h6a1 1 0 0 0 0-2z" />
        </g>
      </g>
    </svg>
  );
}
export function ModalCloseIcon(props: SvgProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="20"
      height="20"
      {...props}
    >
      <g data-name="Layer 2">
        <g data-name="close">
          <rect
            width="24"
            height="24"
            transform="rotate(180 12 12)"
            opacity="0"
          />
          <path d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z" />
        </g>
      </g>
    </svg>
  );
}

export function ThumbsUpIcon(props: SvgProps) {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2265_6536)">
        <path
          d="M0.199219 6.0502V13.8502C0.199219 14.3886 0.635719 14.8252 1.17422 14.8252H4.09922V5.0752H1.17422C0.635719 5.0752 0.199219 5.5117 0.199219 6.0502Z"
          fill="#974EBC"
        />
        <path
          d="M13.9987 6.05022H11.1185C10.8666 6.05022 10.7405 5.88217 10.6981 5.80981C10.6557 5.73792 10.57 5.54606 10.6919 5.32612L11.7074 3.49751C11.9302 3.09713 11.953 2.6263 11.7707 2.20592C11.5883 1.78507 11.2284 1.47991 10.7833 1.36898L10.0673 1.18998C9.89304 1.14618 9.70832 1.20188 9.58692 1.33661L5.6993 5.65556C5.29607 6.1045 5.07422 6.6834 5.07422 7.28611V12.3877C5.07422 13.7317 6.16776 14.8252 7.51172 14.8252L12.3615 14.8247C13.4565 14.8247 14.4234 14.0873 14.7123 13.0318L15.753 8.25586C15.7835 8.12304 15.7992 7.98688 15.7992 7.85073C15.7992 6.85811 14.9913 6.05021 13.9987 6.05021L13.9987 6.05022Z"
          fill="#974EBC"
        />
      </g>
      <defs>
        <clipPath id="clip0_2265_6536">
          <rect
            width="15.6"
            height="15.6"
            fill="white"
            transform="translate(0.199219 0.200195)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export function ThumbsDownIcon(props: SvgProps) {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2265_6507)">
        <path
          d="M0.199219 9.9498V2.14981C0.199219 1.61137 0.635719 1.17481 1.17422 1.17481H4.09922V10.9248H1.17422C0.635719 10.9248 0.199219 10.4883 0.199219 9.9498Z"
          fill="#919EAB"
        />
        <path
          d="M13.9987 9.94978H11.1185C10.8666 9.94978 10.7405 10.1178 10.6981 10.1902C10.6557 10.2621 10.57 10.4539 10.6919 10.6739L11.7074 12.5025C11.9302 12.9029 11.953 13.3737 11.7707 13.7941C11.5883 14.2149 11.2284 14.5201 10.7833 14.631L10.0673 14.81C9.89304 14.8538 9.70832 14.7981 9.58692 14.6634L5.6993 10.3444C5.29607 9.8955 5.07422 9.3166 5.07422 8.71389V3.61228C5.07422 2.26832 6.16776 1.17478 7.51172 1.17478L12.3615 1.17526C13.4565 1.17526 14.4234 1.9127 14.7123 2.96816L15.753 7.74414C15.7835 7.87696 15.7992 8.01312 15.7992 8.14927C15.7992 9.14189 14.9913 9.94979 13.9987 9.94979L13.9987 9.94978Z"
          fill="#919EAB"
        />
      </g>
      <defs>
        <clipPath id="clip0_2265_6507">
          <rect
            width="15.6"
            height="15.6"
            fill="white"
            transform="matrix(1 0 0 -1 0.199219 15.7998)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export function WarningIcon(props: SvgProps) {
  return (
    <svg
      width="38"
      height="32"
      viewBox="0 0 38 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3338 12.0002C17.3338 11.0802 18.0805 10.3335 19.0005 10.3335C19.9205 10.3335 20.6672 11.0802 20.6672 12.0002V18.6668C20.6672 19.5868 19.9205 20.3335 19.0005 20.3335C18.0805 20.3335 17.3338 19.5868 17.3338 18.6668V12.0002ZM17.3338 23.6668C17.3338 22.7468 18.0805 22.0002 19.0005 22.0002C19.9205 22.0002 20.6672 22.7468 20.6672 23.6668C20.6672 24.5868 19.9205 25.3335 19.0005 25.3335C18.0805 25.3335 17.3338 24.5868 17.3338 23.6668ZM33.775 27.5885C33.3933 28.2635 32.65 28.6668 31.7867 28.6668H6.21332C5.34999 28.6668 4.60665 28.2635 4.22665 27.5885C4.04665 27.2718 3.80999 26.6268 4.25499 25.8935L17.04 4.69683C17.8183 3.40683 20.1817 3.40683 20.96 4.69683L33.7467 25.8935C34.19 26.6268 33.955 27.2718 33.775 27.5885ZM36.6017 24.1718L23.815 2.9735C22.8167 1.32016 21.0167 0.333496 19 0.333496C16.9833 0.333496 15.1833 1.32016 14.1867 2.9735L1.39999 24.1718C0.451653 25.7435 0.42332 27.6335 1.32332 29.2268C2.28832 30.9385 4.16332 32.0002 6.21332 32.0002H31.7867C33.8383 32.0002 35.7117 30.9385 36.6783 29.2268C37.5783 27.6335 37.55 25.7435 36.6017 24.1718Z"
        fill="#974EBC"
      />
    </svg>
  );
}

export function SuccessToastIcon(props: SvgProps) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.295 7.60549L9.727 13.6055C9.539 13.8525 9.248 13.9985 8.938 14.0005H8.931C8.624 14.0005 8.334 13.8585 8.144 13.6165L5.712 10.5095C5.372 10.0755 5.448 9.44649 5.883 9.10649C6.317 8.76549 6.947 8.84149 7.287 9.27749L8.92 11.3635L12.704 6.39449C13.038 5.95549 13.665 5.86949 14.106 6.20449C14.545 6.53949 14.63 7.16649 14.295 7.60549ZM10 0.000488281C4.477 0.000488281 0 4.47749 0 10.0005C0 15.5225 4.477 20.0005 10 20.0005C15.523 20.0005 20 15.5225 20 10.0005C20 4.47749 15.523 0.000488281 10 0.000488281Z"
        fill="#097F52"
      />
    </svg>
  );
}

export function ErrorToastIcon(props: SvgProps) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 11C11 11.552 10.552 12 10 12C9.448 12 9 11.552 9 11V6C9 5.448 9.448 5 10 5C10.552 5 11 5.448 11 6V11ZM10 15C9.448 15 9 14.552 9 14C9 13.448 9.448 13 10 13C10.552 13 11 13.448 11 14C11 14.552 10.552 15 10 15ZM10 0C4.477 0 0 4.477 0 10C0 15.523 4.477 20 10 20C15.523 20 20 15.523 20 10C20 4.477 15.523 0 10 0Z"
        fill="#F03D3D"
      />
    </svg>
  );
}

export function ToastCloseIcon(props: SvgProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="20"
      height="20"
      {...props}
    >
      <g data-name="Layer 2">
        <g data-name="close">
          <rect
            width="24"
            height="24"
            transform="rotate(180 12 12)"
            opacity="0"
          />
          <path d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z" />
        </g>
      </g>
    </svg>
  );
}

export function OrderIconOriginal(props: SvgProps) {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="sort"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 512"
      width="20"
      height="20"
      {...props}
    >
      <path
        fill="currentColor"
        d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41zm255-105L177 64c-9.4-9.4-24.6-9.4-33.9 0L24 183c-15.1 15.1-4.4 41 17 41h238c21.4 0 32.1-25.9 17-41z"
      />
    </svg>
  );
}

export function OrderIconSortDown(props: SvgProps) {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="sort-down"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 512"
      width="20"
      height="20"
      {...props}
    >
      <path
        fill="currentColor"
        d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z"
      />
    </svg>
  );
}

export function OrderIconSortUp(props: SvgProps) {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="sort-up"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 512"
      width="20"
      height="20"
      {...props}
    >
      <path
        fill="currentColor"
        d="M279 224H41c-21.4 0-32.1-25.9-17-41L143 64c9.4-9.4 24.6-9.4 33.9 0l119 119c15.2 15.1 4.5 41-16.9 41z"
      />
    </svg>
  );
}

export function DropdownArrowIcon(props: SvgProps) {
  return (
    <svg
      height="20"
      width="20"
      viewBox="0 0 20 20"
      aria-hidden="true"
      focusable="false"
      {...props}
    >
      <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z" />
    </svg>
  );
}

export function InfoIcon(props: SvgProps) {
  function onClick(e: MouseEvent<SVGSVGElement>) {
    e.stopPropagation();
  }
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      {...props}
    >
      <g clipPath="url(#clip0_3211_29122)">
        <path
          d="M7.50019 14.9988C11.6414 14.9988 14.9986 11.6416 14.9986 7.50037C14.9986 3.35911 11.6414 0.00195312 7.50019 0.00195312C3.35892 0.00195312 0.0017662 3.35911 0.0017662 7.50037C0.0017662 11.6416 3.35892 14.9988 7.50019 14.9988Z"
          fill="#f03d3d"
        />
        <path
          d="M8.16509 5.03893C8.8276 5.03893 9.36467 4.56458 9.36467 3.97943C9.36467 3.39428 8.8276 2.91992 8.16509 2.91992C7.50258 2.91992 6.96551 3.39428 6.96551 3.97943C6.96551 4.56458 7.50258 5.03893 8.16509 5.03893Z"
          fill="white"
        />
        <path
          d="M5.36756 6.47581L5.25408 6.9787C5.25408 6.9787 6.99026 6.21593 6.50342 8.04953C6.01659 9.88313 5.36756 11.3923 6.51975 11.9276C7.67193 12.4629 9.08347 11.5869 9.08347 11.5869L9.16456 11.1164C9.16456 11.1164 7.54186 11.7494 7.80147 10.5485C8.06108 9.34758 9.19695 6.70277 7.93128 6.16722C6.66561 5.63168 5.36756 6.47554 5.36756 6.47554V6.47581Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3211_29122">
          <rect width="15" height="15" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
