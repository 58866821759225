import './Section.css';

import clsx from 'clsx';
import React from 'react';

type Props = JSX.IntrinsicElements['section'] & {
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
  variant?: 'standalone';
  transparent?: boolean;
  transparentContainer?: boolean;
};

export const Section: React.FC<Props> = function Section({
  containerProps = {},
  variant = 'standalone',
  transparent,
  transparentContainer,
  ...rest
}) {
  const { className: containerClassName, ...otherContainerProps } =
    containerProps;
  const { className, ...restOfSectionProps } = rest;

  switch (variant) {
    /**
     * This variant matches the form of the registration page
     * This variant should be used with an AppBar that is not fixed.
     */
    case 'standalone':
      return (
        <div
          className={clsx([
            'container',
            transparentContainer && `container--transparent`,
            containerClassName,
          ])}
          {...otherContainerProps}
        >
          <section {...restOfSectionProps} />
        </div>
      );

    default:
      return null;
  }
};
