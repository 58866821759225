import './Row.css';
import { PropsWithChildren } from 'react';

export function SingleRow(props: PropsWithChildren) {
  return <div className="row" {...props} />;
}

export function TwoThirdAndOneThirdRow(props: PropsWithChildren) {
  return <div className="row row--2-1" {...props} />;
}

export function AutoFitRow(props: PropsWithChildren) {
  return <div className="row row--fluid" {...props} />;
}

export function BodyChartRow(props: PropsWithChildren) {
  return <div className="row row--body-chart-row" {...props} />;
}
