import './colors.css';
import './fonts.css';
import './index.css';

import React from 'react';
import { createRoot } from 'react-dom/client';

import App from 'app';
import initLogRocket from 'setupLogRocket';
import initSentry from 'setupSentry';

initSentry();
initLogRocket();

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
