export interface TherapistInstanceOptions {
  id: string;
  name: string;
  prefix: string;
  hubspot_id: string;
}

export class Therapist {
  public id: TherapistInstanceOptions['id'];

  public name: TherapistInstanceOptions['name'];

  public prefix: TherapistInstanceOptions['prefix'];

  public hubspotId: TherapistInstanceOptions['hubspot_id'];

  constructor(opts: TherapistInstanceOptions) {
    this.id = opts.id;
    this.name = opts.name;
    this.prefix = opts.prefix;
    this.hubspotId = opts.hubspot_id;
  }
}
