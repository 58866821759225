import LogRocket from 'logrocket';

/* eslint-disable no-underscore-dangle */
export class NetworkTracker {
  private static _instance: NetworkTracker;

  public endpoints: string[] = [];

  public hasFinished = false;

  private startedAt = -1;

  public static get instance(): NetworkTracker {
    if (!NetworkTracker._instance) {
      NetworkTracker._instance = new NetworkTracker();
    }

    return NetworkTracker._instance;
  }

  addAdminEndpoints() {
    if (this.hasFinished) {
      return;
    }
    this.endpoints = this.endpoints.concat([
      'partners',
      'clinics',
      'physicians',
      'case_distribution',
      'visits_by_injury',
      'age_distribution',
      'change_in_psfs_scale',
      'change_in_pain_level',
      'body_parts',
      'nps',
      'patient_satisfaction',
    ]);
    LogRocket.track('cd_initial_page_load');
    this.startedAt = Date.now();
  }

  addExternalEndpoints() {
    if (this.hasFinished) {
      return;
    }
    this.endpoints = this.endpoints.concat([
      'case_distribution',
      'visits_by_injury',
      'age_distribution',
      'body_parts',
      'change_in_pain_level',
      'nps',
      'change_in_psfs_scale',
      'patient_satisfaction',
      'patients_treated',
      'unseen_patients',
    ]);
    LogRocket.track('cd_initial_page_load');
    this.startedAt = Date.now();
  }

  removeEndpoint(endpoint: string) {
    if (this.hasFinished || this.startedAt < 0) {
      return;
    }
    this.endpoints = this.endpoints.filter((slug) => !endpoint.includes(slug));
    // Finish all timing
    if (this.endpoints.length === 0) {
      const seconds = (Date.now() - this.startedAt) / 1000;
      LogRocket.track('cd_final_page_load', {
        time: parseFloat(seconds.toFixed(2)),
      });
      this.hasFinished = true;
    }
  }
}
