import React from 'react';

import { ChangeInPainLevelSerializer } from '../serializer';

import { ChangeInPainLevelStore } from './change-in-pain-level.store';

export const changeInPainScaleStore = new ChangeInPainLevelStore(
  new ChangeInPainLevelSerializer(),
);

export const ChangeInPainLevelAdapter = React.createContext(
  changeInPainScaleStore,
);
