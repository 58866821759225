import clsx from 'clsx';
import { useCallback, useRef, useState } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useVirtual, VirtualItem } from 'react-virtual';

import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionItemBody,
  AccordionItemBodyRow,
  AccordionItemHeader,
  AccordionItems,
} from 'components/Accordion';
import { DropdownArrowIcon, InfoIcon } from 'components/Icons';
import { Typography } from 'components/Typography';
import { RecentPatientFuncionalScale } from 'types';
import { computeInjuryName } from 'utils';

import { RecentPatient } from '../recent-patient.model';
import { PatientChartInfo, SuccessProps } from '../types';

import {
  computeFunctionScaleMessage,
  computePSFSAndPainScaleValue,
  parseRomValue,
} from './utils';

const PATIENTS_TABLE_ROW_HEIGHT = 52;
const PATIENTS_TABLE_ROW_OPEN = 361;
const PATIENTS_TABLE_ROW_FULL_OPEN = 426;
export const EMPTY_CONTAINER_HEIGHT = 100;

export function EmptyBody() {
  return (
    <div className="recent-patients__accordion empty-results-container">
      <Typography variant="heading3">No Results</Typography>
      <Typography variant="parragraph" color="gray-chateau" component="p">
        There are no patients matching your search or filter criteria.
      </Typography>
    </div>
  );
}

const getFunctionScale = (
  fsScale: RecentPatientFuncionalScale,
  patient: RecentPatient,
): JSX.Element =>
  fsScale ? (
    <div
      data-tooltip-id={`functionalScale-${patient.key}`}
      data-tooltip-content={computeFunctionScaleMessage(fsScale, patient)}
      className={clsx(
        'recent-patients__functional-scale',
        fsScale && `recent-patients__functional-scale--${fsScale.color}`,
      )}
    >
      <ReactTooltip id={`functionalScale-${patient.key}`} place="top" />
    </div>
  ) : (
    <div />
  );

type AccordionRowProps = {
  patient: RecentPatient;
  handleChartsCallback: (patient: RecentPatient, completed: boolean) => void;
  v: VirtualItem;
  hasEscalatedData: boolean;
  shouldHideRom: boolean;
};
function AccordionRow({
  v,
  patient,
  handleChartsCallback,
  hasEscalatedData,
  shouldHideRom,
}: AccordionRowProps) {
  return (
    <AccordionItem
      key={patient.key}
      index={v.index}
      style={{
        position: 'absolute',
        top: v.start,
        left: 0,
        width: '100%',
        height: v.size,
      }}
    >
      <AccordionItemHeader
        className={clsx(
          patient.discharged && 'recent-patients__table-row--discharged',
        )}
      >
        <div className="patient-name-container">
          {patient.functionalScale &&
            getFunctionScale(patient.functionalScale, patient)}
          <Typography variant="caption" component="p" color="darkness">
            {patient.name}
          </Typography>
          {hasEscalatedData && (
            <>
              <InfoIcon
                id={`escalatedPatient-${patient.key}`}
                className="recent-patients__escalated-icon"
              />
              <ReactTooltip
                anchorSelect={`#escalatedPatient-${patient.key}`}
                variant="dark"
                place="top"
                className="patient-treated__tooltip"
              >
                <span>{patient.escalatedMessage}</span>
              </ReactTooltip>
            </>
          )}
        </div>
        <div className="patient-session-contaniner">
          <Typography variant="caption" component="p" color="darkness">
            {patient.sessions}
          </Typography>
        </div>
        <DropdownArrowIcon className="arrow-icon" />
      </AccordionItemHeader>
      <AccordionItemBody>
        <AccordionItemBodyRow>
          <Typography variant="caption" color="gray-chateau" component="p">
            Injury
            <br />
            Name
          </Typography>
          <Typography variant="caption" component="p" color="darkness">
            {computeInjuryName(patient.injuryName)}
          </Typography>
        </AccordionItemBodyRow>
        <AccordionItemBodyRow>
          <Typography variant="caption" color="gray-chateau" component="p">
            Pain Score
            <br />
            Latest/Intake
          </Typography>
          <Typography variant="caption" component="p" color="darkness">
            {computePSFSAndPainScaleValue(patient.painScale)}
          </Typography>
        </AccordionItemBodyRow>
        <AccordionItemBodyRow>
          <Typography variant="caption" color="gray-chateau" component="p">
            PSFS Score
            <br />
            Latest/Intake
          </Typography>
          <Typography variant="caption" component="p" color="darkness">
            {computePSFSAndPainScaleValue(patient.psfsScale)}
          </Typography>
        </AccordionItemBodyRow>
        {!shouldHideRom && (
          <AccordionItemBodyRow>
            <Typography variant="caption" color="gray-chateau" component="p">
              ROM
              <br />
              Extension/Flexion
            </Typography>
            <Typography variant="caption" component="p" color="darkness">
              {parseRomValue(patient.rom_extension, patient.rom_flexion)}
            </Typography>
          </AccordionItemBodyRow>
        )}
        <AccordionItemBodyRow>
          <div>
            <Typography variant="caption" color="gray-chateau" component="p">
              Functional Scale
            </Typography>
            <Typography variant="caption" color="darkness" component="p">
              {patient.functionalScale?.message}
            </Typography>
          </div>
          {patient.functionalScale &&
            getFunctionScale(patient.functionalScale, patient)}
        </AccordionItemBodyRow>
        <AccordionItemBodyRow>
          <Typography
            variant="link"
            component="p"
            color="blue-link"
            onClick={() => {
              handleChartsCallback(patient, true);
            }}
          >
            View Charts
          </Typography>
          <Typography
            variant="link"
            component="p"
            color="blue-link"
            onClick={() => {
              handleChartsCallback(patient, false);
            }}
          >
            View Sessions
          </Typography>
        </AccordionItemBodyRow>
      </AccordionItemBody>
    </AccordionItem>
  );
}

export const RecentPatientsTreatedMobileView: React.FC<SuccessProps> =
  function RecentPatientsTreatedMobileView({
    model,
    handleCharts,
    shouldHideRom,
  }) {
    const handleChartsCallback = (
      patient: RecentPatient,
      completedOnly: boolean,
    ) => {
      const info: PatientChartInfo = {
        dob: patient.date_of_birth,
        patientName: patient.name,
        patientId: patient.patientId,
        physicianId: patient.physicianId,
        partnerId: patient.partnerId,
        physicianName: patient.physicianName,
      };
      handleCharts(info, completedOnly);
    };

    const [indexOpen, setIndexOpen] = useState(-1);

    // dynamic measurement works with react-virtual but only if item size doesn't change after render,
    // with the accordion this is not the case so we declare explicitly the size of given item
    const estimateItemSize = useCallback(
      (i: number) => {
        if (i === indexOpen) {
          if (shouldHideRom) {
            return PATIENTS_TABLE_ROW_OPEN;
          }
          return PATIENTS_TABLE_ROW_FULL_OPEN;
        }
        return PATIENTS_TABLE_ROW_HEIGHT;
      },
      [indexOpen, shouldHideRom],
    );

    const containerRef = useRef<HTMLDivElement>(null);

    const { virtualItems, totalSize } = useVirtual({
      parentRef: containerRef,
      size: model.length,
      overscan: 10,
      estimateSize: estimateItemSize,
    });

    const isEmpty = virtualItems.length === 0;

    return (
      <Accordion
        className="recent-patients__accordion"
        onItemOpen={setIndexOpen}
      >
        <AccordionHeader className="extra-space">
          <Typography variant="caption" color="gray-chateau" component="span">
            Patient
          </Typography>
          <Typography variant="caption" color="gray-chateau" component="span">
            Sessions
            <br />
            Cmpl/Pndg
          </Typography>
        </AccordionHeader>
        <AccordionItems
          ref={containerRef}
          height={isEmpty ? EMPTY_CONTAINER_HEIGHT : totalSize}
        >
          {isEmpty ? (
            <EmptyBody />
          ) : (
            virtualItems.map((v) => {
              const patient = model[v.index];
              const hasEscalatedData = !!patient.escalatedMessage;
              return (
                <AccordionRow
                  key={v.key}
                  v={v}
                  patient={patient}
                  handleChartsCallback={handleChartsCallback}
                  hasEscalatedData={hasEscalatedData}
                  shouldHideRom={shouldHideRom}
                />
              );
            })
          )}
        </AccordionItems>
      </Accordion>
    );
  };
