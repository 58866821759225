import './Button.css';
import clsx from 'clsx';
import { PropsWithChildren, createElement } from 'react';

import { Typography, TypographyProps } from 'components/Typography';

type Props = JSX.IntrinsicElements['button'] &
  JSX.IntrinsicElements['label'] & {
    variant?: 'base' | 'primary' | 'link' | 'secondary';
    component?: string;
    typographyProps?: TypographyProps;
  };

export function Button({
  variant = 'base',
  typographyProps = {},
  className,
  children,
  component = 'button',
  ...rest
}: PropsWithChildren<Props>) {
  const buttonLabel = {
    ...typographyProps,
    className: clsx(['buttonTypography', typographyProps.className]),
  };

  return createElement(
    component,
    {
      className: clsx([
        'button',
        variant === 'primary' && 'button--primary',
        variant === 'link' && 'button--link',
        variant === 'secondary' && 'button--secondary',
        className,
      ]),
      ...rest,
    },
    <Typography component="span" {...buttonLabel}>
      {children}
    </Typography>,
  );
}
