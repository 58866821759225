import { createId as cuid } from '@paralleldrive/cuid2';

/** DEPRECATED */
const FALLBACK_REASON = 'Patient - Unqualified: Other';

export interface DowngradedPatientRowInstanceOptions {
  key?: string;
  patient: string;
  date_of_referral: string;
  date_of_birth: string;
  reason_not_booked: string;
  hubspot_id: string;
}
export class DowngradedPatientRow {
  key: string = cuid();

  name: DowngradedPatientRowInstanceOptions['patient'];

  dateOfReferral: DowngradedPatientRowInstanceOptions['date_of_referral'];

  dob: DowngradedPatientRowInstanceOptions['date_of_birth'];

  reasonNotBooked: DowngradedPatientRowInstanceOptions['reason_not_booked'];

  hubspotId: DowngradedPatientRowInstanceOptions['hubspot_id'];

  constructor(opts: DowngradedPatientRowInstanceOptions) {
    if (opts.key) {
      this.key = opts.key;
    }

    this.name = opts.patient;
    this.dob = opts.date_of_birth;
    this.dateOfReferral = opts.date_of_referral;
    this.reasonNotBooked = opts.reason_not_booked;
    this.hubspotId = opts.hubspot_id;
  }
}
