import React from 'react';

import { CaseDistributionSerializer } from '../serializer';

import { CaseDistributionStore } from './case-distribution.store';

export const caseDistributionStore = new CaseDistributionStore(
  new CaseDistributionSerializer(),
);

export const CaseDistributionAdapter = React.createContext(
  caseDistributionStore,
);
