import { createId as cuid } from '@paralleldrive/cuid2';

export interface OpenTaskPatienInstanceOptions {
  key?: string;
  patient_name: string;
  plan_of_care: string;
  initial_visit: string;
  plan_of_care_url: string | null;
  plan_of_care_id: string | null;
  visit_type: string | null;
  healthy_url: boolean;
}

export class OpenTaskPatient {
  key: string = cuid();

  patientName: OpenTaskPatienInstanceOptions['patient_name'];

  planOfCare: OpenTaskPatienInstanceOptions['plan_of_care'];

  initialVisit: OpenTaskPatienInstanceOptions['initial_visit'];

  planOfCareURL: OpenTaskPatienInstanceOptions['plan_of_care_url'];

  planOfCareId: OpenTaskPatienInstanceOptions['plan_of_care_id'];

  visitType: OpenTaskPatienInstanceOptions['visit_type'];

  healthyURL: OpenTaskPatienInstanceOptions['healthy_url'];

  constructor(opts: OpenTaskPatienInstanceOptions) {
    if (opts.key) {
      this.key = opts.key;
    }

    this.patientName = opts.patient_name;
    this.planOfCare = opts.plan_of_care;
    this.initialVisit = opts.initial_visit;
    this.planOfCareURL = opts.plan_of_care_url;
    this.planOfCareId = opts.plan_of_care_id;
    this.visitType = opts.visit_type;
    this.healthyURL = opts.healthy_url;
  }
}
