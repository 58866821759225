import './Card.css';
import clsx from 'clsx';
import { PropsWithChildren } from 'react';

export function Card({
  className,
  ...rest
}: PropsWithChildren<JSX.IntrinsicElements['article']>) {
  return <article className={clsx(['card', className])} {...rest} />;
}
