/* eslint-disable @typescript-eslint/no-explicit-any */
import './DowngradedPatients.css';
import { observer } from 'mobx-react-lite';
import { ChangeEvent, useContext } from 'react';

import { Button } from 'components/Button';
import { Card } from 'components/Card';
import { ConditionalComponent } from 'components/ConditionalComponent';
import { Dropdown, MenuOptions } from 'components/Dropdown';
// eslint-disable-next-line import/no-cycle
import { ErrorBoundary } from 'components/ErrorBoundary';
import { Progress } from 'components/Progress';
import { Typography } from 'components/Typography';
import { useBodyMachine } from 'features/body/views/useBodyMachine';
import { RecentPatientsStoreAdapter } from 'features/recent-patients';
import useMediaQuery from 'hooks/useMediaQuery';
import { DropdownOption } from 'types';

import { DowngradedPatientRow } from '../downgraded-patient-row.model';
import { DowngradedPatientsStoreAdapter } from '../store';
import { DowngradedPatientViewModel } from '../view-model';

import { DowngradedPatients } from './DowngradedPatients';
import { DowngradedPatientsChart } from './DowngradedPatientsChart';
import { DowngradedPatientsMobileView } from './DowngradedPatientsMobile';
import { EmptyState } from './EmptyState';
import { hubspotMapping } from './utils';

interface FailedProps {
  onRetry: VoidFunction;
  error?: Error;
  view: string;
}

export const Fetching: React.FC = function Fetching() {
  return (
    <Card className="recent-patients">
      <section>
        <Typography
          variant="body"
          color="darkness"
          component="h3"
          fontWeight="normal"
          className="recent-patients__title"
        >
          Unseen Patients
        </Typography>

        <Progress show className="recent-patients__placeholder" />
      </section>
    </Card>
  );
};

export const Failed: React.FC<FailedProps> = observer((props) => {
  const { onRetry, view, error } = props;
  return (
    <Card className="recent-patients">
      <section className="recent-patients__error">
        <Typography
          variant="body"
          component="p"
          color="dark"
          className="recent-patients__error-message"
        >
          An error prevented us from loading this {view}
        </Typography>

        <Button variant="primary" onClick={onRetry}>
          Try Again
        </Button>
      </section>
    </Card>
  );
});

type DowngradedPatientsTableProps = {
  onChangeViewMode: VoidFunction;
  model: Array<DowngradedPatientRow>;
};

interface DropdownMultiValueOptions extends Omit<DropdownOption, 'value'> {
  value: string[];
}

const labelToValues = Object.entries(hubspotMapping).reduce(
  (acc, [internalValue, label]) => {
    acc[label] = acc[label] || [];
    acc[label].push(internalValue);
    return acc;
  },
  {} as Record<string, string[]>,
);

const filters: DropdownMultiValueOptions[] = Object.entries(labelToValues).map(
  ([label, value], i) => ({
    label,
    value,
    index: i + 1,
  }),
);

const LABEL_TO_IGNORE = 'Booked';
const INTERNAL_PROPERTY_TO_IGNORE = 'Patient - Qualified: Booked in Luxe';
const dropdownOptions = [
  { label: 'All', value: [], index: 0 },
  ...filters,
].filter((o) => o.label !== LABEL_TO_IGNORE);

function DowngradedPatientsTable({
  onChangeViewMode,
  model,
}: DowngradedPatientsTableProps) {
  const store = useContext(DowngradedPatientsStoreAdapter);
  const matchesMobileQuery = useMediaQuery('(max-width: 768px)');
  const usableRows = model.filter(
    (r) => r.reasonNotBooked !== INTERNAL_PROPERTY_TO_IGNORE,
  );

  function onSearchUpdated(e: ChangeEvent<HTMLInputElement>) {
    store.setFilteredPatients(e.target.value);
  }

  return (
    <Card className="recent-patients">
      <section>
        <div className="downgraded-patients__header">
          <div className="recent-patients__header-info">
            <Typography
              variant="body"
              color="darkness"
              component="h3"
              fontWeight="normal"
              className="recent-patients__title downgraded-patients__title"
            >
              Unseen Patients
            </Typography>
            <ConditionalComponent validate={!matchesMobileQuery}>
              <Typography variant="caption" color="gray-chateau" component="p">
                Patient search results override Reason Not Booked Filter.
              </Typography>
            </ConditionalComponent>
          </div>
          <ConditionalComponent validate={matchesMobileQuery}>
            <Typography
              component="p"
              color="blue-link"
              className="downgraded-patients__view-chart"
              onClick={onChangeViewMode}
            >
              View chart
            </Typography>
          </ConditionalComponent>
          <ConditionalComponent validate={!matchesMobileQuery}>
            <Dropdown
              MenuItem={MenuOptions}
              placeholder="Reasons Not Booked"
              selectedOption={store.filterStatus}
              options={dropdownOptions}
              onChange={(val: any) => {
                store.setFilteredStatus(val);
              }}
              className="downgraded-patients__dropdown"
            />
          </ConditionalComponent>
        </div>
        <ConditionalComponent validate={matchesMobileQuery}>
          <Typography variant="caption" color="gray-chateau" component="p">
            Patient search results override Reason Not Booked Filter.
          </Typography>
        </ConditionalComponent>
        <div className="downgraded-patients__header-alt">
          <ConditionalComponent validate={!matchesMobileQuery}>
            <Typography
              component="p"
              color="blue-link"
              className="downgraded-patients__view-chart"
              onClick={onChangeViewMode}
            >
              View chart
            </Typography>
          </ConditionalComponent>
          <ConditionalComponent validate={matchesMobileQuery}>
            <Dropdown
              MenuItem={MenuOptions}
              placeholder="Reasons Not Booked"
              selectedOption={store.filterStatus}
              options={dropdownOptions}
              onChange={(val: any) => {
                store.setFilteredStatus(val);
              }}
              className="downgraded-patients__dropdown-full"
            />
          </ConditionalComponent>
        </div>
        <ConditionalComponent validate={matchesMobileQuery}>
          <input
            className="user-input"
            placeholder="Search for Patient"
            value={store.searchTerm}
            onChange={onSearchUpdated}
          />
        </ConditionalComponent>
        <ConditionalComponent validate={!matchesMobileQuery}>
          <DowngradedPatients model={usableRows} />
        </ConditionalComponent>
        <ConditionalComponent validate={matchesMobileQuery}>
          <DowngradedPatientsMobileView model={usableRows} />
        </ConditionalComponent>
      </section>
    </Card>
  );
}

const DowngradedPatientsComponent: React.FC = observer(() => {
  const store = useContext(DowngradedPatientsStoreAdapter);
  const patientsTreatedStore = useContext(RecentPatientsStoreAdapter);
  const bookedPatients =
    store.time === '90' && typeof patientsTreatedStore.data !== 'undefined'
      ? patientsTreatedStore.data.length
      : 0;
  const [mode, onChangeViewMode] = useBodyMachine(
    'Downgraded Patients',
    'table',
  );
  if (store.fetched) {
    if (mode === 'table') {
      if (store.cache!.length === 0) {
        return <EmptyState />;
      }
      return (
        <DowngradedPatientsTable
          onChangeViewMode={onChangeViewMode}
          model={store.rows || []}
        />
      );
    }
    return (
      <DowngradedPatientsChart
        model={new DowngradedPatientViewModel(store.data!, bookedPatients)}
        onChangeViewMode={onChangeViewMode}
      />
    );
  }
  if (store.failure) {
    return (
      <Failed onRetry={() => store.refetch()} error={store.error} view={mode} />
    );
  }
  return <Fetching />;
});

function FallbackUI() {
  return (
    <Card className="fallback-ui recent-patients">
      <Typography
        variant="body"
        component="p"
        color="dark"
        className="recent-patients__error-message"
      >
        Something went wrong.
      </Typography>
    </Card>
  );
}

export function DowngradedPatientsView() {
  return (
    <ErrorBoundary place="Downgraded Patients" renderError={<FallbackUI />}>
      <DowngradedPatientsComponent />
    </ErrorBoundary>
  );
}
