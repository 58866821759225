/* eslint-disable no-nested-ternary */
import clsx from 'clsx';
import React from 'react';

// eslint-disable-next-line import/no-cycle
import { metricLogger } from 'app/routes/Dashboard';
import { Button } from 'components/Button';
import { Modal } from 'components/Modal';
import { PDFViewer } from 'components/PDFViewer';
import { RadioButton } from 'components/RadioButton';
import { Typography } from 'components/Typography';

import OpenTaskContext from '../store/open-tasks.context';
import { SignOptions } from '../types';

export interface SignModalProps {
  showPdfModal: boolean;
  showSignModal: boolean;
  setShowPdfModal: (v: boolean) => void;
  handleCloseSignModal: () => void;
}

function signValueToMetric(v: string) {
  if (v === 'approveSign') {
    return 'sign';
  }
  if (v === 'approveSignWithNote') {
    return 'sign with comment';
  }
  return 'reject';
}

export function SignModal({
  showPdfModal,
  showSignModal,
  setShowPdfModal,
  handleCloseSignModal,
}: SignModalProps) {
  const {
    store,
    onChangeSignValue,
    noteView,
    currentSign,
    onChangeNote,
    signOpenTaskEvent,
    isSigningPOC,
  } = React.useContext(OpenTaskContext);
  const signOpenTask = async () => {
    // this is only done by doctors
    metricLogger.sendMetrics({
      patient_name: store.actualOpenTask?.patientName,
      kind: 'signed_poc',
      extras: {
        poc_id: store.actualOpenTask?.planOfCareId || undefined,
        poc_comment: currentSign.context.signNote || '',
        poc_sign_kind: signValueToMetric(currentSign.context.signValue),
      },
    });
    try {
      await signOpenTaskEvent();
    } catch (e) {
      if (e instanceof Error) {
        metricLogger.sendMetrics({
          kind: 'error',
          patient_name: store.actualOpenTask?.patientName,
          extras: {
            text: e.message || '',
            context: 'sign open task',
            poc_id: store.actualOpenTask?.planOfCareId || undefined,
            poc_comment: currentSign.context.signNote || '',
            poc_sign_kind: signValueToMetric(currentSign.context.signValue),
          },
        });
      }
    }
  };
  return (
    <>
      {showPdfModal && (
        <Modal
          show={showPdfModal}
          handleClose={() => setShowPdfModal(false)}
          size="large"
          title={`Plan of care - ${store.actualOpenTask?.patientName}`}
          className="modal__poc-pdf"
        >
          <div className="open-tasks__modal-content-container">
            {store.actualOpenTask?.planOfCareURL ? (
              store.actualOpenTask.healthyURL ? (
                <PDFViewer src={store.actualOpenTask.planOfCareURL} />
              ) : (
                <Typography
                  variant="body"
                  color="abbey"
                  component="p"
                  fontWeight="normal"
                >
                  Link to view the plan of care has expired. Please refresh this
                  page and try again.
                </Typography>
              )
            ) : (
              <Typography
                variant="body"
                color="abbey"
                component="p"
                fontWeight="normal"
              >
                No document to show
              </Typography>
            )}
          </div>
        </Modal>
      )}

      <Modal
        show={showSignModal}
        handleClose={handleCloseSignModal}
        title={`Plan of Care - ${store.actualOpenTask?.patientName}`}
      >
        <div className="open-tasks__modal-content-container">
          <div className="open-tasks__sign-option-container">
            <RadioButton
              value="approveSign"
              checked={currentSign.context.signValue === 'approveSign'}
              onChange={({ target }) =>
                onChangeSignValue(target.value as SignOptions)
              }
            />
            <Typography
              variant="body"
              color="abbey"
              component="label"
              fontWeight="normal"
              onClick={() => onChangeSignValue('approveSign')}
            >
              Electronically approve the Plan of Care as documented.
            </Typography>
          </div>
          <div className="open-tasks__sign-option-container">
            <RadioButton
              value="approveSignWithNote"
              checked={currentSign.context.signValue === 'approveSignWithNote'}
              onChange={({ target }) =>
                onChangeSignValue(target.value as SignOptions)
              }
            />
            <Typography
              variant="body"
              color="abbey"
              component="label"
              fontWeight="normal"
              onClick={() => onChangeSignValue('approveSignWithNote')}
            >
              Electronically approve the Plan of Care with changes noted below.
            </Typography>
          </div>
          <div className="open-tasks__sign-option-container">
            <RadioButton
              value="noApproveSign"
              checked={currentSign.context.signValue === 'noApproveSign'}
              onChange={({ target }) =>
                onChangeSignValue(target.value as SignOptions)
              }
            />
            <Typography
              variant="body"
              color="abbey"
              component="label"
              fontWeight="normal"
              onClick={() => onChangeSignValue('noApproveSign')}
            >
              Plans of Care is not approved. Ask the patient to return to my
              office.
            </Typography>
          </div>
          <div>
            {noteView === 'signWithNote' && (
              <>
                <textarea
                  name="textarea"
                  className={clsx([
                    'open-tasks__sign-note',
                    currentSign.context.validNote
                      ? 'open-tasks__sign-note-valid'
                      : 'open-tasks__sign-note-invalid',
                  ])}
                  placeholder="Write your note here"
                  value={currentSign.context.signNote}
                  onChange={(e) => onChangeNote(e.target.value)}
                />
                {!currentSign.context.validNote && (
                  <Typography variant="caption" color="error" component="p">
                    This field is required.
                  </Typography>
                )}
              </>
            )}
          </div>
          <div className="open-tasks__sign-footer">
            <Button variant="secondary" onClick={handleCloseSignModal}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={signOpenTask}
              disabled={isSigningPOC}
            >
              Confirm
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}
