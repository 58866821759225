/* eslint-disable react-hooks/exhaustive-deps */
import './Accordion.css';
import clsx from 'clsx';
import {
  useState,
  useMemo,
  useEffect,
  PropsWithChildren,
  forwardRef,
} from 'react';

import {
  AccordionContext,
  AccordionItemContext,
  useAccordion,
  useAccordionItem,
} from './Context';

interface CustomClassNameProps {
  className?: string;
}
export interface AccordionProps extends CustomClassNameProps {
  defaultIndexOpen?: number;
  onItemOpen?: (i: number) => void;
}

interface AccordionItemProps extends CustomClassNameProps {
  index: number;
  children: React.ReactNode;
  style?: React.CSSProperties;
}

export function Accordion({
  children,
  className,
  onItemOpen,
  defaultIndexOpen = -1,
}: PropsWithChildren<AccordionProps>) {
  const [activeIndex, setActiveIndex] = useState<number>(defaultIndexOpen);

  useEffect(() => {
    onItemOpen?.(activeIndex);
  }, [activeIndex]);

  const value = useMemo(() => {
    function onAccordionOpen(index: number) {
      return function onOpen() {
        if (activeIndex === index) {
          setActiveIndex(-1);
          return;
        }
        setActiveIndex(index);
      };
    }
    return {
      activeIndex,
      onAccordionOpen,
    };
  }, [activeIndex]);

  return (
    <AccordionContext.Provider value={value}>
      <div className={clsx(['accordion', className])}>{children}</div>
    </AccordionContext.Provider>
  );
}

export function AccordionHeader({
  children,
  className,
}: PropsWithChildren<CustomClassNameProps>) {
  return (
    <div className={clsx(['accordion__header', className])}>{children}</div>
  );
}

export const AccordionItem = forwardRef<HTMLDivElement, AccordionItemProps>(
  // eslint-disable-next-line prefer-arrow-callback
  function AccordionItem({ children, index, style, className }, ref) {
    const { activeIndex } = useAccordion();

    const value = useMemo(
      () => ({ isActive: activeIndex === index, index }),
      [activeIndex, index],
    );

    return (
      <AccordionItemContext.Provider value={value}>
        <div
          className={clsx('accordion__item', className)}
          ref={ref}
          style={style}
        >
          {children}
        </div>
      </AccordionItemContext.Provider>
    );
  },
);

interface AccordionItemStateProps {
  children(args: Partial<{ expanded: boolean }>): React.ReactNode;
}

export function AccordionItemState({ children }: AccordionItemStateProps) {
  const { isActive } = useAccordionItem();
  return children({ expanded: isActive });
}

export function AccordionItemHeader({
  children,
  className,
}: PropsWithChildren<CustomClassNameProps>) {
  const { isActive, index } = useAccordionItem();
  const { onAccordionOpen } = useAccordion();
  return (
    <div
      className={clsx([
        'accordion__item-header',
        isActive && 'selected',
        className,
      ])}
      onClick={onAccordionOpen(index)}
      onKeyUp={onAccordionOpen(index)}
      role="tab"
      tabIndex={0}
    >
      {children}
    </div>
  );
}

export function AccordionItemBody({
  children,
  className,
}: PropsWithChildren<CustomClassNameProps>) {
  const { isActive } = useAccordionItem();
  return isActive ? (
    <div className={clsx(['accordion__item-body', className])} role="tabpanel">
      {children}
    </div>
  ) : null;
}

export function AccordionItemBodyRow({ children }: PropsWithChildren) {
  return <div className="accordion__item-body-row">{children}</div>;
}

interface AccordionVirtualItemProps {
  children: React.ReactNode;
  dataTestid?: string;
  height?: number;
}

export const AccordionItems = forwardRef<
  HTMLDivElement,
  AccordionVirtualItemProps
  // eslint-disable-next-line prefer-arrow-callback
>(function AccordionItems({ children, dataTestid, height = 0 }, ref) {
  return (
    <div
      className="accordion__items"
      data-testid={dataTestid}
      ref={ref}
      style={{ height }}
      role="tablist"
    >
      {children}
    </div>
  );
});
