import { SerializerError } from 'errors';
import { OpenTasksResponse } from 'types';

import { OpenTaskPatient } from '../open-tasks.model';

export class OpenTaskSerializer {
  deserialize(target: OpenTasksResponse): Array<OpenTaskPatient> {
    const { data } = target;

    const patients = data && data.length > 0 ? data : [];

    patients.forEach((patient) => {
      if (typeof patient.patient_name !== 'string') {
        throw new SerializerError(
          'OpenTaskSerializer',
          'patient name are expected to be string',
        );
      }

      if (typeof patient.plan_of_care_id !== 'string') {
        throw new SerializerError(
          'OpenTaskSerializer',
          'plan_of_care_id are expected to be string',
        );
      }

      if (typeof patient.plan_of_care !== 'string') {
        throw new SerializerError(
          'OpenTaskSerializer',
          'patient case are expected to be string',
        );
      }

      if (typeof patient.initial_visit !== 'string') {
        throw new SerializerError(
          'OpenTaskSerializer',
          'initial_visit are expected to be string',
        );
      }
    });

    return patients.map((patient) => new OpenTaskPatient(patient));
  }
}
