import './PDFViewer.css';
import { captureEvent } from '@sentry/browser';
import clsx from 'clsx';
import LogRocket from 'logrocket';
import React, { useEffect, useState } from 'react';

import { Typography } from 'components/Typography';

interface PDFViewerProps {
  src?: string;
  className?: string;
}

export function PDFViewer({ src, className }: PDFViewerProps) {
  const [hasLoaded, setHasLoaded] = useState(false);

  // this is used to keep track of the last value for hasLoaded so that we can
  // access it in the useEffect's cleanup function
  const onUnmount = React.useRef<VoidFunction>();
  onUnmount.current = () => {
    if (hasLoaded) {
      // track property so can it can be seen in logrocket
      LogRocket.track('PDFWasOpenedSuccessfully');
    } else {
      captureEvent({
        message: 'PDF could not be rendered',
        extra: {
          url: src,
        },
        fingerprint: ['PDFViewer', 'pdfError'],
        timestamp: Math.floor(Date.now() / 1000),
      });
    }
  };

  useEffect(
    () =>
      // check if the pdf loaded ONLY when the component unmounts
      () =>
        onUnmount.current?.(),
    [],
  );

  return (
    <object
      className={clsx('open-tasks__pdf-viewer', className)}
      data={src || undefined}
      type="application/pdf"
      onLoad={() => {
        // Only executes if the resource has loaded without issues
        setHasLoaded(true);
      }}
    >
      <Typography variant="parragraph" color="dark" component="p">
        Unable to display PDF file.&nbsp;
        <Typography
          variant="link"
          component="a"
          color="blue-link"
          rel="noopener noreferrer"
          target="_blank"
          href={src}
        >
          Download
        </Typography>
        &nbsp;instead.
      </Typography>
    </object>
  );
}
