import clsx from 'clsx';

import { ThumbsDownIcon, ThumbsUpIcon } from 'components/Icons';

interface ThumbsIconProps {
  liked: boolean;
}
export function ThumbsIcon({ liked }: ThumbsIconProps) {
  return (
    <span className={clsx(['thumbs-icon', liked && 'thumbs-up'])}>
      {liked ? (
        <ThumbsUpIcon className="thumbs-svg" />
      ) : (
        <ThumbsDownIcon className="thumbs-svg" />
      )}
    </span>
  );
}
