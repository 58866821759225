import './CaseDistribution.css';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Bar } from 'react-chartjs-2';

import { Button } from 'components/Button';
import { Card } from 'components/Card';
import { ChartDescription } from 'components/ChartDescrption';
// eslint-disable-next-line import/no-cycle
import { ErrorBoundary } from 'components/ErrorBoundary';
import { Progress } from 'components/Progress';
import { Typography } from 'components/Typography';
import { BarProps } from 'types';
import { formatNumber } from 'utils';

import { CaseDistribution } from '../case-distribution.model';
import { CaseDistributionAdapter } from '../store';

const Fetching: React.FC = function FetchingCaseDistribution() {
  return (
    <Card className="case-distribution">
      <section>
        <Typography
          variant="body"
          component="h3"
          color="darkness"
          className="case-distribution__title"
          fontWeight="normal"
        >
          Case Distribution
        </Typography>

        <Progress
          show
          className="case-distribution__placeholder"
          data-testid="progress"
        />
      </section>
    </Card>
  );
};

interface SuccessProps {
  caseDistribution: CaseDistribution;
}

const Success: React.FC<SuccessProps> = function SuccessCaseDistribution(
  props,
) {
  const { caseDistribution } = props;
  const barProps: BarProps = {
    data: {
      labels: caseDistribution.labels.map((t) =>
        t.toLowerCase() === 'other' ? 'Neuro/Geriatrics' : t,
      ),
      datasets: [
        {
          barThickness: 24,
          backgroundColor: '#2FACBF',
          hoverBackgroundColor: '#4EC2D3',
          data: caseDistribution.data,
        },
      ],
    },
    height: 256,
    options: {
      maintainAspectRatio: false,
      scales: {
        x: {
          grid: {
            display: false,
          },
          ticks: {
            color: '#3C4858',
          },
        },
        y: {
          grid: {
            drawBorder: false,
          },
          ticks: {
            callback(value) {
              return `${value}%`;
            },
            padding: 12,
            color: '#3C4858',
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          titleMarginBottom: 7,
          bodySpacing: 7,
          callbacks: {
            label(tooltipItem) {
              return `${tooltipItem.parsed.y}%`;
            },
            beforeBody(tooltipItems) {
              const index = tooltipItems[0].dataIndex;
              return `Care Plans: ${formatNumber(caseDistribution.visits[index])}`;
            },
          },
        },
      },
    },
  };

  return (
    <Card className="case-distribution">
      <section>
        <Typography
          variant="body"
          component="h3"
          color="darkness"
          className="case-distribution__title"
          fontWeight="normal"
        >
          Case Distribution
        </Typography>

        <div className="case-distribution__chart-container">
          <Bar {...barProps} data-testid="chart" />
        </div>

        <ChartDescription>
          &quot;Neuro/Geriatrics&quot; includes fall risk, gait impairements,
          post acute neurological conditions, vestibular conditions, etc.
        </ChartDescription>
      </section>
    </Card>
  );
};

interface FailedProps {
  onRetry: VoidFunction;
  error?: Error;
}

const Failed: React.FC<FailedProps> = function Failed(props) {
  const { onRetry, error } = props;

  return (
    <Card className="case-distribution">
      <section>
        <Typography
          variant="body"
          component="h3"
          color="darkness"
          className="case-distribution__title"
          fontWeight="normal"
        >
          Case Distribution
        </Typography>

        <div className="case-distribution__placeholder">
          <Typography
            variant="body"
            component="p"
            color="dark"
            className="case-distribution__title"
          >
            An error prevented us from loading this chart
          </Typography>

          <Button variant="primary" onClick={onRetry}>
            Try Again
          </Button>
        </div>
      </section>
    </Card>
  );
};

const CaseDistributionComponent: React.FC = observer(() => {
  const store = React.useContext(CaseDistributionAdapter);

  if (store.fetched) {
    return <Success caseDistribution={store.cache!} />;
  }
  if (store.failure) {
    return <Failed onRetry={() => store.refetch()} error={store.error} />;
  }
  return <Fetching />;
});

function FallbackUI() {
  return (
    <Card className="fallback-ui case-distribution">
      <Typography
        variant="body"
        component="h3"
        color="darkness"
        className="case-distribution__title"
        fontWeight="normal"
      >
        Something went wrong.
      </Typography>
    </Card>
  );
}

export function CaseDistributionView() {
  return (
    <ErrorBoundary place="Case Distribution" renderError={<FallbackUI />}>
      <CaseDistributionComponent />
    </ErrorBoundary>
  );
}
