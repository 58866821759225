/* eslint-disable @typescript-eslint/no-explicit-any */
// import { metricLogger } from 'bootstrap';
import './RecentsPatients.css';
import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, PropsWithChildren } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

// eslint-disable-next-line import/no-cycle
import { metricLogger } from 'app/routes/Dashboard';
import { Button } from 'components/Button';
import { Card } from 'components/Card';
import { ConditionalComponent } from 'components/ConditionalComponent';
import {
  Dropdown,
  MenuOptions,
  MultiDropdown,
  RadioOptions,
} from 'components/Dropdown';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { Progress } from 'components/Progress';
import { Typography } from 'components/Typography';
import { useAuth } from 'context/auth';
import { useToastContext } from 'context/toast-context';
import useMediaQuery from 'hooks/useMediaQuery';
import { ToastType } from 'types';

import { RecentPatientsStoreAdapter } from '../store';
import { ChartBox, PatientChartInfo } from '../types';

import { RecentPatientsTreatedMobileView } from './RecentPatientsTreatedMobile';
import { Success } from './RecentsPatientsTreated';
import { SharedCharts } from './SharedCharts';
import { isInvalidRom } from './utils';

interface FailedProps {
  onRetry: VoidFunction;
  error?: Error;
}

const computeTableText = (filter: string) => {
  if (filter === '90') {
    return 'Recently Seen Patients';
  }
  return 'All Seen Patients';
};

export const Fetching: React.FC = function Fetching() {
  const store = React.useContext(RecentPatientsStoreAdapter);
  return (
    <Card className="recent-patients">
      <section>
        <Typography
          variant="body"
          color="darkness"
          component="h3"
          fontWeight="normal"
          className="recent-patients__title"
          data-tooltip-content={store.time === '90' ? 'Last 90 days' : ''}
        >
          {computeTableText(store.time!)}
        </Typography>
        {store.time === '90' && (
          <ReactTooltip
            place="bottom"
            variant="dark"
            className="recent-patients__functional-scale--tooltip"
          />
        )}
        <Typography variant="caption" color="gray-chateau" component="p">
          Grayed rows are discharged patients.
          <br />
          Patient search results override Patient Status Filter.
        </Typography>

        <Progress show className="recent-patients__placeholder" />
      </section>
    </Card>
  );
};

export const Failed: React.FC<FailedProps> = observer((props) => {
  const { onRetry, error } = props;

  return (
    <Card className="recent-patients">
      <section className="recent-patients__error">
        <Typography
          variant="body"
          component="p"
          color="dark"
          className="recent-patients__error-message"
        >
          An error prevented us from loading this chart
        </Typography>

        <Button variant="primary" onClick={onRetry}>
          Try Again
        </Button>
      </section>
    </Card>
  );
});

function EmptyState({ time }: { time: string }) {
  return (
    <Card className="recent-patients">
      <section>
        <div className="recent-patients__header">
          <div className="recent-patients__header-info">
            <Typography
              variant="body"
              color="darkness"
              component="h3"
              fontWeight="normal"
              className="recent-patients__title"
              data-tooltip-content={time === '90' ? 'Last 90 days' : ''}
              data-tooltip-id="patient-table-title-tooltip"
            >
              {computeTableText(time)}
            </Typography>
            {time === '90' && (
              <ReactTooltip
                id="patient-table-title-tooltip"
                place="bottom"
                variant="dark"
                className="recent-patients__functional-scale--tooltip"
              />
            )}
            <Typography variant="caption" color="gray-chateau" component="p">
              Grayed rows are discharged patients.
              <br />
              Patient search results override Patient Status Filter.
            </Typography>
          </div>
        </div>
        <Typography
          variant="parragraph"
          color="abbey"
          className="recent-patients_empty-state-title"
        >
          No Patients to Report
        </Typography>
      </section>
    </Card>
  );
}
type ActionButtonProps = { onClick: () => void; disabled?: boolean };

function ActionButton({
  disabled,
  onClick,
  children,
}: PropsWithChildren<ActionButtonProps>) {
  return (
    <button
      type="button"
      onClick={onClick}
      className="charts-table-option-button"
      disabled={disabled}
    >
      <span className="charts-table-option-button__text">{children}</span>
    </button>
  );
}

const RecentPatientsTreatedComponent: React.FC = observer(() => {
  const store = React.useContext(RecentPatientsStoreAdapter);

  const { addToast } = useToastContext();

  const { user } = useAuth();

  const [modalCharts, setModalCharts] = React.useState(false);
  const [charts, setCharts] = React.useState<ChartBox[]>([]);
  const [modalTitle, setModalTitle] = React.useState('');

  const [patientProps, setPatientProps] = React.useState({
    patientName: '',
    dob: '',
    physicianName: '',
    physicianId: '',
    patientId: '',
  });

  function onSearchUpdated(e: ChangeEvent<HTMLInputElement>) {
    store.setFilteredPatients(e.target.value);
  }

  async function handleCharts(
    patientInfo: PatientChartInfo,
    completedOnly: boolean,
  ) {
    const {
      dob,
      partnerId,
      patientId,
      patientName,
      physicianId,
      physicianName,
    } = patientInfo;
    const computedPatientName = patientName
      .split(',')
      .reverse()
      .join(' ')
      .trim();
    setPatientProps({
      patientName: computedPatientName,
      dob,
      physicianId,
      physicianName,
      patientId,
    });
    const chartsResponse = await store.fetchSharedCharts(
      physicianId,
      patientId,
      partnerId,
    );

    metricLogger.sendMetrics({
      patient_name: computedPatientName,
      provider_name: physicianName,
      provider_id: physicianId,
      kind: completedOnly ? 'opened_charts_modal' : 'opened_sessions_modal',
      extras: {
        patient_id: patientId,
      },
    });

    let { data } = chartsResponse;

    /*
      XXX: The fetchSharedCharts endpoint is used to both fetch a list of _charts_ to display in the charts modal,
      and a list of completed/cancelled/pending _appointments_ in the sessions modal. Only completed appointments
      will have charts all of the time. The endpoint fakes charts with bogus ID's (negative integers) for most
      cancelled/pending appointments.

      In the sessions modal, we wish to display all of completed/cancelled/pending.

      In the charts modal, we only wish to display the completed ones. But since the endpoint returns all of
      completed/cancelled/pending, we need to filter out the charts for non-completed appointments.
    */
    if (completedOnly) {
      data = data
        .map((chart) => ({
          ...chart,
          charts: chart.charts.filter((c) => c.state === 'completed'),
        }))
        .filter((c) => c.charts.length > 0);
    }

    setModalTitle(completedOnly ? 'Charts' : 'Sessions');
    setCharts(data);
    setModalCharts(true);
  }
  const downloadTableData = () => {
    metricLogger.sendMetrics({
      patient_name: 'Does not apply',
      provider_id: patientProps.physicianId,
      provider_name: patientProps.physicianName,
      kind: 'requested_patient_data_download',
    });
    store
      .downloadTableData(user?.email || '')
      .then(() => addToast('Check email for download', ToastType.SUCCESS))
      .catch((e) => {
        addToast(
          'Oops! There is an error in the download attempt',
          ToastType.ERROR,
        );
        metricLogger.sendMetrics({
          kind: 'error',
          patient_name: 'Does not apply',
          extras: {
            text: e.message,
            context: 'download patient table data',
          },
        });
      });
  };

  const closeModal = () => {
    setModalCharts(false);
  };

  const matchesMobileQuery = useMediaQuery('(max-width: 768px)');

  if (store.fetched) {
    const shouldHideRomColumn = store.cache!.every((p) =>
      isInvalidRom(p.rom_extension, p.rom_flexion),
    );
    if (store.isEmpty) {
      return <EmptyState time={store.time!} />;
    }
    return (
      <>
        {modalCharts && (
          <SharedCharts
            patientProps={patientProps}
            charts={charts}
            modalStatus={modalCharts}
            closeModal={closeModal}
            title={modalTitle}
            canDownload={modalTitle === 'Charts'}
            isAdmin={!store.isTokenSet()}
          />
        )}
        <Card className="recent-patients">
          <section>
            <div className="recent-patients__header">
              <div className="recent-patients__header-info">
                <Typography
                  variant="body"
                  color="darkness"
                  component="h3"
                  fontWeight="normal"
                  className="recent-patients__title"
                  data-tooltip-content={
                    store.time === '90' ? 'Last 90 days' : ''
                  }
                  data-tooltip-id="patient-table-title-tooltip"
                >
                  {computeTableText(store.time!)}
                </Typography>
                {store.time === '90' && (
                  <ReactTooltip
                    id="patient-table-title-tooltip"
                    place="bottom"
                    variant="dark"
                    className="recent-patients__functional-scale--tooltip"
                  />
                )}
                <Typography
                  variant="caption"
                  color="gray-chateau"
                  component="p"
                >
                  Grayed rows are discharged patients.
                  <br />
                  Patient search results override Patient Status Filter.
                </Typography>
              </div>
              <div className="recent-patients__header-filters">
                {store.groupName && (
                  <MultiDropdown
                    MenuItem={RadioOptions}
                    placeholder="Select a Physician"
                    selectedOptions={[]}
                    options={store.therapistList.map((t, i) => ({
                      label: t.label, // label
                      value: t.value, // value
                      index: i,
                    }))}
                    onSelect={(ids: string[]) => {
                      store.setFilteredPhysicians(ids);
                    }}
                  />
                )}
                <Dropdown
                  MenuItem={MenuOptions}
                  placeholder="Patient Status"
                  selectedOption={store.patientStatus}
                  options={[
                    { label: 'All', index: 0, value: 'all' },
                    { label: 'Active', index: 1, value: false },
                    { label: 'Upcoming', index: 2, value: 'upcoming' },
                    { label: 'Discharged', index: 3, value: true },
                  ]}
                  onChange={(val: any) => {
                    store.setFilteredStatus(val);
                  }}
                />
                <ConditionalComponent validate={matchesMobileQuery}>
                  <input
                    className="user-input"
                    placeholder="Search for Patient"
                    value={store.searchTerm}
                    onChange={onSearchUpdated}
                  />
                </ConditionalComponent>
              </div>
            </div>
            <ConditionalComponent validate={!matchesMobileQuery}>
              <Success
                model={store.cache!}
                shouldHideRom={shouldHideRomColumn}
                // eslint-disable-next-line react/jsx-no-bind
                handleCharts={handleCharts}
              />
            </ConditionalComponent>
            <ConditionalComponent validate={matchesMobileQuery}>
              <RecentPatientsTreatedMobileView
                model={store.cache!}
                shouldHideRom={shouldHideRomColumn}
                // eslint-disable-next-line react/jsx-no-bind
                handleCharts={handleCharts}
              />
            </ConditionalComponent>
            <div className="recent-patients__footer">
              <ActionButton
                onClick={downloadTableData}
                disabled={
                  store.isDownloadingPatientTable || store.data!.length === 0
                }
              >
                Download All
              </ActionButton>
            </div>
          </section>
        </Card>
      </>
    );
  }
  if (store.failure) {
    return <Failed onRetry={() => store.refetch()} error={store.error} />;
  }
  return <Fetching />;
});

function FallbackUI() {
  return (
    <Card className="fallback-ui recent-patients">
      <Typography
        variant="body"
        component="p"
        color="dark"
        className="recent-patients__error-message"
      >
        Something went wrong.
      </Typography>
    </Card>
  );
}

export function RecentPatientsTreatedView() {
  return (
    <ErrorBoundary place="Recent Patients" renderError={<FallbackUI />}>
      <RecentPatientsTreatedComponent />
    </ErrorBoundary>
  );
}
