import { ChartProps } from 'react-chartjs-2';

/* eslint-disable @typescript-eslint/no-explicit-any */
export type SortOrder = 'original' | 'ordered' | 'decrescent' | 'increscent';
export enum ToastType {
  SUCCESS,
  ERROR,
}

export interface IToast {
  index: number;
  msg: string;
  type: ToastType;
  key: string;
}

export interface DropdownOption {
  value: any;
  index: number;
  label: string;
  tooltip?: string;
}
export interface DropdownItemProps<T = string> {
  value: T;
  label: string;
  isSelected: boolean;
  onClick(): void;
  tooltip?: string;
}

export interface BaseDropdownProps {
  placeholder: string;
  options: Array<DropdownOption>;
  className?: string;
  MenuItem: React.FC<DropdownItemProps>;
}

export interface OnChangeType {
  (e: any): void;
}

export type SingleOptionDropdown = {
  onChange: OnChangeType;
  selectedOption: any;
};

export type MultiOptionDropdown = {
  selectedOptions: Array<DropdownOption>;
  onSelect: OnChangeType;
};

export type SingleDropdownProps = BaseDropdownProps & SingleOptionDropdown;
export type MultiDropdownProps = BaseDropdownProps & MultiOptionDropdown;

export interface SelectOption<T = number> {
  value: T;
  label: string;
  prefix?: string;
  selected?: boolean;
  hubspotId?: string;
  code?: string;
}

/** ChartJS */
export type BarProps<TData = number[], TLabel = unknown> = Omit<
  ChartProps<'bar', TData, TLabel>,
  'type'
>;
export type DoughnutProps<TData = number[], TLabel = unknown> = Omit<
  ChartProps<'doughnut', TData, TLabel>,
  'type'
>;
export type PieProps<TData = number[], TLabel = unknown> = Omit<
  ChartProps<'pie', TData, TLabel>,
  'type'
>;

export interface ChartsFetchParams {
  therapists: Array<SelectOption<string>>;
  partnerId?: string;
  time: string;
  permission?: boolean;
}

export interface ChartLoadParams {
  therapists: Array<SelectOption<string>>;
  partnerId: string | undefined;
  partners: Array<SelectOption<string>>;
  clinics: Array<SelectOption<string>>;
  time: string;
}
export type ValueOf<T> = T[keyof T];

export type ChartLabels = Array<string>;
export type ChartData = Array<number>;
export type Dataset = Array<number>;
export type Datasets = Array<Dataset>;
export type InjuryTypeDataSet = {
  [injury: string]: {
    between_five_and_ten: {
      care_plan_count: number;
      percentage: number;
    };
    greater_than_ten: {
      care_plan_count: number;
      percentage: number;
    };
    less_than_five: {
      care_plan_count: number;
      percentage: number;
    };
    total: {
      average: number;
      total_care_plans: number;
      total_visits_accumulated: number;
    };
  };
};

export interface GlobalDataResponse {
  therapists: TherapistsResponse;
  partners: PartnersResponse;
  clinics: ClinicResponse;
}

type TwoChoice<A, B> = A | B;
type TrueFalseString = TwoChoice<'true', 'false'>;

type TherapistRaw = {
  id: string;
  name: string;
  prefix: string;
  hubspot_id: string;
};
export interface TherapistRawResponse {
  info: {
    data: Array<
      TherapistRaw & {
        can_sign_patient_pocs: TrueFalseString;
        can_view_assigned_physician: TrueFalseString;
        can_view_patient_charts: TrueFalseString;
      }
    >;
    group?: {
      id: string;
      name: string;
      code: string;
    };
    partner?: {
      id: string;
      name: string;
      code: string;
    };
    clinic?: {
      id: string;
      name: string;
      code: string;
    };
  };
}

type ChangeFields<T, R> = Omit<T, keyof R> & R;

type FinalTherapistResponse = ChangeFields<
  TherapistRawResponse,
  { info: Omit<TherapistRawResponse['info'], 'data'> }
>;
export interface PermissionsResponse {
  data: {
    can_sign_poc: boolean;
    can_view_charts: boolean;
    can_view_physicians: boolean;
    name: string;
  };
}

export type TherapistsResponse = FinalTherapistResponse & {
  info: {
    data: TherapistRaw[];
    permissions: PermissionsResponse;
  };
};

export interface PartnersResponse {
  info: {
    data: Array<Partner>;
  };
}

export interface ClinicResponse {
  info: {
    data: Array<Clinic>;
  };
}

export interface Clinic {
  id: string;
  key: string;
  name: string;
  code: string;
}

export interface Partner {
  name: string;
  id: string;
  code: string;
}

/* API RESPONSES */
export interface AgeDistributionResponse {
  info: {
    data: {
      female: {
        age_ranges: Array<string>;
        data: Array<number>;
      };
      male: {
        age_ranges: Array<string>;
        data: Array<number>;
      };
      total_patients: number;
    };
  };
}
export interface BodyResponse {
  info: {
    data: {
      knee: {
        pain: Array<number>;
        psfs: Array<number>;
        kos: Array<number>;
      };
      hip: {
        pain: Array<number>;
        psfs: Array<number>;
        lefs: Array<number>;
      };
      lower_back: {
        pain: Array<number>;
        psfs: Array<number>;
        mdq: Array<number>;
      };
      shoulder: {
        pain: Array<number>;
        psfs: Array<number>;
        quick_dash: Array<number>;
      };
      neck: {
        pain: Array<number>;
        psfs: Array<number>;
        ndi: Array<number>;
      };
      hoos: {
        pain: Array<number>;
        psfs: Array<number>;
        lefs: Array<number>;
      };
      koos: {
        pain: Array<number>;
        psfs: Array<number>;
        kos: Array<number>;
      };
    };
  };
}
export interface CaseDistributionResponse {
  info: {
    labels: ChartLabels;
    data: Datasets;
  };
}

export interface ChangeInPainLevelResponse {
  info: {
    data: ChartData;
    decrease_percentage: number;
  };
}

export interface ChangeInPsfsScaleResponse {
  info: {
    data: ChartData;
    decrease_percentage: number;
  };
}

export interface NPSResponse {
  info: {
    data: {
      promoters: {
        total: number;
        percentage: number;
      };
      passives: {
        total: number;
        percentage: number;
      };
      detractors: {
        total: number;
        percentage: number;
      };
      nps: number;
    };
  };
}

export interface PatientSatisfactionResponse {
  info: {
    data: {
      thumbs_up: {
        total: number;
        percentage: number;
      };
      thumbs_down: {
        total: number;
        percentage: number;
      };
      total_rates: number;
    };
  };
}

export interface QualityOfLifeResponse {
  info: {
    labels: ChartLabels;
    data: Dataset;
  };
}
export interface VisitsByInjuryTypeResponse {
  info: {
    labels: ChartLabels;
    data: InjuryTypeDataSet;
  };
}
export interface OpenTasksResponse {
  data: Array<{
    key?: string;

    patient_name: string;
    plan_of_care: string;
    initial_visit: string;
    plan_of_care_url: string;
    plan_of_care_id: string | null;
    visit_type: string | null;
    healthy_url: boolean;
  }>;
}
export interface RecentPatientFuncionalScale {
  color: 'dark-green' | 'light-green' | 'yellow';
  message: string;
}

export interface RecentPatientInfo {
  key?: string;
  date_of_birth: string;
  date_of_surgery: string;
  name: string;
  pain_scale: string;
  psfs_scale: string;
  functional_scale: RecentPatientFuncionalScale;
  sessions: string;
  discharged: string;
  patient_id: string;
  physician_id: string;
  partner_id: string;
  injury_name: string;
  rom_extension: string;
  rom_flexion: string;
  physician_name: string;
  escalated_full_message: string;
}

export interface DowngradedPatientInfo {
  key?: string;
  patient: string;
  date_of_referral: string;
  date_of_birth: string;
  reason_not_booked: string;
  hubspot_id: string;
}

export interface RecentPatientsResponse {
  info: {
    data: Array<RecentPatientInfo>;
  };
}
export interface DowngradedPatientDataset {
  percentage: number;
  count: number;
}
export interface DowngradedPatientDropdownOption {
  value: string;
  label: string;
}
export interface DowngradedPatientsResponse {
  data: {
    table: Array<DowngradedPatientInfo>;
    charts: {
      data: Record<string, { amount: number; percentage: number }>;
      total_patients: number;
    };
  };
}
