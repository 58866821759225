import { SerializerError } from 'errors';
import { ClinicResponse } from 'types';

import { Clinic } from '../../models';

interface ClinicSerializerType {
  clinics: Array<Clinic>;
}

export class ClinicsSerializer {
  deserialize(target: ClinicResponse): ClinicSerializerType {
    const {
      info: { data },
    } = target;

    if (data === undefined) {
      return { clinics: [] };
    }

    data.forEach((clinic) => {
      if (typeof clinic.name !== 'string') {
        throw new SerializerError(
          'ClinicSerializer',
          `clinic names are expected to be string`,
        );
      }
    });

    const clinics = data.map((clinic) => new Clinic(clinic));
    return { clinics };
  }
}
