/* eslint-disable class-methods-use-this */
import { computed, observable } from 'mobx';
import {
  ActorRefFrom,
  DoneActorEvent,
  ErrorActorEvent,
  SnapshotFrom,
  assign,
  fromPromise,
  setup,
} from 'xstate';

import api from 'lib/api';
import { ChartLoadParams, ChartsFetchParams, SelectOption } from 'types';

// T corresponds to the type of data fecthed. e.g AgeDistribution
// R corresponds to the response type
export abstract class BaseChartStore<T, R> {
  @observable
  protected current!: SnapshotFrom<typeof this.machine>;

  protected service!: ActorRefFrom<typeof this.machine>;

  protected machine = setup({
    types: {
      context: {} as BaseMachineContext<T>,
      events: {} as MachineEvent,
    },
    actions: {
      saveData: assign({
        data: ({ event }) => {
          const { output } = event as DoneActorEvent<T>;
          return output;
        },
      }),
      saveError: assign({
        error: ({ event }) => {
          const { error } = event as ErrorActorEvent<Error>;

          return error;
        },
      }),
      deleteError: assign({
        error: undefined,
      }),
      setTherapists: assign({
        selectedTherapists: ({ event }) =>
          (event as SelectedTherapistsEvent).ids,
        partnerId: ({ event }) => (event as SelectedTherapistsEvent).partnerId,
        time: ({ event }) => (event as SelectedTherapistsEvent).time,
      }),
      setPartners: assign({
        selectedPartners: ({ event }) =>
          (event as SelectedPartnersEvent).selected,
      }),
      setClinics: assign({
        selectedClinics: ({ event }) =>
          (event as SelectedClinicsEvent).selected,
      }),
    },
    actors: {
      fetchDataModel: fromPromise<T, ChartLoadParams>(({ input, signal }) =>
        this.doFetch(
          input.therapists,
          input.partnerId,
          input.partners,
          input.clinics,
          input.time,
          signal,
        ),
      ),
    },
  }).createMachine({
    id: this.id,
    initial: 'initial',

    context: {
      data: undefined,
      error: undefined,
      selectedTherapists: [],
      selectedPartners: [],
      selectedClinics: [],
      partnerId: undefined,
      time: '90',
    },
    states: {
      initial: {
        on: {
          THERAPISTS_SELECTED: {
            target: 'fetching',
            actions: ['setTherapists'],
          },
          PARTNERS_SELECTED: { actions: ['setPartners'] },
          CLINICS_SELECTED: { actions: ['setClinics'] },
        },
      },
      fetching: {
        on: {
          THERAPISTS_SELECTED: {
            reenter: true, // Stopped any invoked actors and start new ones (fetch)
            target: 'fetching',
            actions: ['setTherapists'],
          },
          PARTNERS_SELECTED: { actions: ['setPartners'] },
          CLINICS_SELECTED: { actions: ['setClinics'] },
        },
        invoke: {
          id: 'fetchData',
          src: 'fetchDataModel',
          input: ({ context, event }) => ({
            therapists:
              (event as SelectedTherapistsEvent).ids ||
              context.selectedTherapists,
            partnerId:
              (event as SelectedTherapistsEvent).partnerId || context.partnerId,
            partners: context.selectedPartners,
            clinics: context.selectedClinics,
            time: (event as SelectedTherapistsEvent).time || context.time,
          }),
          onDone: {
            target: 'success',
            actions: ['saveData', 'deleteError'],
          },
          onError: {
            target: 'failure',
            actions: ['saveError'],
          },
        },
      },
      success: {
        on: {
          THERAPISTS_SELECTED: {
            target: 'fetching',
            actions: ['setTherapists'],
          },
          PARTNERS_SELECTED: { actions: ['setPartners'] },
          CLINICS_SELECTED: { actions: ['setClinics'] },
        },
      },
      failure: {
        on: {
          RETRY_EVENT: 'fetching',
          THERAPISTS_SELECTED: {
            target: 'fetching',
            actions: ['setTherapists'],
          },
          PARTNERS_SELECTED: { actions: ['setPartners'] },
          CLINICS_SELECTED: { actions: ['setClinics'] },
        },
      },
    },
  });

  constructor(private id: string) {}

  protected abstract doFetch(
    therapists: Array<SelectOption<string>>,
    partnerId: string | undefined,
    partners: Array<SelectOption<string>>,
    clinics: Array<SelectOption<string>>,
    time: string,
    signal?: AbortSignal,
  ): Promise<T>;

  protected async load(
    path: string,
    payload: ChartLoadParams,
    signal?: AbortSignal,
  ): Promise<R> {
    const { therapists, time, partnerId, partners, clinics } = payload;
    // set path and base of the url
    const params = new URLSearchParams();
    params.set('from', time);
    let doctors: string[] = [];
    if (therapists.length === 0) {
      if (partnerId) {
        params.set('partner', partnerId);
      }
    } else {
      doctors = therapists.map(({ value }) => value);
    }

    const response = await api.post<R>(
      `/${path}?${params.toString()}`,
      {
        data: {
          doctors,
          partners: partners.map((p) => p.value),
          clinics: clinics.map((c) => c.value),
        },
      },
      { signal },
    );
    return response.data;
  }

  public setPartners(selectedPartners: Array<SelectOption<string>>) {
    this.service.send({
      type: 'PARTNERS_SELECTED',
      selected: selectedPartners,
    });
  }

  public setClinics(selectedClinics: Array<SelectOption<string>>) {
    this.service.send({ type: 'CLINICS_SELECTED', selected: selectedClinics });
  }

  public fetch(params: ChartsFetchParams): void {
    const { therapists, partnerId, time } = params;
    this.service.send({
      type: 'THERAPISTS_SELECTED',
      ids: therapists,
      partnerId,
      time,
    });
  }

  public refetch(): void {
    this.service.send({ type: 'RETRY_EVENT' });
  }

  @computed
  public get cache(): T | undefined {
    if (typeof this.current === 'undefined') {
      return undefined;
    }
    return this.current.context.data as T;
  }

  @computed
  public get fetching(): boolean {
    if (typeof this.current === 'undefined') {
      return false;
    }
    return this.current.matches('fetching');
  }

  @computed
  public get fetched(): boolean {
    if (typeof this.current === 'undefined') {
      return false;
    }
    return this.current.matches('success');
  }

  @computed
  public get failure(): boolean {
    if (typeof this.current === 'undefined') {
      return false;
    }

    return this.current.matches('failure');
  }

  @computed
  public get error(): Error | undefined {
    if (typeof this.current === 'undefined') {
      return undefined;
    }

    return this.current.context.error;
  }
}

export interface BaseMachineContext<T> {
  data?: T;
  error?: Error;
  selectedTherapists: Array<SelectOption<string>>;
  selectedPartners: Array<SelectOption<string>>;
  selectedClinics: Array<SelectOption<string>>;
  partnerId: string | undefined;
  time: string;
}

export interface SelectedTherapistsEvent {
  type: 'THERAPISTS_SELECTED';
  ids: Array<SelectOption<string>>;
  partnerId: string | undefined;
  time: string;
}

export interface SelectedPartnersEvent {
  type: 'PARTNERS_SELECTED';
  selected: Array<SelectOption<string>>;
}
export interface SelectedClinicsEvent {
  type: 'CLINICS_SELECTED';
  selected: Array<SelectOption<string>>;
}

export type MachineEvent =
  | {
      type: 'RETRY_EVENT';
    }
  | SelectedTherapistsEvent
  | SelectedClinicsEvent
  | SelectedPartnersEvent
  | DoneActorEvent
  | ErrorActorEvent<Error>;
