import { captureEvent, captureException } from '@sentry/browser';
// import { metricLogger } from 'bootstrap';
import { observable, computed } from 'mobx';
import {
  ActorRefFrom,
  DoneActorEvent,
  ErrorActorEvent,
  SnapshotFrom,
  assign,
  createActor,
  fromPromise,
  setup,
} from 'xstate';

import api from 'lib/api';
import {
  SelectOption,
  ChartsFetchParams,
  RecentPatientInfo,
  RecentPatientsResponse,
  ChartLoadParams,
} from 'types';

import { RecentPatient } from '../recent-patient.model';
import { RecentPatientsSerializer } from '../serializer';
import { ChartAttributes, ChartBoxResponse } from '../types';

type PatientStatuses = 'all' | 'upcoming' | boolean | string;
interface MachineContext {
  data?: Array<RecentPatient>;
  error?: Error;
  filteredPatients: Array<RecentPatient>;
  filteredPhysicians: Array<string>;
  filteredStatus: PatientStatuses;
  filteredPatient: string;
  selectedTherapists: Array<SelectOption<string>>;
  selectedPartners: Array<SelectOption<string>>;
  selectedClinics: Array<SelectOption<string>>;
  partnerId: string | undefined;
  permission: boolean;
  groupName: string | undefined;
  time: string;
}

interface SelectedTherapistsEvent {
  type: 'THERAPISTS_SELECTED';
  ids: Array<SelectOption<string>>;
  partnerId: string | undefined;
  permission: boolean;
  time: string;
}

interface SelectedPartnersEvent {
  type: 'PARTNERS_SELECTED';
  selected: Array<SelectOption<string>>;
}

interface SelectedClinicsEvent {
  type: 'CLINICS_SELECTED';
  selected: Array<SelectOption<string>>;
}
interface SetFilteredPatients {
  type: 'SET_PATIENT_FILTER';
  name: string;
}

interface ResetFilteredPatients {
  type: 'RESET_PATIENT_FILTER';
}

interface SetGroupNameEvent {
  type: 'SET_GROUP_NAME';
  name: string;
}

interface SetFilteredPhysicians {
  type: 'SET_FILTERED_PHYSICIANS';
  data: Array<string>;
}
interface SetFilteredStatus {
  type: 'SET_FILTERED_STATUS';
  data: PatientStatuses;
}

type MachineEvent =
  | {
      type: 'RETRY_EVENT';
    }
  | SelectedTherapistsEvent
  | SelectedPartnersEvent
  | SelectedClinicsEvent
  | SetGroupNameEvent
  | SetFilteredPhysicians
  | SetFilteredStatus
  | SetFilteredPatients
  | ResetFilteredPatients
  | DoneActorEvent<Array<RecentPatient>>
  | ErrorActorEvent<Error>;

export class RecentPatientsStore {
  @observable
  protected current!: SnapshotFrom<typeof this.machine>;

  protected service!: ActorRefFrom<typeof this.machine>;

  @observable public isDownloadingPatientTable = false;

  constructor(private serializer: RecentPatientsSerializer) {
    this.service = createActor(this.machine);
    this.service.subscribe((state) => {
      this.current = state;
    });
    this.service.start();
  }

  private token = '';

  private machine = setup({
    types: { context: {} as MachineContext, events: {} as MachineEvent },
    actions: {
      saveData: assign({
        data: ({ event }) => {
          const { output } = event as DoneActorEvent<Array<RecentPatient>>;
          return output;
        },
        filteredPatients: ({ event }) => {
          const { output } = event as DoneActorEvent<Array<RecentPatient>>;
          return output;
        },
      }),
      saveError: assign({
        error: ({ event }) => {
          const { error } = event as ErrorActorEvent<Error>;

          return error;
        },
      }),
      deleteError: assign({
        error: undefined,
      }),
      setTherapists: assign({
        selectedTherapists: ({ event }) =>
          (event as SelectedTherapistsEvent).ids,
        partnerId: ({ event }) => (event as SelectedTherapistsEvent).partnerId,
        permission: ({ event }) =>
          (event as SelectedTherapistsEvent).permission,
        time: ({ event }) => (event as SelectedTherapistsEvent).time,
      }),
      setPartners: assign({
        selectedPartners: ({ event }) =>
          (event as SelectedPartnersEvent).selected,
      }),
      setClinics: assign({
        selectedClinics: ({ event }) =>
          (event as SelectedClinicsEvent).selected,
      }),
      setGroupName: assign({
        groupName: ({ event }) => (event as SetGroupNameEvent).name,
      }),
      setFilteredPhysicians: assign({
        filteredPhysicians: ({ event }) =>
          (event as SetFilteredPhysicians).data,
      }),
      setFilteredStatus: assign({
        filteredStatus: ({ event }) => (event as SetFilteredStatus).data,
      }),
      setFilteredPatient: assign({
        filteredPatient: ({ event }) => {
          const { name } = event as SetFilteredPatients;
          return name;
        },
      }),
      filterPatients: assign({
        filteredPatients: ({ context }) => {
          const applyFiter = this.getFilterFunction(context.filteredStatus);
          const preResults = context
            .data!.filter(
              (p) =>
                context.filteredPhysicians.includes(p.physicianId) ||
                context.filteredPhysicians.length === 0,
            ) // apply filter to selected physicians in dropdown
            .filter((p) => applyFiter(p));
          // Override patient status filter
          const searchTerm = context.filteredPatient;
          if (searchTerm) {
            return context.data!.filter((p) =>
              p.name.toLowerCase().includes(searchTerm.toLowerCase()),
            );
          }
          return preResults;
        },
      }),
      resetPatientFilter: assign({
        filteredStatus: () => '',
        filteredPatient: () => '',
        filteredPatients: () => [],
      }),
      resetStatusFilter: assign({
        filteredStatus: () => 'all',
        filteredPatients: () => [],
      }),
    },
    actors: {
      fetchRecentPatientModel: fromPromise<
        Array<RecentPatient>,
        ChartLoadParams
      >(({ input, signal }) =>
        this.doFetch(
          input.therapists,
          input.partnerId,
          input.partners,
          input.clinics,
          input.time,
          signal,
        ),
      ),
    },
  }).createMachine({
    id: 'Recent Patients Machine',
    initial: 'initial',
    context: {
      data: undefined,
      error: undefined,
      filteredPatients: [],
      filteredPhysicians: [],
      filteredStatus: '',
      filteredPatient: '',
      selectedTherapists: [],
      selectedPartners: [],
      selectedClinics: [],
      partnerId: undefined,
      permission: false,
      groupName: undefined,
      time: '90',
    },
    states: {
      initial: {
        on: {
          THERAPISTS_SELECTED: {
            target: 'fetching',
            actions: ['setTherapists'],
          },
          PARTNERS_SELECTED: { actions: ['setPartners'] },
          CLINICS_SELECTED: { actions: ['setClinics'] },
          SET_GROUP_NAME: { actions: ['setGroupName'] },
          RESET_PATIENT_FILTER: { actions: ['resetPatientFilter'] },
        },
      },
      fetching: {
        on: {
          THERAPISTS_SELECTED: {
            reenter: true,
            target: 'fetching',
            actions: ['setTherapists'],
          },
          PARTNERS_SELECTED: { actions: ['setPartners'] },
          CLINICS_SELECTED: { actions: ['setClinics'] },
          SET_GROUP_NAME: { actions: ['setGroupName'] },
          RESET_PATIENT_FILTER: { actions: ['resetPatientFilter'] },
        },
        invoke: {
          id: 'fetchRecentPatientModel',
          src: 'fetchRecentPatientModel',
          input: ({ context, event }) => ({
            therapists:
              (event as SelectedTherapistsEvent).ids ||
              context.selectedTherapists,
            partnerId:
              (event as SelectedTherapistsEvent).partnerId || context.partnerId,
            partners: context.selectedPartners,
            clinics: context.selectedClinics,
            time: (event as SelectedTherapistsEvent).time || context.time,
          }),
          onDone: {
            target: 'success',
            actions: ['saveData', 'deleteError'],
          },
          onError: {
            target: 'failure',
            actions: ['saveError'],
          },
        },
      },
      success: {
        on: {
          THERAPISTS_SELECTED: {
            target: 'fetching',
            actions: ['setTherapists'],
          },
          PARTNERS_SELECTED: { actions: ['setPartners'] },
          CLINICS_SELECTED: { actions: ['setClinics'] },
          SET_GROUP_NAME: { actions: ['setGroupName'] },
          SET_FILTERED_PHYSICIANS: {
            actions: ['setFilteredPhysicians', 'filterPatients'],
          },
          SET_FILTERED_STATUS: {
            actions: ['setFilteredStatus', 'filterPatients'],
          },
          SET_PATIENT_FILTER: {
            actions: [
              'resetStatusFilter',
              'setFilteredPatient',
              'filterPatients',
            ],
          },
          RESET_PATIENT_FILTER: { actions: ['resetPatientFilter'] },
        },
      },
      failure: {
        on: {
          RETRY_EVENT: 'fetching',
          THERAPISTS_SELECTED: {
            target: 'fetching',
            actions: ['setTherapists'],
          },
          PARTNERS_SELECTED: { actions: ['setPartners'] },
          CLINICS_SELECTED: { actions: ['setClinics'] },
          SET_GROUP_NAME: { actions: ['setGroupName'] },
          RESET_PATIENT_FILTER: { actions: ['resetPatientFilter'] },
        },
      },
    },
  });

  private async load(
    therapists: Array<SelectOption<string>>,
    partnerId: string | undefined,
    partners: Array<SelectOption<string>>,
    clinics: Array<SelectOption<string>>,
    time: string,
    signal?: AbortSignal,
  ): Promise<RecentPatientsResponse> {
    const params = new URLSearchParams();
    params.set('from', time);

    let doctors: string[] = [];

    if (therapists.length === 0) {
      if (partnerId) {
        params.set('partner', partnerId);
      }
      if (!partnerId && partners.length === 0 && clinics.length === 0) {
        return Promise.resolve({
          info: {
            data: [],
          },
        });
      }
    } else {
      doctors = therapists.map(({ value }) => value);
    }
    const request = await api.post<RecentPatientsResponse>(
      `/patients_treated?${params.toString()}`,
      {
        data: {
          doctors,
          partners: partners.map((p) => p.value),
          clinics: clinics.map((p) => p.value),
        },
      },
      { signal },
    );
    return request.data;
  }

  public setPartners(selectedPartners: Array<SelectOption<string>>) {
    this.service.send({
      type: 'PARTNERS_SELECTED',
      selected: selectedPartners,
    });
  }

  public setClinics(selectedClinics: Array<SelectOption<string>>) {
    this.service.send({ type: 'CLINICS_SELECTED', selected: selectedClinics });
  }

  public setGroupName(groupName: string) {
    this.service.send({ type: 'SET_GROUP_NAME', name: groupName });
  }

  public fetch(params: ChartsFetchParams): void {
    const { therapists, partnerId, permission, time } = params;
    this.service.send({
      type: 'THERAPISTS_SELECTED',
      ids: therapists,
      partnerId,
      permission: !!permission,
      time,
    });
  }

  public setFilteredPhysicians(ids: string[]) {
    this.service.send({ type: 'SET_FILTERED_PHYSICIANS', data: ids });
  }

  public setFilteredStatus(status: PatientStatuses) {
    this.service.send({ type: 'RESET_PATIENT_FILTER' });
    this.service.send({ type: 'SET_FILTERED_STATUS', data: status });
  }

  public setFilteredPatients(name: string) {
    this.service.send({ type: 'SET_PATIENT_FILTER', name });
  }

  public resetFilteredPatients() {
    this.service.send({ type: 'RESET_PATIENT_FILTER' });
  }

  @computed get patientStatus() {
    return this.current.context.filteredStatus;
  }

  @computed get searchTerm() {
    return this.current.context.filteredPatient;
  }

  private async doFetch(
    therapists: Array<SelectOption<string>>,
    partnerId: string | undefined,
    partners: Array<SelectOption<string>>,
    clinics: Array<SelectOption<string>>,
    time: string,
    signal?: AbortSignal,
  ) {
    const jsonResponse = await this.load(
      therapists,
      partnerId,
      partners,
      clinics,
      time,
      signal,
    );

    // Check for null/"null" in any physician name
    const onePhysicianIsNull = jsonResponse.info.data.some(
      (p: RecentPatientInfo) =>
        !p.physician_name || p.physician_name === 'null',
    );
    const onePhysicianIdIsNull = jsonResponse.info.data.some(
      (p: RecentPatientInfo) => !p.physician_id || p.physician_id === 'null',
    );
    if (onePhysicianIsNull) {
      // https://develop.sentry.dev/sdk/event-payloads/types/
      captureEvent({
        message: "Found null Physician's name",
        extra: {
          therapists: therapists.map((t) => t.value), // ids of the therapists
          partnerId,
          partners,
          clinics,
          time,
        },
        fingerprint: ['POST', '/patients_treated'],
        timestamp: Math.floor(Date.now() / 1000),
      });
    }

    if (onePhysicianIdIsNull) {
      // https://develop.sentry.dev/sdk/event-payloads/types/
      captureEvent({
        message: "Found null Physician's Id",
        extra: {
          therapists: therapists.map((t) => t.value), // ids of the therapists
          partnerId,
          partners,
          clinics,
          time,
        },
        fingerprint: ['POST', '/patients_treated'],
        timestamp: Math.floor(Date.now() / 1000),
      });
    }

    return this.serializer.deserialize(jsonResponse);
  }

  // generate filter functions based on type of filter
  private getFilterFunction(filter: PatientStatuses) {
    if (filter === 'all' || filter === '') {
      return (_: RecentPatient) => true;
    }
    if (filter === 'upcoming') {
      return (patient: RecentPatient) => patient.sessions === '0/1';
    }
    return (patient: RecentPatient) => patient.discharged === filter;
  }

  @computed
  public get data(): Array<RecentPatient> | undefined {
    if (typeof this.current === 'undefined') {
      return undefined;
    }

    return this.current.context.data as Array<RecentPatient>;
  }

  @computed
  public get cache(): Array<RecentPatient> | undefined {
    if (typeof this.current === 'undefined') {
      return undefined;
    }

    return this.current.context.filteredPatients;
  }

  @computed get isEmpty() {
    if (typeof this.current === 'undefined') {
      return false;
    }
    return this.current.context.data!.length === 0;
  }

  @computed
  public get fetching(): boolean {
    if (typeof this.current === 'undefined') {
      return false;
    }

    return this.current.matches('fetching');
  }

  @computed
  public get fetched(): boolean {
    if (typeof this.current === 'undefined') {
      return false;
    }

    return this.current.matches('success');
  }

  @computed
  public get failure(): boolean {
    if (typeof this.current === 'undefined') {
      return false;
    }

    return this.current.matches('failure');
  }

  public refetch(): void {
    this.service.send({ type: 'RETRY_EVENT' });
  }

  public async fetchSharedCharts(
    physicianId: string,
    patientId: string,
    partnerId: string,
  ): Promise<ChartBoxResponse> {
    const chartPermission = this.current.context.permission;
    const params = new URLSearchParams();
    params.append('physician_id', physicianId);
    params.append('patient_id', patientId);
    params.append('can_view_charts', chartPermission.toString());
    params.append('partner_id', partnerId);
    // const queryParams = `physician_id=${physicianId}&patient_id=${patientId}&can_view_charts=${chartPermission}&partner_id=${partnerId}`;
    const request = await api.get<ChartBoxResponse>(
      `/shared_charts?${params.toString()}`,
    );
    return request.data;
  }

  public async downloadAllCharts(
    charts_attributes: Array<ChartAttributes>,
    patientName: string,
  ) {
    if (!this.isTokenSet()) {
      return Promise.reject(new Error('No token provided'));
    }
    const formattedName = patientName
      .replace(/\s\s+/g, ' ')
      .trim()
      .replace(',', '')
      .replace(' ', '_')
      .toLowerCase(); // replace all whitespaces with just one
    return api.post(
      '/shared_charts/download',
      { data: { patient_name: formattedName, charts_attributes } },
      {
        headers: {
          Authorization: `Token ${this.token}`,
        },
      },
    );
  }

  private get getEmailFromDom() {
    const emailElement = document.querySelector('#admin_email');
    return emailElement?.textContent?.trim() || '';
  }

  public get isAdminDashboard() {
    return typeof this.token === 'undefined' || this.token.length <= 0;
  }

  public async downloadTableData(email: string) {
    if (this.isDownloadingPatientTable) return;
    this.isDownloadingPatientTable = true;
    const params = new URLSearchParams();
    const baseBody = {
      doctors: this.current.context.selectedTherapists.map((o) => o.value),
      partners: this.current.context.selectedPartners,
    };
    // if its partner dashboard
    if (this.current.context.partnerId) {
      params.append('partner', this.current.context.partnerId);
    }
    // if its admin dashboard
    if (this.isAdminDashboard) {
      params.append('email', email);
    }
    try {
      await api.post(
        `/download_patient_data?${params.toString()}`,
        {
          data: baseBody,
        },
        {
          headers: {
            Authorization: !this.isAdminDashboard
              ? `Token ${this.token}`
              : undefined,
          },
        },
      );
    } finally {
      this.isDownloadingPatientTable = false;
    }
  }

  public setToken(token: string) {
    this.token = token;
  }

  public isTokenSet() {
    return typeof this.token !== 'undefined' && this.token.length > 0;
  }

  @computed
  public get groupName(): string | undefined {
    if (typeof this.current === 'undefined') {
      return undefined;
    }
    return this.current.context.groupName;
  }

  @computed
  public get therapistList(): Array<SelectOption<string>> {
    if (typeof this.current === 'undefined') {
      return [];
    }
    return this.current.context.selectedTherapists;
  }

  @computed
  public get time(): string | undefined {
    if (typeof this.current === 'undefined') {
      return undefined;
    }
    return this.current.context.time;
  }

  @computed
  public get error(): Error | undefined {
    if (typeof this.current === 'undefined') {
      return undefined;
    }

    return this.current.context.error;
  }

  @computed get providerKind(): string {
    if (this.isAdminDashboard || typeof this.current === 'undefined') {
      return '';
    }
    if (this.current.context.partnerId) {
      return 'partner';
    }

    if (this.groupName) {
      return 'physician_group';
    }

    return 'physician';
  }
}
