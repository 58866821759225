import {
  DowngradedPatientDataset,
  DowngradedPatientDropdownOption,
  DowngradedPatientsResponse,
} from 'types';

import { DowngradedPatientRow } from './downgraded-patient-row.model';

type ChartResponseData = DowngradedPatientsResponse['data']['charts']['data'];

export class DowngradedPatient {
  rows: DowngradedPatientRow[];

  labels: string[];

  data: DowngradedPatientDataset[];

  reasonsNotBooked: DowngradedPatientDropdownOption[];

  totalPatients: number;

  private mapToDataset(data: ChartResponseData): DowngradedPatientDataset[] {
    return Object.values(data)
      .map((v) => ({ count: v.amount, percentage: v.percentage }))
      .filter((v) => v.count > 0);
  }

  private mapLabels(data: ChartResponseData): string[] {
    return Object.keys(data).filter((label) => data[label].amount > 0);
  }

  constructor(response: DowngradedPatientsResponse) {
    this.rows = response.data.table.map((p) => new DowngradedPatientRow(p));
    this.labels = this.mapLabels(response.data.charts.data);
    this.reasonsNotBooked = [];
    this.data = this.mapToDataset(response.data.charts.data);
    this.totalPatients = response.data.charts.total_patients;
  }
}
