import { createActor } from 'xstate';

import { BaseChartStore } from 'features/base-chart-store';
import { SelectOption, BodyResponse } from 'types';

import { Body } from '../body.model';
import { BodySerializer } from '../serializer';

export class BodyStore extends BaseChartStore<Body, BodyResponse> {
  constructor(private serializer: BodySerializer) {
    super('Body Machine');
    // extend base chart machine if necessary
    this.service = createActor(this.machine);
    this.service.subscribe((state) => {
      this.current = state;
    });
    this.service.start();
  }

  // @middleware()
  protected async doFetch(
    therapists: Array<SelectOption<string>>,
    partnerId: string | undefined,
    partners: Array<SelectOption<string>>,
    clinics: Array<SelectOption<string>>,
    time: string,
    signal?: AbortSignal,
  ) {
    const jsonResponse = await this.load(
      'body_parts',
      {
        therapists,
        partnerId,
        partners,
        clinics,
        time,
      },
      signal,
    );

    return this.serializer.deserialize(jsonResponse);
  }
}
