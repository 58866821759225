import { SerializerError } from 'errors';
import { ChangeInPsfsScaleResponse } from 'types';

import { ChangeInPsfsScale } from '../change-in-psfs-scale.model';

export class ChangeInPsfsScaleSerializer {
  deserialize(target: ChangeInPsfsScaleResponse): ChangeInPsfsScale {
    const {
      info: { data, decrease_percentage: counter },
    } = target;

    const dataIsNumeric = data.every((datum) => typeof datum === 'number');
    if (!dataIsNumeric) {
      throw new SerializerError(
        'ChangeInPsfsScaleSerializer',
        'data items are expected to be numeric',
      );
    }

    const percentageIsNumeric = typeof counter === 'number';
    if (!percentageIsNumeric) {
      throw new SerializerError(
        'ChangeInPsfsScaleSerializer',
        'decrease_percentage is expected to be numeric',
      );
    }

    return new ChangeInPsfsScale({
      data,
      counter,
    });
  }
}
