import './Checkbox.css';

type CheckboxProps = {
  selected?: boolean;
};

export function Checkbox({ selected }: CheckboxProps) {
  if (selected) {
    return (
      <span className="checkmark checkmark-purple selected">
        <span className="check" />
      </span>
    );
  }
  return <span className="checkmark blank" />;
}
