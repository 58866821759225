import { observer } from 'mobx-react-lite';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { Button } from 'components/Button';
import { ChartDescription } from 'components/ChartDescrption';
// eslint-disable-next-line import/no-cycle
import { ErrorBoundary } from 'components/ErrorBoundary';
import { Progress } from 'components/Progress';
import { Typography } from 'components/Typography';
import { DoughnutProps } from 'types';
import { formatNumber } from 'utils';

import { PatientSatisfaction } from '../patient-satisfaction.model';
import { PatientSatisfactionAdapter } from '../store';

import { ThumbsDown, ThumbsUp } from './Icons';

function Fetching() {
  return (
    <Progress
      show
      className="nps-satisfaction__loader"
      data-testid="progress"
    />
  );
}

interface ChartLegend {
  percentage: number;
  tooltip: string;
  bgColor: string;
  up?: boolean;
}

const Legend: React.FC<ChartLegend> = function Legend(props) {
  const { percentage, bgColor, up, tooltip } = props;

  return (
    <li
      className="satisfaction__legend"
      data-tooltip-content={tooltip}
      data-tooltip-id="nps-score-tooltip"
    >
      {up ? <ThumbsUp /> : <ThumbsDown height={31} />}
      <div className="satisfaction__legend-progress">
        <div
          className="satisfaction__legend-bar"
          style={{ backgroundColor: bgColor, width: `${percentage}%` }}
        />
      </div>
      <ReactTooltip
        id="nps-score-tooltip"
        place="bottom"
        variant="dark"
        className="nps-satisfaction__tooltip"
      />
    </li>
  );
};

function EmptyResponse() {
  return (
    <div className="nps-satisfaction__empty-container">
      <div className="nps-satisfaction__empty">
        <Typography variant="parragraph" fontWeight="medium" color="medium">
          Insufficient Responses
        </Typography>
        <br />
        <Typography variant="caption" className="nps-satisfaction__empty-msg">
          Scores will be displayed once more than 3 responses are received.
        </Typography>
      </div>
    </div>
  );
}

interface PatientSatisfactionProps {
  model: PatientSatisfaction;
}

function Success({ model }: PatientSatisfactionProps) {
  const chartProps: DoughnutProps = {
    data: {
      labels: ['thumbs-up', 'thumbs-down'],
      datasets: [
        {
          backgroundColor: ['#974EBC', '#2FACBF'],
          data: model.data,
          borderWidth: 0,
        },
      ],
    },
    height: 180,
    options: {
      animation: false,
      maintainAspectRatio: false,
      cutout: '88%',
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
      },
    },
  };
  if (model.isEmpty) {
    return <EmptyResponse />;
  }
  return (
    <>
      <div className="nps-satisfaction__chart-container">
        <Doughnut {...chartProps} />
        <Typography
          className="nps-satisfaction__chart-text"
          color="light-dark"
          variant="displayMedium"
        >
          {model.percentages[0]}%
        </Typography>
      </div>
      <ul className="satisfaction-legends">
        <Legend
          bgColor="#974EBC"
          percentage={model.percentages[0]}
          tooltip={`${formatNumber(model.data[0])} thumbs up`}
          up
        />
        <Legend
          bgColor="#2FACBF"
          percentage={model.percentages[1]}
          tooltip={`${formatNumber(model.data[1])} thumbs down`}
        />
      </ul>
      <ChartDescription>
        Thumbs up and Thumbs down is provided by patients after every session
        through the Luna app.
      </ChartDescription>
    </>
  );
}

interface FailedProps {
  onRetry: VoidFunction;
  error?: Error;
}

const Failed: React.FC<FailedProps> = function Failed(props) {
  const { onRetry, error } = props;

  return (
    <div className="nps-satisfaction__placeholder">
      <Typography
        variant="body"
        component="p"
        color="dark"
        className="nps-satisfaction__title"
      >
        An error prevented us from loading this chart
      </Typography>

      <Button variant="primary" onClick={onRetry}>
        Try Again
      </Button>
    </div>
  );
};

const PatientSatisfactionComponent: React.FC = observer(() => {
  const store = React.useContext(PatientSatisfactionAdapter);
  if (store.fetched) {
    return <Success model={store.cache!} />;
  }
  if (store.failure) {
    return <Failed onRetry={() => store.refetch()} error={store.error} />;
  }
  return <Fetching />;
});

function FallbackUI() {
  return (
    <Typography
      variant="body"
      component="p"
      color="dark"
      className="nps-satisfaction__title"
    >
      Something went wrong.
    </Typography>
  );
}

export function PatientSatisfactionView() {
  return (
    <ErrorBoundary place="Patient Satisfaction" renderError={<FallbackUI />}>
      <PatientSatisfactionComponent />
    </ErrorBoundary>
  );
}
