import './BaseStyles.css';
import clsx from 'clsx';
import { useEffect, useState } from 'react';

import { DropdownArrowIcon } from 'components/Icons';
import useClickOutside from 'hooks/useClickOutside';
import { DropdownOption, MultiDropdownProps } from 'types';

export function MultiDropdown(props: MultiDropdownProps) {
  const {
    placeholder = '',
    options = [],
    onSelect,
    selectedOptions,
    MenuItem,
    className,
  } = props;
  const [selectedValues, setSelectedValues] =
    useState<DropdownOption[]>(selectedOptions);
  const [ref, isMenuOpen, setIsMenuOpen] = useClickOutside<HTMLDivElement>();

  useEffect(() => {
    onSelect(selectedValues.map((s) => s.value));
  }, [selectedValues, onSelect]);

  const isOptionSelected = (o: DropdownOption) =>
    selectedValues.some((s) => s.value === o.value);

  function onClick() {
    setIsMenuOpen((prev) => !prev);
  }

  return (
    <div
      className={clsx(['select-container dropdown', className])}
      ref={ref}
      onClick={onClick}
      onKeyUp={onClick}
      role="menu"
      tabIndex={0}
    >
      <div
        className="select-box"
        style={{ borderColor: isMenuOpen ? '#974EBC' : '#dfe3e8' }}
      >
        <div className="placeholder">{placeholder}</div>
        <div className="icon">
          <DropdownArrowIcon />
        </div>
      </div>
      <div
        className="select-menu"
        style={{ display: isMenuOpen ? 'flex' : 'none' }}
        role="listbox"
      >
        {options.map((o) => (
          <MenuItem
            tooltip={o.tooltip}
            key={o.label}
            value={o.value}
            label={o.label}
            isSelected={isOptionSelected(o)}
            onClick={() => {
              if (isOptionSelected(o)) {
                setSelectedValues(
                  selectedValues.filter((v) => v.value !== o.value),
                );
              } else {
                setSelectedValues([...selectedValues, o]);
              }
            }}
          />
        ))}
      </div>
    </div>
  );
}
