import { AxiosError } from 'axios';
import { observable, computed } from 'mobx';
import {
  ActorRefFrom,
  DoneActorEvent,
  ErrorActorEvent,
  SnapshotFrom,
  assign,
  createActor,
  fromPromise,
  setup,
} from 'xstate';

import api from 'lib/api';
import { SelectOption, OpenTasksResponse } from 'types';

import { OpenTaskPatient } from '../open-tasks.model';
import { OpenTaskSerializer } from '../serializer';

interface MachineContext {
  data?: Array<OpenTaskPatient>;
  error?: Error;
  selectedTherapists: Array<SelectOption<string>>;
  permission?: boolean | undefined;
  openTaskSet: OpenTaskPatient | undefined;
  signStatus: 'idle' | 'success' | 'failure';
}

interface SelectedTherapistsEvent {
  type: 'THERAPISTS_SELECTED';
  ids: Array<SelectOption<string>>;
  permission: boolean | undefined;
}

interface SetOpenTaskEvent {
  type: 'OPEN_TASK_SET';
  openTask: OpenTaskPatient | undefined;
}

interface SetSignStatusEvent {
  type: 'SET_SIGN_STATUS';
  status: 'idle' | 'success' | 'failure';
}
interface RemoveOpenTaskEvent {
  type: 'REMOVE_CURRENT_OPEN_TASK';
}

type MachineEvent =
  | {
      type: 'RETRY_EVENT';
    }
  | SetOpenTaskEvent
  | SelectedTherapistsEvent
  | SetSignStatusEvent
  | RemoveOpenTaskEvent
  | DoneActorEvent<OpenTaskPatient[]>
  | ErrorActorEvent<Error>;

interface FetchDataInput {
  ids: Array<SelectOption<string>>;
  permission: boolean | undefined;
}

export class OpenTasksStore {
  @observable
  protected current!: SnapshotFrom<typeof this.machine>;

  protected service!: ActorRefFrom<typeof this.machine>;

  constructor(private serializer: OpenTaskSerializer) {
    this.service = createActor(this.machine);
    this.service.subscribe((state) => {
      this.current = state;
    });
    this.service.start();
  }

  private machine = setup({
    types: { context: {} as MachineContext, events: {} as MachineEvent },
    actions: {
      saveData: assign({
        data: ({ event }) => {
          const { output } = event as DoneActorEvent<OpenTaskPatient[]>;

          return output;
        },
      }),
      saveError: assign({
        error: ({ event }) => {
          const { error } = event as ErrorActorEvent<Error>;

          return error;
        },
      }),
      deleteError: assign({
        error: undefined,
      }),
      setTherapists: assign({
        selectedTherapists: ({ event }) =>
          (event as SelectedTherapistsEvent).ids,
        permission: ({ event }) =>
          (event as SelectedTherapistsEvent).permission,
      }),
      setOpenTask: assign({
        openTaskSet: ({ event }) => {
          const { openTask } = event as SetOpenTaskEvent;

          return openTask;
        },
      }),
      setSignStatus: assign({
        signStatus: ({ event }) => {
          const { status } = event as SetSignStatusEvent;

          return status;
        },
      }),
      removeCurrentOpenTask: assign({
        data: ({ context }) => {
          const current = context.openTaskSet;
          if (typeof current === 'undefined') {
            return context.data;
          }
          const previousTaks = context.data || [];
          // Remove the signed task from state
          return previousTaks.filter(
            (taks) => taks.planOfCareId !== current.planOfCareId,
          );
        },
      }),
    },
    actors: {
      fetchOpenTaskModel: fromPromise<OpenTaskPatient[], FetchDataInput>(
        ({ input, signal }) =>
          this.doFetch(input.ids, input.permission, signal),
      ),
    },
  }).createMachine({
    id: 'Open Tasks Machine',
    initial: 'initial',
    context: {
      data: undefined,
      error: undefined,
      selectedTherapists: [],
      permission: undefined,
      openTaskSet: undefined,
      signStatus: 'idle',
    },
    states: {
      initial: {
        on: {
          THERAPISTS_SELECTED: {
            target: 'fetching',
            actions: ['setTherapists'],
          },
        },
      },
      fetching: {
        on: {
          THERAPISTS_SELECTED: {
            reenter: true,
            target: 'fetching',
          },
        },
        invoke: {
          id: 'fetchOpenTaskModel',
          src: 'fetchOpenTaskModel',
          input: ({ context, event }) => ({
            ids:
              (event as SelectedTherapistsEvent).ids ||
              context.selectedTherapists,
            permission: (event as SelectedTherapistsEvent).permission,
          }),
          onDone: {
            target: 'success',
            actions: ['saveData', 'deleteError'],
          },
          onError: {
            target: 'failure',
            actions: ['saveError'],
          },
        },
      },
      success: {
        on: {
          THERAPISTS_SELECTED: {
            target: 'fetching',
            actions: ['setTherapists'],
          },
          OPEN_TASK_SET: {
            actions: 'setOpenTask',
          },
          SET_SIGN_STATUS: {
            actions: 'setSignStatus',
          },
          REMOVE_CURRENT_OPEN_TASK: {
            actions: 'removeCurrentOpenTask',
          },
          RETRY_EVENT: 'fetching',
        },
      },
      failure: {
        on: {
          RETRY_EVENT: 'fetching',
          THERAPISTS_SELECTED: {
            target: 'fetching',
            actions: ['setTherapists'],
          },
        },
      },
    },
  });

  private getToken() {
    const { pathname } = window.location;
    const pathnameSplited = pathname.split('/');
    const tokenIdx = pathnameSplited.indexOf('dashboard') + 1;
    const token = pathnameSplited[tokenIdx];

    return token;
  }

  private async load(
    therapists: Array<SelectOption<string>>,
    permission?: boolean,
    signal?: AbortSignal,
  ): Promise<OpenTasksResponse> {
    const token = this.getToken();
    const therapistsIds = therapists.map((doctor) => doctor.value);
    const hasNoToken =
      typeof token === 'undefined' || !token.length || token.length === 0;
    const request = await api.post<OpenTasksResponse>(
      '/plans_of_care',
      {
        data: {
          can_sign_poc: permission,
          physician_ids: hasNoToken ? therapistsIds : [],
        },
      },
      { headers: { Authorization: `Token ${token}` }, signal },
    );

    return request.data;
  }

  public fetch(
    therapists: Array<SelectOption<string>>,
    partnerId: string | undefined,
    permission?: boolean,
  ): void {
    this.service.send({
      type: 'THERAPISTS_SELECTED',
      ids: therapists,
      permission,
    });
  }

  private async doFetch(
    therapists: Array<SelectOption<string>>,
    permission?: boolean,
    signal?: AbortSignal,
  ) {
    const jsonResponse = await this.load(therapists, permission, signal);

    return this.serializer.deserialize(jsonResponse);
  }

  @computed
  public get cache(): Array<OpenTaskPatient> | undefined {
    if (typeof this.current === 'undefined') {
      return undefined;
    }
    return this.current.context.data as Array<OpenTaskPatient>;
  }

  @computed
  public get fetching(): boolean {
    if (typeof this.current === 'undefined') {
      return false;
    }

    return this.current.matches('fetching');
  }

  @computed
  public get fetched(): boolean {
    if (typeof this.current === 'undefined') {
      return false;
    }

    return this.current.matches('success');
  }

  @computed
  public get failure(): boolean {
    if (typeof this.current === 'undefined') {
      return false;
    }

    return this.current.matches('failure');
  }

  @computed
  public get actualOpenTask() {
    if (typeof this.current === 'undefined') {
      return undefined;
    }

    return this.current.context.openTaskSet;
  }

  @computed
  public get actualSignStatus() {
    if (typeof this.current === 'undefined') {
      return undefined;
    }

    return this.current.context.signStatus;
  }

  public refetch(): void {
    this.service.send({ type: 'RETRY_EVENT' });
  }

  public async signOpenTask(sign: boolean, note: string | undefined) {
    const token = this.getToken();
    const actualOpenTask = this.current.context.openTaskSet;

    if (typeof actualOpenTask === 'undefined') {
      return;
    }

    const data =
      note === ''
        ? {
            plan_of_care_id: actualOpenTask.planOfCareId,
            signed: sign,
          }
        : {
            plan_of_care_id: actualOpenTask.planOfCareId,
            signed: sign,
            comments: note,
          };
    try {
      await api.post(
        `/sign_plan_of_care`,
        {
          sign: data,
        },
        { headers: { Authorization: `Token ${token}` } },
      );
      this.service.send({ type: 'REMOVE_CURRENT_OPEN_TASK' });
      this.setSignStatus('success');
    } catch (err) {
      this.setSignStatus('failure');
      if (err instanceof AxiosError) {
        throw new Error(err.response?.data?.message || err.message);
      }
    }
  }

  public setOpenTask(openTask: OpenTaskPatient): void {
    this.service.send({ type: 'OPEN_TASK_SET', openTask });
  }

  public setSignStatus(status: 'idle' | 'success' | 'failure'): void {
    this.service.send({ type: 'SET_SIGN_STATUS', status });
  }

  @computed
  public get generalDashboard() {
    const token = this.getToken();

    if (typeof token === 'undefined' || !token.length || token.length === 0)
      return false;

    return true;
  }

  @computed
  public get error(): Error | undefined {
    if (typeof this.current === 'undefined') {
      return undefined;
    }

    return this.current.context.error;
  }

  @computed
  public get context() {
    if (typeof this.current === 'undefined') {
      return undefined;
    }

    return this.current.context;
  }
}
