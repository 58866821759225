export interface ClinicInstanceOptions {
  name: string;
  id: string;
  key: string;
  code: string;
}

export class Clinic {
  public name: ClinicInstanceOptions['name'];

  public id: ClinicInstanceOptions['id'];

  public code: ClinicInstanceOptions['code'];

  public key: ClinicInstanceOptions['key'];

  constructor(opts: ClinicInstanceOptions) {
    this.name = opts.name;
    this.id = opts.id;
    this.key = opts.key;
    this.code = opts.code;
  }
}
