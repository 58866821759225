import { SerializerError } from 'errors';
import { DowngradedPatientsResponse } from 'types';

import { DowngradedPatient } from '../downgraded-patient.model';

export class DowngradedPatientsSerializer {
  deserialize(target: DowngradedPatientsResponse): DowngradedPatient {
    const {
      data: { table },
    } = target;

    table.forEach((patient) => {
      if (typeof patient.patient !== 'string') {
        throw new SerializerError(
          'DowngradedPatientsSerializer',
          'patient name are expected to be string',
        );
      }
    });

    return new DowngradedPatient(target);
  }
}
