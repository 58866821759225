/* eslint-disable no-nested-ternary */
import './Progress.css';
import clsx from 'clsx';
import React from 'react';

import { Overlay } from 'components/Overlay';

type Props = JSX.IntrinsicElements['div'] & {
  show?: boolean;
  variant?: 'normal' | 'full-screen';
};

export const Progress: React.FC<Props> = function Progress({
  show,
  variant = 'normal',
  className,
  ...rest
}) {
  const progress = (
    <div className={clsx(['spinnerContainer', className])} {...rest}>
      <span hidden>Asynchronous operation in progress. Please wait...</span>

      <div className="spinner" />
    </div>
  );

  switch (variant) {
    case 'full-screen':
      return typeof show === 'boolean' ? (
        show ? (
          <Overlay>{progress}</Overlay>
        ) : null
      ) : (
        <Overlay>{progress}</Overlay>
      );

    default:
      return typeof show === 'boolean' && show
        ? show
          ? progress
          : null
        : progress;
  }
};
