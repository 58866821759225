import { observer } from 'mobx-react-lite';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { Button } from 'components/Button';
import { ChartDescription } from 'components/ChartDescrption';
// eslint-disable-next-line import/no-cycle
import { ErrorBoundary } from 'components/ErrorBoundary';
import { Progress } from 'components/Progress';
import { Typography } from 'components/Typography';
import { DoughnutProps } from 'types';
import { formatNumber } from 'utils';

import { NPS } from '../nps.model';
import { NPSAdapter } from '../store';

import { DetractorsIcon, PassivesIcon, PromotersIcon } from './Icons';

function Fetching() {
  return (
    <Progress
      show
      className="nps-satisfaction__loader"
      data-testid="progress"
    />
  );
}

function EmptyResponse() {
  return (
    <div className="nps-satisfaction__empty-container">
      <div className="nps-satisfaction__empty">
        <Typography variant="parragraph" fontWeight="medium" color="medium">
          Insufficient Responses
        </Typography>
        <br />
        <Typography variant="caption" className="nps-satisfaction__empty-msg">
          Scores will be displayed once more than 3 responses are received.
        </Typography>
      </div>
    </div>
  );
}

type NPSType = 'promoters' | 'passives' | 'detractors';

const typeColor: Record<NPSType, string> = {
  promoters: '#47CC00',
  passives: '#FFA633',
  detractors: '#F03D3D',
};

const typeIcon: Record<NPSType, React.ReactElement> = {
  promoters: <PromotersIcon />,
  passives: <PassivesIcon />,
  detractors: <DetractorsIcon />,
};

interface ChartLegend {
  percentage: number;
  type: NPSType;
  tooltip: string;
}

const Legend: React.FC<ChartLegend> = function Legend(props) {
  const { percentage, type, tooltip } = props;

  return (
    <li
      className="nps-legend"
      data-tooltip-content={tooltip}
      data-tooltip-id="nps-score-tooltip"
    >
      <Typography variant="parragraph" className={`nps-lenged-${type}`}>
        {percentage}%
      </Typography>
      {typeIcon[type]}
      <ReactTooltip
        id="nps-score-tooltip"
        place="bottom"
        variant="dark"
        className="nps-satisfaction__tooltip"
      />
    </li>
  );
};

interface NPSScoreProps {
  model: NPS;
}

function Success({ model }: NPSScoreProps) {
  const chartProps: DoughnutProps = {
    data: {
      labels: ['promoters', 'passives', 'detractors'],
      datasets: [
        {
          backgroundColor: Object.values(typeColor),
          data: model.data,
          borderWidth: 0,
        },
      ],
    },
    height: 180,
    options: {
      animation: false,
      maintainAspectRatio: false,
      cutout: '88%',
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
      },
    },
  };
  if (model.isEmpty) {
    return <EmptyResponse />;
  }
  return (
    <>
      <div className="nps-satisfaction__chart-container">
        <Doughnut {...chartProps} />
        <Typography
          className="nps-satisfaction__chart-text"
          color="light-dark"
          variant="displayMedium"
        >
          {model.value}
        </Typography>
      </div>
      <ul className="nps-legends">
        <Legend
          percentage={model.percentages[0]}
          type="promoters"
          tooltip={`${formatNumber(model.data[0])} Promoters`}
        />
        <Legend
          percentage={model.percentages[1]}
          type="passives"
          tooltip={`${formatNumber(model.data[1])} Passives`}
        />
        <Legend
          percentage={model.percentages[2]}
          type="detractors"
          tooltip={`${formatNumber(model.data[2])} Detractors`}
        />
      </ul>
      <ChartDescription>
        Net Promoter Scores (NPS) are provided by patients every 5 visits via
        Progress Forms.
      </ChartDescription>
    </>
  );
}

interface FailedProps {
  onRetry: VoidFunction;
  error?: Error;
}

const Failed: React.FC<FailedProps> = function Failed(props) {
  const { onRetry, error } = props;

  return (
    <div className="nps-satisfaction__placeholder">
      <Typography
        variant="body"
        component="p"
        color="dark"
        className="nps-satisfaction__title"
      >
        An error prevented us from loading this chart
      </Typography>

      <Button variant="primary" onClick={onRetry}>
        Try Again
      </Button>
    </div>
  );
};

const NPSScoreComponent: React.FC = observer(() => {
  const store = React.useContext(NPSAdapter);
  if (store.fetched) {
    return <Success model={store.cache!} />;
  }
  if (store.failure) {
    return <Failed onRetry={() => store.refetch()} error={store.error} />;
  }
  return <Fetching />;
});

function FallbackUI() {
  return (
    <Typography
      variant="body"
      component="p"
      color="dark"
      className="nps-satisfaction__title"
    >
      Something went wrong.
    </Typography>
  );
}

export function NPSScoreView() {
  return (
    <ErrorBoundary place="NPS Score" renderError={<FallbackUI />}>
      <NPSScoreComponent />
    </ErrorBoundary>
  );
}
