import { ChartDataset } from 'chart.js';

import { DowngradedPatient } from '../downgraded-patient.model';

interface DowngradedPatientChartDataset extends ChartDataset<'bar'> {
  data: Array<number>;
  backgroundColor: string[];
  hoverBackgroundColor: string[];
  barThickness: number;
}

const BOOKED_PATIENT_COLORS = {
  main: '#974EBC',
  hover: '#B168D6',
};

const NOT_BOOKED_PATIENT_COLORS = {
  main: '#2FACBF',
  hover: '#4EC2D3',
};

export class DowngradedPatientViewModel {
  constructor(
    private model: DowngradedPatient,
    private bookedPatients: number,
  ) {}

  public get labels() {
    return ['Booked', ...this.model.labels];
  }

  public get totalPatients() {
    return this.values.reduce((acc, curr) => acc + curr, 0);
  }

  public getPercentage(count: number) {
    const basePercentage = (count / this.totalPatients) * 100;
    if (basePercentage < 1) {
      return basePercentage;
    }
    return Math.round(basePercentage);
  }

  public get percentages() {
    return this.values.map((v) => this.getPercentage(v));
  }

  public get datasets(): Array<DowngradedPatientChartDataset> {
    // each data set
    return [
      {
        barThickness: 24,
        backgroundColor: this.labels.map((_, i) =>
          i === 0 ? BOOKED_PATIENT_COLORS.main : NOT_BOOKED_PATIENT_COLORS.main,
        ),
        hoverBackgroundColor: this.labels.map((_, i) =>
          i === 0
            ? BOOKED_PATIENT_COLORS.hover
            : NOT_BOOKED_PATIENT_COLORS.hover,
        ),
        data: this.percentages,
        minBarLength: 10,
      },
    ];
  }

  public get categories() {
    return [
      {
        category: {
          color: BOOKED_PATIENT_COLORS.main,
          label: 'Booked patients',
        },
      },
      {
        category: {
          color: NOT_BOOKED_PATIENT_COLORS.main,
          label: 'Unseen patients',
        },
      },
    ];
  }

  public get values() {
    return [this.bookedPatients, ...this.model.data.map((m) => m.count)];
  }

  public get isEmpty() {
    return this.model.data.length === 0;
  }

  public getNextMultipleOf(x: number, base = 10) {
    return x + (base - (x % base));
  }

  public get maxValue() {
    const maxPercetage = this.percentages.reduce((acc, curr) =>
      acc > curr ? acc : curr,
    );
    return Math.min(this.getNextMultipleOf(maxPercetage), 100);
  }
}
