import './Grid.css';
import { PropsWithChildren } from 'react';

type GridProps = PropsWithChildren<JSX.IntrinsicElements['div']>;

export function Grid({ children, ...props }: GridProps) {
  return (
    <div className="grid" {...props}>
      {children}
    </div>
  );
}
