import React from 'react';

import { AgeDistributionSerializer } from '../serializer';

import { AgeDistributionStore } from './age-distribution.store';

export const ageDistributionStore = new AgeDistributionStore(
  new AgeDistributionSerializer(),
);

export const AgeDistributionAdapter = React.createContext(ageDistributionStore);
