import { createId as cuid } from '@paralleldrive/cuid2';

import { RecentPatientFuncionalScale } from 'types';

export interface RecentPatientInstanceOptions {
  key?: string;
  date_of_birth: string;
  date_of_surgery: string;
  name: string;
  pain_scale: string;
  psfs_scale: string;
  functional_scale: RecentPatientFuncionalScale;
  sessions: string;
  discharged: string;
  patient_id: string;
  physician_id: string;
  partner_id: string;
  injury_name: string;
  rom_extension: string;
  rom_flexion: string;
  physician_name: string;
  escalated_full_message: string;
}

export class RecentPatient {
  key: string = cuid();

  date_of_surgery: RecentPatientInstanceOptions['date_of_surgery'];

  date_of_birth: RecentPatientInstanceOptions['date_of_birth'];

  name: RecentPatientInstanceOptions['name'];

  painScale: RecentPatientInstanceOptions['pain_scale'];

  psfsScale: RecentPatientInstanceOptions['psfs_scale'];

  functionalScale: RecentPatientInstanceOptions['functional_scale'];

  sessions: RecentPatientInstanceOptions['sessions'];

  discharged: boolean;

  patientId: RecentPatientInstanceOptions['patient_id'];

  physicianId: RecentPatientInstanceOptions['physician_id'];

  partnerId: RecentPatientInstanceOptions['partner_id'];

  injuryName: RecentPatientInstanceOptions['injury_name'];

  rom_extension: RecentPatientInstanceOptions['rom_extension'];

  rom_flexion: RecentPatientInstanceOptions['rom_flexion'];

  physicianName: RecentPatientInstanceOptions['physician_name'];

  escalatedMessage: RecentPatientInstanceOptions['escalated_full_message'];

  constructor(opts: RecentPatientInstanceOptions) {
    if (opts.key) {
      this.key = opts.key;
    }

    this.name = opts.name;
    this.date_of_birth = opts.date_of_birth;
    this.date_of_surgery = opts.date_of_surgery;
    this.painScale = opts.pain_scale;
    this.psfsScale = opts.psfs_scale;
    this.functionalScale = opts.functional_scale;
    this.sessions = opts.sessions;
    this.discharged = opts.discharged === 'true';
    this.patientId = opts.patient_id;
    this.physicianId = opts.physician_id;
    this.partnerId = opts.partner_id;
    this.injuryName = opts.injury_name;
    this.rom_extension = opts.rom_extension;
    this.rom_flexion = opts.rom_flexion;
    this.physicianName = opts.physician_name;
    this.escalatedMessage = opts.escalated_full_message;
  }
}
