import { RouterProvider } from 'react-router-dom';

import { ErrorBoundary } from 'components/ErrorBoundary';
import { AuthProvider } from 'context/auth';
import { ToastProvider } from 'context/toast-context';

import router from './routes';

export default function App() {
  return (
    <ErrorBoundary place="App">
      <AuthProvider>
        <ToastProvider>
          <RouterProvider router={router} />
        </ToastProvider>
      </AuthProvider>
    </ErrorBoundary>
  );
}
