import { SerializerError } from 'errors';
import { BodyResponse } from 'types';

import {
  Body,
  Knee,
  Hip,
  LowerBack,
  Shoulder,
  Neck,
  Hoos,
  Koos,
} from '../body.model';

export class BodySerializer {
  deserialize(target: BodyResponse): Body {
    const knee: Knee = {
      labels: ['Pain', 'PSFS', 'KOS'],
      data: [
        target.info.data.knee.pain[1],
        target.info.data.knee.psfs[1],
        target.info.data.knee.kos[1],
      ].filter(Boolean),
      mcid: [
        target.info.data.knee.pain[0],
        target.info.data.knee.psfs[0],
        target.info.data.knee.kos[0],
      ].filter(Boolean),
    };

    const hip: Hip = {
      labels: ['Pain', 'PSFS', 'LEFS'],
      data: [
        target.info.data.hip.pain[1],
        target.info.data.hip.psfs[1],
        target.info.data.hip.lefs[1],
      ].filter(Boolean),
      mcid: [
        target.info.data.hip.pain[0],
        target.info.data.hip.psfs[0],
        target.info.data.hip.lefs[0],
      ].filter(Boolean),
    };

    const lowerBack: LowerBack = {
      labels: ['Pain', 'PSFS', 'MDQ'],
      data: [
        target.info.data.lower_back.pain[1],
        target.info.data.lower_back.psfs[1],
        target.info.data.lower_back.mdq[1],
      ].filter(Boolean),
      mcid: [
        target.info.data.lower_back.pain[0],
        target.info.data.lower_back.psfs[0],
        target.info.data.lower_back.mdq[0],
      ].filter(Boolean),
    };

    const shoulder: Shoulder = {
      labels: ['Pain', 'PSFS', 'Quick Dash'],
      data: [
        target.info.data.shoulder.pain[1],
        target.info.data.shoulder.psfs[1],
        target.info.data.shoulder.quick_dash[1],
      ].filter(Boolean),
      mcid: [
        target.info.data.shoulder.pain[0],
        target.info.data.shoulder.psfs[0],
        target.info.data.shoulder.quick_dash[0],
      ].filter(Boolean),
    };

    const neck: Neck = {
      labels: ['Pain', 'PSFS', 'NDI'],
      data: [
        target.info.data.neck.pain[1],
        target.info.data.neck.psfs[1],
        target.info.data.neck.ndi[1],
      ].filter(Boolean),
      mcid: [
        target.info.data.neck.pain[0],
        target.info.data.neck.psfs[0],
        target.info.data.neck.ndi[0],
      ].filter(Boolean),
    };

    const hoos: Hoos = {
      labels: ['Pain', 'PSFS', 'LEFS'],
      data: [
        target.info.data.hoos.pain[1],
        target.info.data.hoos.psfs[1],
        target.info.data.hoos.lefs[1],
      ].filter(Boolean),
      mcid: [
        target.info.data.hoos.pain[0],
        target.info.data.hoos.psfs[0],
        target.info.data.hoos.lefs[0],
      ].filter(Boolean),
    };

    const koos: Koos = {
      labels: ['Pain', 'PSFS', 'KOS'],
      data: [
        target.info.data.koos.pain[1],
        target.info.data.koos.psfs[1],
        target.info.data.koos.kos[1],
      ].filter(Boolean),
      mcid: [
        target.info.data.koos.pain[0],
        target.info.data.koos.psfs[0],
        target.info.data.koos.kos[0],
      ].filter(Boolean),
    };

    [knee, hip, lowerBack, shoulder, neck, hoos, koos].forEach((part) => {
      const dataIsNumeric = part.data.every(Number.isInteger);
      if (!dataIsNumeric) {
        throw new SerializerError(
          'BodyPartSerializer',
          'data items are expected to be numeric',
        );
      }

      const mcidIsNumeric = part.mcid.every(Number.isInteger);
      if (!mcidIsNumeric) {
        throw new SerializerError(
          'BodyPartSerializer',
          'mcid items are expected to be numeric',
        );
      }
    });

    return new Body(knee, hip, lowerBack, shoulder, neck, hoos, koos);
  }
}
