import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

export default function initLogRocket() {
  if (process.env.NODE_ENV === 'production') {
    const logRocketAppId =
      process.env.REACT_APP_LOGROCKET_APP_ID?.replace('-omega', '') || '';
    LogRocket.init(logRocketAppId);
    setupLogRocketReact(LogRocket);
  }
}
