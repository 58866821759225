/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Sentry from '@sentry/browser';
import axios from 'axios';
import LogRocket from 'logrocket';
import { observable, computed, reaction, action } from 'mobx';
import {
  ActorRefFrom,
  DoneActorEvent,
  SnapshotFrom,
  assign,
  createActor,
  fromPromise,
  setup,
  createMachine,
  ErrorActorEvent,
} from 'xstate';

// eslint-disable-next-line import/no-cycle
import { AgeDistributionStore } from 'features/age-distribution';
import { BodyStore } from 'features/body';
import { CaseDistributionStore } from 'features/case-distribution';
import { ChangeInPainLevelStore } from 'features/change-in-pain-level';
import { ChangeInPsfsScaleStore } from 'features/change-in-psfs-scale';
import { DownGradedPatientsStore } from 'features/downgraded-patients';
import { NPSStore } from 'features/nps';
import { OpenTasksStore } from 'features/open-tasks/store';
import { PatientSatisfactionStore } from 'features/patient-satisfaction';
import { RecentPatientsStore } from 'features/recent-patients';
import { VisitsByInjuryTypeStore } from 'features/visits-by-injury';
import api, { getAccessToken, manager } from 'lib/api';
import {
  TherapistsResponse,
  PermissionsResponse,
  PartnersResponse,
  GlobalDataResponse,
  ClinicResponse,
  Clinic,
  TherapistRawResponse,
  SelectOption,
} from 'types';
import { isValidBooleanString } from 'utils';
import { NetworkTracker } from 'utils/requestTracker';

import { Partner, Therapist } from './models';
import {
  ClinicsSerializer,
  PartnersSerializer,
  TherapistsSerializer,
} from './serializers';

type ProviderKind = 'partner' | 'physician_group' | 'physician' | 'clinic' | '';

export interface AdminDataResponse {
  clinics: Clinic[];
  partners: Partner[];
  therapists: Therapist[];
  permissions: PermissionsResponse;
}

export interface AdminDataFetchEvent {
  type: string;
  data: AdminDataResponse;
}

interface MachineContext {
  data?: {
    therapists: Array<Therapist>;
    partners: Array<Partner>;
    clinics: Array<Clinic>;
  };
  selectedTherapists: Array<SelectOption<string>>;
  selectedPartners: Array<SelectOption<string>>;
  selectedClinics: Array<SelectOption<string>>;
  error?: Error;
  successMessage?: string | undefined;
  partnerName?: string | undefined;
  clinicName?: string;
  clinicId?: string;
  partnerId?: string | undefined;
  groupName?: string | undefined;
  groupId?: string | undefined;
  chartPermission?: boolean | undefined;
  signPermission?: boolean | undefined;
  therapistListPermission?: boolean | undefined;
  time: string;
  dashboardId: string;
  providerEmail: string;
}

export interface FetchedDataEvent {
  type: string;
  data: {
    therapists: Array<Therapist>;
    group?: {
      id: string;
      name: string;
    };
    partner?: {
      id: string;
      name: string;
    };
    clinic?: {
      id: string;
      name: string;
    };
    permissions: PermissionsResponse | undefined;
    partners: Array<Partner>;
    clinics: Array<Clinic>;
  };
}

interface SuccessDataEvent {
  type: string;
  data: {
    message: string;
  };
}

interface SelectedTherapistsEvent {
  type: 'THERAPISTS_SELECTED';
  ids: Array<SelectOption<string>>;
}

interface SelectedPartnersEvent {
  type: 'PARTNERS_SELECTED';
  ids: Array<SelectOption<string>>;
}

interface SelectedClinicsEvent {
  type: 'CLINICS_SELECTED';
  ids: Array<SelectOption<string>>;
}

interface SelectedTimeEvent {
  type: 'TIME_SELECTED';
  time: string;
}

/* interface RefreshAccessTokenEvent {
  type: string;
  data: AccessTokenFields;
} */

interface SessionData {
  dashboard_id: string;
  provider_email: string;
}

interface SessionIdEvent {
  type: 'SESSION_ID_EVENT';
  data: SessionData;
}

type MachineEvent =
  | {
      type: 'RETRY_EVENT';
    }
  | {
      type: 'NEW_ACCESS_LINK_EVENT';
    }
  | SelectedTherapistsEvent
  | SelectedPartnersEvent
  | SelectedClinicsEvent
  | SelectedTimeEvent
  | SessionIdEvent
  | AdminDataFetchEvent
  | DoneActorEvent<FetchedDataEvent['data']>
  | ErrorActorEvent<Error>
  | DoneActorEvent<SuccessDataEvent['data']>
  | DoneActorEvent<SessionData>;

const emptyResponse: GlobalDataResponse = {
  therapists: {
    info: {
      data: [],
      group: {
        id: '',
        name: '',
        code: '',
      },
      partner: {
        id: '',
        name: '',
        code: '',
      },
      permissions: {
        data: {
          can_sign_poc: false,
          can_view_charts: false,
          can_view_physicians: false,
          name: '',
        },
      },
    },
  },
  partners: {
    info: {
      data: [],
    },
  },
  clinics: {
    info: {
      data: [],
    },
  },
};

export class AppStore {
  @observable
  protected current: SnapshotFrom<typeof this.machine>;

  protected service: ActorRefFrom<typeof this.machine>;

  constructor(
    caseDistributionStore: CaseDistributionStore,
    visitsByInjuryTypeStore: VisitsByInjuryTypeStore,
    ageDistributionStore: AgeDistributionStore,
    changeInPainLevelStore: ChangeInPainLevelStore,
    changeInPfsScaleStore: ChangeInPsfsScaleStore,
    bodyStore: BodyStore,
    recentPatientsStore: RecentPatientsStore,
    downgradedPatientsStore: DownGradedPatientsStore,
    openTasksStore: OpenTasksStore,
    npsStore: NPSStore,
    patientSatisfactionStore: PatientSatisfactionStore,
    private readonly therapistSerializer: TherapistsSerializer,
    private readonly partnerSerializer: PartnersSerializer,
    private readonly clinicSerializer: ClinicsSerializer,
  ) {
    this.service = createActor(this.machine);
    this.service.subscribe((state) => {
      this.current = state;
    });
    // Workaround to get initial state
    this.current = this.service.getSnapshot();
    manager.urlToken = AppStore.getToken();
    manager.isAdmin = this.isAdmin();

    reaction(
      () => ({
        selectedTherapists: this.selectedTherapists,
        partnerId: this.partnerId,
        selectedPartners: this.selectedPartners,
        selectedClinics: this.selectedClinics,
        groupName: this.groupName,
        time: this.time,
      }),
      ({
        selectedTherapists,
        partnerId,
        selectedPartners,
        groupName,
        time,
        selectedClinics,
      }) => {
        const stores: Array<any> = [
          caseDistributionStore,
          visitsByInjuryTypeStore,
          ageDistributionStore,
          changeInPainLevelStore,
          changeInPfsScaleStore,
          bodyStore,
          npsStore,
          patientSatisfactionStore,
        ];

        if (this.signPermission) {
          openTasksStore.fetch(
            selectedTherapists || [],
            partnerId,
            this.signPermission,
          );
        }

        const partners = selectedPartners || [];
        const clinics = selectedClinics || [];
        stores.forEach((store) => {
          store.setClinics(clinics);
          store.setPartners(partners);
          store.fetch({ therapists: selectedTherapists, partnerId, time });
        });
        recentPatientsStore.setPartners(partners);
        recentPatientsStore.setClinics(clinics);
        recentPatientsStore.setGroupName(groupName || '');
        recentPatientsStore.setToken(AppStore.getToken());
        recentPatientsStore.resetFilteredPatients();
        recentPatientsStore.fetch({
          therapists: selectedTherapists || [],
          time: time!,
          partnerId,
          permission: this.chartPermission,
        });

        downgradedPatientsStore.setPartners(partners);
        downgradedPatientsStore.setClinics(clinics);
        downgradedPatientsStore.setGroupName(groupName || '');
        downgradedPatientsStore.setToken(AppStore.getToken());
        downgradedPatientsStore.resetFilteredPatients();
        downgradedPatientsStore.fetch({
          therapists: selectedTherapists || [],
          time: time!,
          partnerId,
          permission: this.chartPermission,
        });
      },
    );
    reaction(
      () => ({
        data: caseDistributionStore.cache,
      }),
      ({ data }) => visitsByInjuryTypeStore.setLabelOrder(data!.labels),
    );
  }

  private machine = setup({
    types: { context: {} as MachineContext, events: {} as MachineEvent },
    actions: {
      saveData: assign({
        data: ({ event }) => {
          const {
            output: { therapists, partners, clinics },
          } = event as DoneActorEvent<FetchedDataEvent['data']>;
          return {
            therapists,
            partners,
            clinics,
          };
        },
        selectedTherapists: ({ event }) => {
          const { output } = event as DoneActorEvent<FetchedDataEvent['data']>;
          const token = AppStore.getToken();

          let therapistList: SelectOption<string>[] = [];

          if (typeof token !== 'undefined' && token.length > 0) {
            if (
              output?.partner?.name !== null ||
              output?.group?.name !== null ||
              output.therapists.length <= 1
            ) {
              therapistList = output.therapists.map((t) => ({
                value: t.id,
                label: t.name,
                prefix: t.prefix,
                hubspotId: t.hubspotId,
              }));
            }
          }

          return therapistList;
        },
        selectedPartners: ({ event }) => {
          const { output } = event as DoneActorEvent<FetchedDataEvent['data']>;
          const token = AppStore.getToken();
          let partnerList: SelectOption<string>[] = [];

          if (typeof token !== 'undefined' && token.length > 0) {
            if (
              output?.partner?.name !== null ||
              output?.group?.name !== null ||
              output.partners.length <= 1
            ) {
              partnerList = output.partners.map((p) => ({
                value: p.id,
                prefix: '',
                label: p.name,
                hubspotId: '',
                code: p.code,
              }));
            }
          }
          return partnerList;
        },
        selectedClinics: ({ event }) => {
          const { output } = event as DoneActorEvent<FetchedDataEvent['data']>;
          const token = AppStore.getToken();
          let clinicList: SelectOption<string>[] = [];
          // if this is NOT admin
          if (typeof token !== 'undefined' && token.length > 0) {
            // If a partner/group/clinic exists
            const shouldMapClinics =
              output?.partner?.name !== null ||
              output?.group?.name !== null ||
              output?.clinic?.name !== null ||
              output?.clinics.length <= 1;
            if (shouldMapClinics) {
              clinicList = output.clinics.map((p) => ({
                value: p.id,
                prefix: '',
                label: p.name,
                hubspotId: '',
                code: p.code,
              }));
            }
          }
          return clinicList;
        },
        partnerName: ({ event }) => {
          const { output } = event as DoneActorEvent<FetchedDataEvent['data']>;
          if (output.partner) {
            return output.partner.name;
          }
          return '';
        },
        clinicName: ({ event }) => {
          const { output } = event as DoneActorEvent<FetchedDataEvent['data']>;
          if (output.clinic) {
            return output.clinic.name;
          }
          return '';
        },
        clinicId: ({ event }) => {
          const { output } = event as DoneActorEvent<FetchedDataEvent['data']>;
          if (output.clinic) {
            return output.clinic.id;
          }
          return '';
        },
        partnerId: ({ event }) => {
          const { output } = event as DoneActorEvent<FetchedDataEvent['data']>;
          if (output.partner) {
            return output.partner.id;
          }
          return '';
        },
        groupName: ({ event }) => {
          const { output } = event as DoneActorEvent<FetchedDataEvent['data']>;
          if (output.group) {
            return output.group.name;
          }
          return '';
        },
        groupId: ({ event }) => {
          const { output } = event as DoneActorEvent<FetchedDataEvent['data']>;
          if (output.group) {
            return output.group.id;
          }
          return '';
        },
        chartPermission: ({ event }) => {
          const { output } = event as DoneActorEvent<FetchedDataEvent['data']>;
          const { permissions } = output;

          if (!permissions) {
            return false;
          }
          return permissions.data.can_view_charts;
        },
        signPermission: ({ event }) => {
          const { output } = event as DoneActorEvent<FetchedDataEvent['data']>;
          const { permissions } = output;

          if (!permissions) {
            return false;
          }

          return permissions.data.can_sign_poc;
        },
        therapistListPermission: ({ event }) => {
          const { output } = event as DoneActorEvent<FetchedDataEvent['data']>;
          const { permissions } = output;

          if (!permissions) {
            return false;
          }
          return permissions.data.can_view_physicians;
        },
      }),
      initAdminData: assign({
        data: () => ({
          clinics: [],
          therapists: [],
          partners: [],
        }),
      }),
      saveAdminData: assign({
        data: ({ context, event }) => {
          const {
            output: { clinics, partners, therapists },
          } = event as DoneActorEvent<AdminDataFetchEvent['data']>;
          return {
            therapists: therapists || context.data?.therapists,
            clinics: clinics || context.data?.clinics,
            partners: partners || context.data?.partners,
          };
        },
        chartPermission: ({ event, context }) => {
          const { output } = event as DoneActorEvent<
            AdminDataFetchEvent['data']
          >;
          const { permissions } = output;
          return permissions?.data.can_view_charts || context.chartPermission;
        },
        signPermission: ({ event, context }) => {
          const { output } = event as DoneActorEvent<
            AdminDataFetchEvent['data']
          >;
          const { permissions } = output;

          return permissions?.data.can_sign_poc || context.signPermission;
        },
        therapistListPermission: ({ event, context }) => {
          const { output } = event as DoneActorEvent<
            AdminDataFetchEvent['data']
          >;
          const { permissions } = output;
          return (
            permissions?.data.can_view_physicians ||
            context.therapistListPermission
          );
        },
      }),
      saveSuccess: assign({
        successMessage: ({ event }) => {
          const { output } = event as DoneActorEvent<SuccessDataEvent['data']>;
          return output.message;
        },
      }),
      saveError: assign({
        error: ({ event }) => {
          const { error } = event as ErrorActorEvent<Error>;
          const actualError = axios.isAxiosError(error)
            ? error.response?.data
            : error;
          return actualError;
        },
      }),
      deleteError: assign({
        error: undefined,
      }),
      setTherapists: assign({
        selectedPartners: () => [],
        selectedClinics: () => [],
        selectedTherapists: ({ event, context }) => {
          const token = AppStore.getToken();
          if (
            (event as SelectedTherapistsEvent).ids.length === 0 &&
            typeof token !== 'undefined' &&
            token.length > 0
          ) {
            if (!context.groupName && context.data!.therapists.length > 1) {
              return [];
            }
            return context.data!.therapists.map((t) => ({
              value: t.id,
              label: t.name,
              prefix: t.prefix,
              hubspotId: t.hubspotId,
            }));
          }
          // Admin dashboard
          return (event as SelectedTherapistsEvent).ids;
        },
      }),
      setPartners: assign({
        selectedTherapists: () => [],
        selectedClinics: () => [],
        selectedPartners: ({ event, context }) => {
          const token = AppStore.getToken();
          // if there is not any partner selected and is normal dashboard
          if (
            (event as SelectedPartnersEvent).ids.length === 0 &&
            typeof token !== 'undefined' &&
            token.length > 0
          ) {
            // if not physician group and partners array has elements ignores it
            if (!context.groupName && context.data!.partners.length > 1) {
              return [];
            }
            return context.data!.partners.map((p) => ({
              value: p.id,
              label: p.name,
              prefix: '',
              hubspotId: '',
            }));
          }
          return (event as SelectedPartnersEvent).ids;
        },
      }),
      setClinics: assign({
        selectedTherapists: () => [],
        selectedPartners: () => [],
        selectedClinics: ({ event, context }) => {
          const token = AppStore.getToken();
          // if there is not any partner selected and is normal dashboard
          if (
            (event as SelectedClinicsEvent).ids.length === 0 &&
            typeof token !== 'undefined' &&
            token.length > 0
          ) {
            // if not physician group and clinics array has elements ignores it
            if (!context.groupName && context.data!.clinics.length > 1) {
              return [];
            }
            return context.data!.clinics.map((p) => ({
              value: p.id,
              label: p.name,
              prefix: '',
              hubspotId: '',
            }));
          }
          return (event as SelectedClinicsEvent).ids;
        },
      }),
      setTime: assign({
        time: ({ event }) => (event as SelectedTimeEvent).time,
      }),
      setDashboardIdAndEmail: assign({
        dashboardId: ({ event }) => {
          const { output } = event as DoneActorEvent<SessionData>;
          return output.dashboard_id;
        },
        providerEmail: ({ event }) => {
          const { output } = event as DoneActorEvent<SessionData>;
          return output.provider_email;
        },
      }),
    },
    actors: {
      fetchGlobalData: fromPromise<FetchedDataEvent['data'], { email: string }>(
        ({ input }) => this.fetch(input.email),
      ),
      fetchAdminData: fromPromise<AdminDataResponse>(() =>
        this.fetchAdminData(),
      ),
      sendNewAccessLink: fromPromise<SuccessDataEvent['data']>(() =>
        this.sendNewAccessLinkCall(),
      ),
      testAuth: fromPromise<SessionData>(() => this.fetchAuth()),
    },
    guards: {
      isAdmin: () => this.isAdmin(),
      isNotAdmin: () => !this.isAdmin(),
    },
  }).createMachine({
    id: 'App Machine',
    initial: 'testingAuth',
    context: {
      data: undefined,
      selectedTherapists: [],
      selectedPartners: [],
      selectedClinics: [],
      error: undefined,
      successMessage: undefined,
      partnerName: undefined,
      clinicName: undefined,
      clinicId: undefined,
      partnerId: undefined,
      chartPermission: undefined,
      signPermission: undefined,
      therapistListPermission: undefined,
      time: '90',
      dashboardId: '',
      providerEmail: '',
    },
    states: {
      testingAuth: {
        invoke: {
          id: 'testAuth',
          src: 'testAuth',
          onDone: [
            {
              target: 'fetching',
              actions: ['setDashboardIdAndEmail'],
              guard: 'isNotAdmin',
            },
            {
              target: 'success',
              actions: ['initAdminData'],
              guard: 'isAdmin',
            },
          ],
          onError: {
            target: 'failure',
            actions: ['saveError'],
          },
        },
      },
      sendingNewAccessLink: {
        invoke: {
          id: 'sendNewAccessLink',
          src: 'sendNewAccessLink',
          onDone: {
            target: 'newAccessLinkSent',
            actions: ['saveSuccess'],
          },
          onError: {
            target: 'failure',
            actions: ['saveError'],
          },
        },
      },
      newAccessLinkSent: {
        type: 'final',
      },
      fetching: {
        invoke: {
          id: 'fetchGlobalData',
          src: 'fetchGlobalData',
          input: ({ context }) => ({ email: context.providerEmail }),
          onDone: {
            target: 'success',
            actions: ['saveData', 'deleteError'],
          },
          onError: {
            target: 'failure',
            actions: ['saveError'],
          },
        },
        on: {
          TIME_SELECTED: {
            actions: ['setTime'],
          },
        },
      },
      success: {
        invoke: {
          id: 'fetchAdminData',
          src: 'fetchAdminData',
          onDone: {
            actions: ['saveAdminData'],
          },
          onError: {
            target: 'failure',
            actions: ['saveError'],
          },
        },
        on: {
          THERAPISTS_SELECTED: {
            actions: ['setTherapists'],
          },
          PARTNERS_SELECTED: {
            actions: ['setPartners'],
          },
          CLINICS_SELECTED: {
            actions: ['setClinics'],
          },
          TIME_SELECTED: {
            actions: ['setTime'],
          },
        },
      },
      failure: {
        on: {
          RETRY_EVENT: 'testingAuth',
          NEW_ACCESS_LINK_EVENT: 'sendingNewAccessLink',
        },
      },
    },
  });

  public start() {
    this.service.start();
  }

  private static getToken() {
    const { pathname } = window.location;
    const pathnameSplited = pathname.split('/');
    const tokenIdx = pathnameSplited.indexOf('dashboard') + 1;
    const token = pathnameSplited[tokenIdx];

    return token || '';
  }

  public isAdmin(): boolean {
    const token = AppStore.getToken();
    return typeof token === 'undefined' || !token.length || token.length === 0;
  }

  private static async getTherapistInfo(
    token: string,
    permission?: boolean,
  ): Promise<TherapistRawResponse & { err?: boolean }> {
    const params = new URLSearchParams();
    if (permission) {
      params.set('can_view_physicians', permission.toString());
    }
    try {
      const request = await api.get<TherapistRawResponse>(
        `/physicians?${params.toString()}`,
        { headers: { Authorization: `Token ${token}` } },
      );
      return request.data;
    } catch (err) {
      return {
        info: {
          data: [],
          group: {
            id: '',
            name: '',
            code: '',
          },
          partner: {
            id: '',
            name: '',
            code: '',
          },
          clinic: {
            id: '',
            name: '',
            code: '',
          },
        },
        err: true,
      };
    }
  }

  private static async getPhysicianInfo() {
    const request = await api.get<TherapistRawResponse>(`/physicians`);
    return request.data;
  }

  private static async getPartnersInfo() {
    const request = await api.get<PartnersResponse>(`/partners`);

    return request.data;
  }

  private static async getClinicsInfo() {
    const request = await api.get<ClinicResponse>(`/clinics`);

    return request.data;
  }

  private static async load(
    accessToken: string,
    providerEmail: string,
  ): Promise<GlobalDataResponse> {
    const token = this.getToken();
    const therapists = await this.getTherapistInfo(token);
    const { group, partner, data, clinic } = therapists.info;
    const params = new URLSearchParams();
    params.set('entity', 'physician');
    params.set('entity_name', data[0]?.name);
    const isGroupDashboard = !!(group?.id && group?.name);
    const isPartnerDashboard = !!(partner?.id && partner?.name);
    const isClinicDashboard = !!(clinic?.id && clinic?.name);
    if (isGroupDashboard) {
      params.set('entity', 'group');
      params.set('entity_name', group.name);
    } else if (isPartnerDashboard) {
      params.set('entity', 'partner');
      params.set('entity_name', partner.name);
    } else if (isClinicDashboard) {
      params.set('entity', 'clinic');
      params.set('entity_name', clinic.name);
    }

    const isPhysicianDashboard = !(
      isGroupDashboard ||
      isPartnerDashboard ||
      isClinicDashboard
    );

    // Ignore call to /permissions if dashboard is from a physician
    //
    const permissions: PermissionsResponse = isPhysicianDashboard
      ? {
          data: {
            name: '',
            can_sign_poc: isValidBooleanString(data[0].can_sign_patient_pocs),
            can_view_charts: isValidBooleanString(
              data[0].can_view_patient_charts,
            ),
            can_view_physicians: isValidBooleanString(
              data[0].can_view_assigned_physician,
            ),
          },
        }
      : (
          await api.get<PermissionsResponse>(
            `/permissions?${params.toString()}`,
          )
        ).data;

    if (permissions.data === null) {
      throw new Error("Dashboard's link is invalid");
    }
    const modifiedResponse: TherapistsResponse = {
      info: {
        data: therapists.info.data.map((t) => ({
          id: t.id,
          name: t.name || '',
          prefix: t.prefix || '',
          hubspot_id: t.hubspot_id,
        })),
        group: therapists.info.group,
        partner: therapists.info.partner,
        clinic: therapists.info.clinic, // this is needed for the dashboard title logic
        permissions,
      },
    };

    const partners: PartnersResponse = {
      info: {
        data: isPartnerDashboard
          ? [{ id: partner.id, name: partner.name, code: partner.code }]
          : [],
      },
    };
    // if this is a clinic dashboard then add manually the entry (if exists) to this response
    // so we can use it in the charts endpoints
    const clinics: ClinicResponse = {
      info: {
        data: isClinicDashboard
          ? [{ id: clinic.id, name: clinic.name, key: '', code: clinic.code }]
          : [],
      },
    };

    // Identify users in logrocket based on the type of dashboard

    if (isPhysicianDashboard) {
      LogRocket.identify(modifiedResponse.info.data[0].id, {
        name: modifiedResponse.info.data[0].name,
        email: providerEmail,
        type: 'Physicians',
      });
    }

    if (isGroupDashboard) {
      LogRocket.identify(modifiedResponse.info.group!.id, {
        name: modifiedResponse.info.group!.name,
        email: providerEmail,
        type: 'Physician Groups',
      });
    }

    if (isPartnerDashboard) {
      LogRocket.identify(modifiedResponse.info.partner!.id, {
        name: modifiedResponse.info.partner!.name,
        email: providerEmail,
        type: 'Partners',
      });
    }

    if (isClinicDashboard) {
      LogRocket.identify(modifiedResponse.info.clinic!.id, {
        name: modifiedResponse.info.clinic!.name,
        email: providerEmail,
        type: 'Clinics',
      });
    }

    return { therapists: modifiedResponse, partners, clinics };
  }

  private async fetch(
    providerEmail: string,
  ): Promise<FetchedDataEvent['data']> {
    const {
      token: accessToken,
      expires,
      refreshToken,
    } = await getAccessToken(AppStore.getToken());
    manager.accessToken = accessToken;
    manager.expires = expires;
    manager.refreshToken = refreshToken;
    const jsonResponse = await AppStore.load(accessToken, providerEmail);
    const therapists = this.therapistSerializer.deserialize(
      jsonResponse.therapists,
    );
    const partners = this.partnerSerializer.deserialize(jsonResponse.partners);
    const clinics = this.clinicSerializer.deserialize(jsonResponse.clinics);
    return {
      ...therapists,
      ...partners,
      ...clinics,
    };
  }

  // because this is an invoked service then we need to check if
  // this is an admin dashboard
  private async fetchAdminData() {
    if (!this.isAdmin()) {
      return {} as AdminDataResponse;
    }
    const adminPermissions: PermissionsResponse = {
      data: {
        can_sign_poc: true,
        can_view_charts: true,
        can_view_physicians: true,
        name: 'none',
      },
    };
    // Parallelize these requests
    const [partnersResponse, clinicsResponse, therapistsResponse] =
      await Promise.all([
        AppStore.getPartnersInfo(),
        AppStore.getClinicsInfo(),
        AppStore.getPhysicianInfo(),
      ]);

    const mappedTherapists = therapistsResponse.info.data.map((t) => ({
      id: t.id,
      name: t.name || '',
      prefix: t.prefix || '',
      hubspot_id: t.hubspot_id,
    }));

    const { partners } = this.partnerSerializer.deserialize(partnersResponse);
    const { clinics } = this.clinicSerializer.deserialize(clinicsResponse);
    const { therapists, permissions } = this.therapistSerializer.deserialize({
      info: {
        ...therapistsResponse.info,
        data: mappedTherapists,
        permissions: adminPermissions,
      },
    });
    return {
      clinics,
      partners,
      therapists,
      permissions,
    };
  }

  private async fetchAuth(): Promise<SessionData> {
    // Log Here
    const token = AppStore.getToken();
    if (typeof token === 'undefined' || !token.length || token.length === 0) {
      NetworkTracker.instance.addAdminEndpoints();
      return {
        dashboard_id: '',
        provider_email: '',
      };
    }
    const response = await api.post<SessionData>(
      `/sessions`,
      {},
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      },
    );
    LogRocket.track('CustomProperties', {
      dashboardId: response.data.dashboard_id,
    });
    NetworkTracker.instance.addExternalEndpoints();
    return response.data;
  }

  private async sendNewAccessLinkCall(): Promise<SuccessDataEvent['data']> {
    const token = AppStore.getToken();

    const response = await api.post<SuccessDataEvent['data']>(
      `/sessions/refresh`,
      {},
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      },
    );
    return response.data;
  }

  @computed
  public get therapists(): Array<Therapist> | undefined {
    if (
      typeof this.current === 'undefined' ||
      typeof this.current.context.data === 'undefined'
    ) {
      return undefined;
    }

    return this.current.context.data.therapists as Array<Therapist>;
  }

  @computed
  public get partners(): Array<Partner> | undefined {
    if (
      typeof this.current === 'undefined' ||
      typeof this.current.context.data === 'undefined'
    ) {
      return undefined;
    }

    return this.current.context.data.partners as Array<Partner>;
  }

  @computed
  public get clinics(): Array<Clinic> | undefined {
    if (
      typeof this.current === 'undefined' ||
      typeof this.current.context.data === 'undefined'
    ) {
      return undefined;
    }

    return this.current.context.data.clinics as Array<Clinic>;
  }

  @computed
  public get groupName(): string | undefined {
    if (
      typeof this.current === 'undefined' ||
      typeof this.current.context.data === 'undefined'
    ) {
      return undefined;
    }

    return this.current.context.groupName;
  }

  @computed
  public get groupId(): string | undefined {
    if (
      typeof this.current === 'undefined' ||
      typeof this.current.context.data === 'undefined'
    ) {
      return undefined;
    }

    return this.current.context.groupId;
  }

  @computed
  public get time(): string | undefined {
    if (
      typeof this.current === 'undefined' ||
      typeof this.current.context.data === 'undefined'
    ) {
      return undefined;
    }

    return this.current.context.time;
  }

  @computed.struct
  public get selectedTherapists(): Array<SelectOption<string>> | undefined {
    if (typeof this.current === 'undefined') {
      return undefined;
    }

    return this.current.context.selectedTherapists;
  }

  @computed.struct
  public get selectedPartners(): Array<SelectOption<string>> | undefined {
    if (typeof this.current === 'undefined') {
      return undefined;
    }

    return this.current.context.selectedPartners;
  }

  @computed.struct
  public get selectedClinics(): Array<SelectOption<string>> | undefined {
    if (typeof this.current === 'undefined') {
      return undefined;
    }

    return this.current.context.selectedClinics;
  }

  @computed
  public get partnerName(): string | undefined {
    if (typeof this.current === 'undefined') {
      return undefined;
    }

    return this.current.context.partnerName;
  }

  @computed
  public get clinicName(): string | undefined {
    if (typeof this.current === 'undefined') {
      return undefined;
    }

    return this.current.context.clinicName;
  }

  @computed
  public get clinicId(): string | undefined {
    if (typeof this.current === 'undefined') {
      return undefined;
    }

    return this.current.context.clinicId;
  }

  @computed
  public get partnerId(): string | undefined {
    if (typeof this.current === 'undefined') {
      return undefined;
    }

    return this.current.context.partnerId;
  }

  @computed.struct
  public get chartPermission(): boolean | undefined {
    return this.current.context.chartPermission;
  }

  @computed.struct
  public get signPermission(): boolean | undefined {
    return this.current.context.signPermission;
  }

  @computed.struct
  public get therapistListPermission(): boolean | undefined {
    return this.current.context.therapistListPermission;
  }

  @computed
  public get selectedTherapistsLength(): number {
    if (typeof this.current === 'undefined') {
      return 0;
    }

    return this.current.context.selectedTherapists.length;
  }

  public setTherapists(ids: Array<SelectOption<string>>): void {
    this.service.send({ type: 'THERAPISTS_SELECTED', ids: ids || [] });
  }

  public setPartners(ids: Array<SelectOption<string>>): void {
    this.service.send({ type: 'PARTNERS_SELECTED', ids: ids || [] });
  }

  public setClinics(ids: Array<SelectOption<string>>): void {
    this.service.send({ type: 'CLINICS_SELECTED', ids: ids || [] });
  }

  public setTime(time: string): void {
    this.service.send({ type: 'TIME_SELECTED', time });
  }

  @computed
  public get fetching(): boolean {
    if (typeof this.current === 'undefined') {
      return false;
    }
    return this.current.matches('fetching');
  }

  @computed
  public get fetched(): boolean {
    if (typeof this.current === 'undefined') {
      return false;
    }
    return this.current.matches('success');
  }

  @computed
  public get failure(): boolean {
    if (typeof this.current === 'undefined') {
      return false;
    }

    return this.current.matches('failure');
  }

  @computed
  public get newAccessLinkSent(): boolean {
    if (typeof this.current === 'undefined') {
      return false;
    }

    return this.current.matches('newAccessLinkSent');
  }

  @computed
  public get sendingNewAccessLink(): boolean {
    if (typeof this.current === 'undefined') {
      return false;
    }

    return this.current.matches('sendingNewAccessLink');
  }

  public refetch(): void {
    this.service.send({ type: 'RETRY_EVENT' });
  }

  public sendNewAccessLink(): void {
    this.service.send({ type: 'NEW_ACCESS_LINK_EVENT' });
  }

  @computed
  public get error(): Error | undefined {
    if (typeof this.current === 'undefined') {
      return undefined;
    }

    if (process.env.REACT_APP_SENTRY_DSN_FRONTEND) {
      Sentry.withScope((scope) => {
        Object.keys(this.current).forEach((key) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          scope.setExtra(key, this.current[key]);
        });
        Sentry.captureException(this.current.context.error);
      });
    }
    return this.current.context.error;
  }

  @computed
  public get successMessage(): string | undefined {
    if (typeof this.current === 'undefined') {
      return undefined;
    }

    return this.current.context.successMessage;
  }

  @computed
  public get providerKind(): ProviderKind {
    if (this.isAdmin() || typeof this.current === 'undefined') {
      return '';
    }
    if (this.partnerId && this.partnerName) {
      return 'partner';
    }

    if (this.groupId && this.groupName) {
      return 'physician_group';
    }

    if (this.clinicId && this.clinicName) {
      return 'clinic';
    }

    return 'physician';
  }

  @computed
  public get dashboardId(): string | undefined {
    if (typeof this.current === 'undefined') {
      return undefined;
    }

    return this.current.context.dashboardId;
  }

  @computed
  public get providerEmail(): string | undefined {
    if (typeof this.current === 'undefined') {
      return '';
    }

    return this.current.context.providerEmail;
  }
}
