/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { SortOrder } from 'types';

interface SortConfig {
  list: Array<any>;
  column: string;
  deepColumn?: string | null;
  actualOrder: string;
  specificOrder?: Array<string> | null;
  extractFunction: (v: any) => number | string;
}

interface SortResponse {
  orderedList: Array<any>;
  newOrder: SortOrder;
}

export function sortColumnsTable(config: SortConfig): SortResponse {
  const {
    list,
    column,
    deepColumn,
    actualOrder,
    specificOrder,
    extractFunction,
  } = config;

  const newOrderedList = [...list];
  let nextOrder: SortOrder;
  let valueA: string;
  let valueB: string;

  if (specificOrder) {
    if (actualOrder === 'original') {
      nextOrder = 'ordered';
      newOrderedList.sort((a, b) => {
        valueA = deepColumn
          ? a[column]
            ? a[column][deepColumn]
            : '-1'
          : a[column];
        valueB = deepColumn
          ? b[column]
            ? b[column][deepColumn]
            : '-1'
          : b[column];
        if (extractFunction(valueA) > extractFunction(valueB)) {
          return 1;
        }
        return -1;
      });
    } else {
      nextOrder = 'original';
    }
  } else if (actualOrder === 'original') {
    nextOrder = 'decrescent';
    newOrderedList.sort((a, b) => {
      valueA = deepColumn
        ? a[column]
          ? a[column][deepColumn]
          : '-1'
        : a[column];
      valueB = deepColumn
        ? b[column]
          ? b[column][deepColumn]
          : '-1'
        : b[column];
      /*  valueA = deepColumn ? a[column][deepColumn] : a[column];
        valueB = deepColumn ? b[column][deepColumn] : b[column]; */
      if (extractFunction(valueA) < extractFunction(valueB)) return 1;
      if (extractFunction(valueA) > extractFunction(valueB)) return -1;
      return 0;
    });
  } else if (actualOrder === 'decrescent') {
    nextOrder = 'increscent';
    newOrderedList.sort((a, b) => {
      valueA = deepColumn
        ? a[column]
          ? a[column][deepColumn]
          : '-1'
        : a[column];
      valueB = deepColumn
        ? b[column]
          ? b[column][deepColumn]
          : '-1'
        : b[column];
      if (extractFunction(valueA) > extractFunction(valueB)) return 1;
      if (extractFunction(valueA) < extractFunction(valueB)) return -1;
      return 0;
    });
  } else {
    nextOrder = 'original';
  }

  return {
    orderedList: newOrderedList,
    newOrder: nextOrder,
  };
}
