import React from 'react';

import { VisitsByInjurySerializer } from '../serializer';

import { VisitsByInjuryTypeStore } from './visits-by-injury-type.store';

export const visitsByInjuryTypeStore = new VisitsByInjuryTypeStore(
  new VisitsByInjurySerializer(),
);

export const VisitsByInjuryAdapter = React.createContext(
  visitsByInjuryTypeStore,
);
