import { createActor } from 'xstate';

import { BaseChartStore } from 'features/base-chart-store';
import { SelectOption, CaseDistributionResponse } from 'types';

import { CaseDistribution } from '../case-distribution.model';
import { CaseDistributionSerializer } from '../serializer';

export class CaseDistributionStore extends BaseChartStore<
  CaseDistribution,
  CaseDistributionResponse
> {
  constructor(private serializer: CaseDistributionSerializer) {
    super('Case Distribution Machine');
    // extend base chart machine if necessary
    this.service = createActor(this.machine);
    this.service.subscribe((state) => {
      this.current = state;
    });
    this.service.start();
  }

  protected async doFetch(
    therapists: Array<SelectOption<string>>,
    partnerId: string | undefined,
    partners: Array<SelectOption<string>>,
    clinics: Array<SelectOption<string>>,
    time: string,
    signal?: AbortSignal,
  ) {
    const jsonResponse = await this.load(
      'case_distribution',
      {
        therapists,
        partnerId,
        partners,
        clinics,
        time,
      },
      signal,
    );

    return this.serializer.deserialize(jsonResponse);
  }
}
