import { VisitsByInjuryType } from '../visits-by-injury-type.model';

export const COLORS: Array<{
  backgroundColor: string;
  hoverBackgroundColor: string;
}> = [
  {
    // Primary
    backgroundColor: '#974EBC',
    hoverBackgroundColor: '#B168D6',
  },
  {
    // Teal
    backgroundColor: '#2FACBF',
    hoverBackgroundColor: '#4EC2D3',
  },
  {
    // Yellow
    backgroundColor: '#FFCB66',
    hoverBackgroundColor: '#FFE580',
  },
];

export interface VisitsByInjuryTypeCategory {
  label: string;
  color: string;
}

interface VisitsByInjuryTypeDataset {
  data: Array<number>;
  backgroundColor: string;
  hoverBackgroundColor: string;
  barThickness: number;
}

export class VisitsByInjuryTypeViewModel {
  constructor(private readonly model: VisitsByInjuryType) {}

  public get labels() {
    return this.model.labels;
  }

  public get datasets(): Array<VisitsByInjuryTypeDataset> {
    // each data set
    return this.model.data.map((dataset, idx) => ({
      barThickness: 24,
      ...COLORS[this.model.data.length - (idx + 1)],
      data: dataset,
      label: idx.toString(),
    }));
  }

  public get data() {
    return this.model.rawData;
  }

  public get categories(): Array<VisitsByInjuryTypeCategory> {
    return ['>10 visits', '5-10 visits', '<5 visits'].map((label, idx) => ({
      label,
      color: COLORS[idx].backgroundColor,
    }));
  }

  public getOrderedData(
    order: string[],
  ): [string[], VisitsByInjuryTypeDataset[]] {
    // match this label's order the same as the case distribution
    const filteredLabels = this.labels.filter((v) => v !== 'Total');
    let matchedLabels = filteredLabels
      .slice()
      .sort((a, b) => order.indexOf(a) - order.indexOf(b));
    // old index - new index
    const newIndexes = filteredLabels.map((v) => matchedLabels.indexOf(v));
    const orderedDataset = this.datasets.map((d) => ({
      ...d,
      data: newIndexes.map((_, i) => d.data[newIndexes.indexOf(i)]),
    }));

    /* //Order averages
    this.model.averages = this.averages.map((_, i) =>
      i === newIndexes.length ? this.averages[i] : this.averages[newIndexes.indexOf(i)]
    );
    //Order visits
    this.model.visits = this.visits.map((_, i) =>
      i === newIndexes.length ? this.visits[i] : this.visits[newIndexes.indexOf(i)]
    ); */

    orderedDataset.forEach((d, i) => {
      const { length } = this.datasets[i].data;
      d.data.push(this.datasets[i].data[length - 1]);
    });
    matchedLabels = [...matchedLabels, 'Total'];
    return [matchedLabels, orderedDataset];
  }
}
