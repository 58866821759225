// better readability
export function computeInjuryName(injury?: string) {
  if (typeof injury === 'undefined' || injury === '') {
    return '';
  }
  const trimmedText = injury?.trim().toLowerCase() || '';
  if (trimmedText.includes('joint replacement')) {
    return injury.replace(/Joint Replacement/gi, 'JR');
  }
  if (trimmedText === 'other') {
    return 'Neuro/Geriatrics';
  }
  return injury;
}
