export interface CaseDistributionInstanceOptions {
  labels: Array<string>;
  data: Array<number>;
  visits: Array<number>;
}

export class CaseDistribution {
  public labels: CaseDistributionInstanceOptions['labels'];

  public data: CaseDistributionInstanceOptions['data'];

  public visits: CaseDistributionInstanceOptions['visits'];

  constructor(opts: CaseDistributionInstanceOptions) {
    this.labels = opts.labels;
    this.data = opts.data;
    this.visits = opts.visits;
  }
}
