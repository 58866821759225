import { computed, observable } from 'mobx';
import {
  ActorRefFrom,
  DoneActorEvent,
  ErrorActorEvent,
  SnapshotFrom,
  assign,
  createActor,
  fromPromise,
  setup,
} from 'xstate';

import api from 'lib/api';
import {
  SelectOption,
  ChartsFetchParams,
  DowngradedPatientsResponse,
  ChartLoadParams,
} from 'types';

import { DowngradedPatientRow } from '../downgraded-patient-row.model';
import { DowngradedPatient } from '../downgraded-patient.model';
import { DowngradedPatientsSerializer } from '../serializer';

interface MachineContext {
  data?: DowngradedPatient;
  error?: Error;
  filteredPatients: Array<DowngradedPatientRow>;
  filteredStatus: string[];
  filteredPatient: string;
  filteredPhysicians: Array<string>;
  selectedTherapists: Array<SelectOption<string>>;
  selectedPartners: Array<SelectOption<string>>;
  selectedClinics: Array<SelectOption<string>>;
  partnerId: string | undefined;
  permission: boolean;
  groupName: string | undefined;
  time: string;
}

interface SelectedTherapistsEvent {
  type: 'THERAPISTS_SELECTED';
  ids: Array<SelectOption<string>>;
  partnerId: string | undefined;
  permission: boolean;
  time: string;
}

interface SelectedPartnersEvent {
  type: 'PARTNERS_SELECTED';
  selected: Array<SelectOption<string>>;
}

interface SelectedClinicsEvent {
  type: 'CLINICS_SELECTED';
  selected: Array<SelectOption<string>>;
}

interface SetFilteredPatients {
  type: 'SET_PATIENT_FILTER';
  name: string;
}

interface SetGroupNameEvent {
  type: 'SET_GROUP_NAME';
  name: string;
}
interface SetFilteredPhysicians {
  type: 'SET_FILTERED_PHYSICIANS';
  data: Array<string>;
}

interface SetFilteredStatus {
  type: 'SET_FILTERED_STATUS';
  data: string[];
}

interface ResetFilteredPatients {
  type: 'RESET_PATIENT_FILTER';
}

interface RetryEvent {
  type: 'RETRY_EVENT';
}

type MachineEvent =
  | DoneActorEvent<DowngradedPatient>
  | ErrorActorEvent<Error>
  | SelectedTherapistsEvent
  | SelectedPartnersEvent
  | SelectedClinicsEvent
  | SetGroupNameEvent
  | SetFilteredPhysicians
  | SetFilteredStatus
  | SetFilteredPatients
  | ResetFilteredPatients
  | RetryEvent;

export class DownGradedPatientsStore {
  @observable
  protected current!: SnapshotFrom<typeof this.machine>;

  protected service!: ActorRefFrom<typeof this.machine>;

  constructor(private serializer: DowngradedPatientsSerializer) {
    this.service = createActor(this.machine);
    this.service.subscribe((state) => {
      this.current = state;
    });
    this.service.start();
  }

  private token = '';

  private machine = setup({
    types: { context: {} as MachineContext, events: {} as MachineEvent },
    actions: {
      saveData: assign({
        data: ({ event }) => {
          const { output } = event as DoneActorEvent<DowngradedPatient>;
          return output;
        },
        filteredPatients: ({ event }) => {
          const { output } = event as DoneActorEvent<DowngradedPatient>;
          return output.rows;
        },
      }),
      saveError: assign({
        error: ({ event }) => {
          const { error } = event as ErrorActorEvent<Error>;

          return error;
        },
      }),
      deleteError: assign({
        error: undefined,
      }),
      setTherapists: assign({
        selectedTherapists: ({ event }) =>
          (event as SelectedTherapistsEvent).ids,
        partnerId: ({ event }) => (event as SelectedTherapistsEvent).partnerId,
        permission: ({ event }) =>
          (event as SelectedTherapistsEvent).permission,
        time: ({ event }) => (event as SelectedTherapistsEvent).time,
      }),
      setPartners: assign({
        selectedPartners: ({ event }) =>
          (event as SelectedPartnersEvent).selected,
      }),
      setClinics: assign({
        selectedClinics: ({ event }) =>
          (event as SelectedClinicsEvent).selected,
      }),
      setGroupName: assign({
        groupName: ({ event }) => (event as SetGroupNameEvent).name,
      }),
      setFilteredStatus: assign({
        filteredStatus: ({ event }) => (event as SetFilteredStatus).data,
      }),
      setFilteredPatient: assign({
        filteredPatient: ({ event }) => {
          const { name } = event as SetFilteredPatients;
          return name;
        },
      }),
      setFilteredPhysicians: assign({
        filteredPhysicians: ({ event }) =>
          (event as SetFilteredPhysicians).data,
      }),
      filterPatients: assign({
        filteredPatients: ({ context }) => {
          const filteredRows =
            context.filteredStatus.length === 0
              ? context.data!.rows
              : context.data!.rows.filter((p) =>
                  context.filteredStatus.includes(p.reasonNotBooked),
                );
          // Override patient reason not booked filter
          const searchTerm = context.filteredPatient;
          if (searchTerm) {
            return context.data!.rows.filter((p) =>
              p.name.toLowerCase().includes(searchTerm.toLowerCase()),
            );
          }
          return filteredRows;
        },
      }),
      resetPatientFilter: assign({
        filteredStatus: () => ['noop'],
        filteredPatient: () => '',
        filteredPatients: () => [],
      }),
      resetStatusFilter: assign({
        filteredStatus: () => [],
        filteredPatients: () => [],
      }),
    },
    actors: {
      fetchDowngradedPatientModel: fromPromise<
        DowngradedPatient,
        ChartLoadParams
      >(({ input, signal }) =>
        this.doFetch(
          input.therapists,
          input.partnerId,
          input.partners,
          input.clinics,
          input.time,
          signal,
        ),
      ),
    },
    guards: {
      shouldFetch: ({ event }) => {
        const { time } = event as unknown as ChartsFetchParams;
        return time === '90';
      },
    },
  }).createMachine({
    id: 'Downgraded Patients Machine',
    initial: 'initial',
    context: {
      data: undefined,
      error: undefined,
      filteredStatus: ['noop'],
      filteredPatient: '',
      filteredPatients: [],
      filteredPhysicians: [],
      selectedTherapists: [],
      selectedPartners: [],
      selectedClinics: [],
      partnerId: undefined,
      permission: false,
      groupName: undefined,
      time: '90',
    },
    states: {
      initial: {
        on: {
          THERAPISTS_SELECTED: {
            target: 'fetching',
            actions: ['setTherapists'],
            guard: 'shouldFetch',
          },
          PARTNERS_SELECTED: { actions: ['setPartners'] },
          CLINICS_SELECTED: { actions: ['setClinics'] },
          SET_GROUP_NAME: { actions: ['setGroupName'] },
          RESET_PATIENT_FILTER: { actions: ['resetPatientFilter'] },
        },
      },
      fetching: {
        on: {
          THERAPISTS_SELECTED: {
            reenter: true,
            target: 'fetching',
            actions: ['setTherapists'],
            guard: 'shouldFetch',
          },
          PARTNERS_SELECTED: { actions: ['setPartners'] },
          CLINICS_SELECTED: { actions: ['setClinics'] },
          SET_GROUP_NAME: { actions: ['setGroupName'] },
          RESET_PATIENT_FILTER: { actions: ['resetPatientFilter'] },
        },
        invoke: {
          id: 'fetchDowngradedPatientModel',
          src: 'fetchDowngradedPatientModel',
          input: ({ context, event }) => ({
            therapists:
              (event as SelectedTherapistsEvent).ids ||
              context.selectedTherapists,
            partnerId:
              (event as SelectedTherapistsEvent).partnerId || context.partnerId,
            partners: context.selectedPartners,
            clinics: context.selectedClinics,
            time: (event as SelectedTherapistsEvent).time || context.time,
          }),
          onDone: {
            target: 'success',
            actions: ['saveData', 'deleteError'],
          },
          onError: {
            target: 'failure',
            actions: ['saveError'],
          },
        },
      },
      success: {
        on: {
          THERAPISTS_SELECTED: {
            target: 'fetching',
            actions: ['setTherapists'],
            guard: 'shouldFetch',
          },
          PARTNERS_SELECTED: { actions: ['setPartners'] },
          CLINICS_SELECTED: { actions: ['setClinics'] },
          SET_GROUP_NAME: { actions: ['setGroupName'] },
          SET_FILTERED_PHYSICIANS: {
            actions: ['setFilteredPhysicians', 'filterPatients'],
          },
          SET_FILTERED_STATUS: {
            actions: ['setFilteredStatus', 'filterPatients'],
          },
          SET_PATIENT_FILTER: {
            actions: [
              'resetStatusFilter',
              'setFilteredPatient',
              'filterPatients',
            ],
          },
          RESET_PATIENT_FILTER: { actions: ['resetPatientFilter'] },
        },
      },
      failure: {
        on: {
          RETRY_EVENT: 'fetching',
          THERAPISTS_SELECTED: {
            target: 'fetching',
            actions: ['setTherapists'],
            guard: 'shouldFetch',
          },
          PARTNERS_SELECTED: { actions: ['setPartners'] },
          CLINICS_SELECTED: { actions: ['setClinics'] },
          SET_GROUP_NAME: { actions: ['setGroupName'] },
          RESET_PATIENT_FILTER: { actions: ['resetPatientFilter'] },
        },
      },
    },
  });

  private async load(
    therapists: Array<SelectOption<string>>,
    partnerId: string | undefined,
    partners: Array<SelectOption<string>>,
    clinics: Array<SelectOption<string>>,
    time: string,
    signal?: AbortSignal,
  ): Promise<DowngradedPatientsResponse> {
    const params = new URLSearchParams();
    params.set('from', time);
    let doctors: string[] = [];

    if (therapists.length === 0) {
      if (partnerId) {
        params.set('partner', partnerId);
      }
      if (!partnerId && partners.length === 0 && clinics.length === 0) {
        return Promise.resolve({
          data: {
            table: [],
            charts: {
              data: {},
              total_patients: 0,
            },
          },
        });
      }
    } else {
      // Remove null ids
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      doctors = therapists.map(({ hubspotId }) => hubspotId).filter(Boolean);
    }
    const request = await api.post<DowngradedPatientsResponse>(
      `/unseen_patients?${params.toString()}`,
      {
        data: {
          physician_hubspot_ids: doctors,
          practices_codes: partners.map((p) => p.code),
          clinics_codes: clinics.map((p) => p.code),
        },
      },
      { signal },
    );
    return request.data;
  }

  public setPartners(selectedPartners: Array<SelectOption<string>>) {
    this.service.send({
      type: 'PARTNERS_SELECTED',
      selected: selectedPartners,
    });
  }

  public setClinics(selectedClinics: Array<SelectOption<string>>) {
    this.service.send({ type: 'CLINICS_SELECTED', selected: selectedClinics });
  }

  public setGroupName(groupName: string) {
    this.service.send({ type: 'SET_GROUP_NAME', name: groupName });
  }

  public fetch(params: ChartsFetchParams): void {
    const { therapists, partnerId, permission, time } = params;
    this.service.send({
      type: 'THERAPISTS_SELECTED',
      ids: therapists,
      partnerId,
      permission: !!permission,
      time,
    });
  }

  public setFilteredPhysicians(ids: string[]) {
    this.service.send({ type: 'SET_FILTERED_PHYSICIANS', data: ids });
  }

  public setFilteredStatus(reasons: string[]) {
    this.service.send({ type: 'RESET_PATIENT_FILTER' });
    this.service.send({ type: 'SET_FILTERED_STATUS', data: reasons });
  }

  public setFilteredPatients(name: string) {
    this.service.send({ type: 'SET_PATIENT_FILTER', name });
  }

  public resetFilteredPatients() {
    this.service.send({ type: 'RESET_PATIENT_FILTER' });
  }

  @computed get searchTerm() {
    return this.current.context.filteredPatient;
  }

  private async doFetch(
    therapists: Array<SelectOption<string>>,
    partnerId: string | undefined,
    partners: Array<SelectOption<string>>,
    clinics: Array<SelectOption<string>>,
    time: string,
    signal?: AbortSignal,
  ) {
    const jsonResponse = await this.load(
      therapists,
      partnerId,
      partners,
      clinics,
      time,
      signal,
    );

    return this.serializer.deserialize(jsonResponse);
  }

  @computed
  public get data() {
    if (typeof this.current === 'undefined') {
      return undefined;
    }

    return this.current.context.data;
  }

  @computed
  public get cache() {
    if (typeof this.current === 'undefined') {
      return undefined;
    }

    return this.current.context.data?.rows || [];
  }

  @computed
  public get rows(): Array<DowngradedPatientRow> | undefined {
    if (typeof this.current === 'undefined') {
      return undefined;
    }
    return this.current.context.filteredPatients;
  }

  @computed
  public get isEmpty() {
    if (typeof this.current === 'undefined') {
      return false;
    }
    return this.current.context.data!.rows.length === 0;
  }

  @computed get dataset() {
    if (typeof this.current === 'undefined') {
      return undefined;
    }
    return this.current.context.data!.data;
  }

  @computed
  public get fetching(): boolean {
    if (typeof this.current === 'undefined') {
      return false;
    }

    return this.current.matches('fetching');
  }

  @computed
  public get fetched(): boolean {
    if (typeof this.current === 'undefined') {
      return false;
    }

    return this.current.matches('success');
  }

  @computed
  public get failure(): boolean {
    if (typeof this.current === 'undefined') {
      return false;
    }

    return this.current.matches('failure');
  }

  @computed
  public get filterStatus() {
    if (typeof this.current === 'undefined') {
      return [];
    }
    return this.current.context.filteredStatus;
  }

  public refetch(): void {
    this.service.send({ type: 'RETRY_EVENT' });
  }

  public setToken(token: string) {
    this.token = token;
  }

  public isTokenSet() {
    return typeof this.token !== 'undefined' && this.token.length > 0;
  }

  @computed
  public get time(): string | undefined {
    if (typeof this.current === 'undefined') {
      return undefined;
    }
    return this.current.context.time;
  }

  @computed
  public get error(): Error | undefined {
    if (typeof this.current === 'undefined') {
      return undefined;
    }

    return this.current.context.error;
  }
}
