/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { format } from 'date-fns';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { VirtualItem, useVirtual } from 'react-virtual';

import { ConditionalComponent } from 'components/ConditionalComponent';
import {
  OrderIconOriginal,
  OrderIconSortDown,
  OrderIconSortUp,
} from 'components/Icons';
import { Typography } from 'components/Typography';
import useMediaQuery from 'hooks/useMediaQuery';
import { sortColumnsTable } from 'utils';

import { DowngradedPatientsStoreAdapter } from '../store';
import { SuccessProps } from '../types';

import { hubspotMapping } from './utils';

const INITIAL_ORDER = {
  dob: 'original',
  dateOfReferral: 'original',
  reasonNotBooked: 'original',
};

const TABLE_BODY_ROW_HEIGHT = 56;

interface IconProps {
  order: string;
}

const OrderIcon: React.FC<IconProps> = function OrderIcon({ order }) {
  if (order === 'original') {
    return <OrderIconOriginal />;
  }
  if (order === 'decrescent' || order === 'ordered') {
    return <OrderIconSortDown />;
  }

  return <OrderIconSortUp />;
};

function EmptyBody() {
  return (
    <tr>
      <td colSpan={4} style={{ textAlign: 'center', paddingTop: '1em' }}>
        <Typography variant="heading3">No Results</Typography>
        <Typography variant="parragraph" color="gray-chateau" component="p">
          There are no patients matching your search or filter criteria.
        </Typography>
      </td>
    </tr>
  );
}

export function DowngradedPatients({ model }: SuccessProps) {
  const [orderedModel, setOrderedModel] = React.useState([...model]);
  const [order, setOrder] = React.useState(INITIAL_ORDER);
  const [maxHeight, setMaxHeight] = useState(-1);

  const store = useContext(DowngradedPatientsStoreAdapter);

  function onSearchUpdated(e: ChangeEvent<HTMLInputElement>) {
    store.setFilteredPatients(e.target.value);
  }

  const matchesMobileQuery = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    setOrderedModel(model);
    setOrder(INITIAL_ORDER);
  }, [model]);

  const getDateValue = (d: string) => new Date(d).getTime();
  const getExactValue = (d: string) => d;
  const getLabelFromReason = (r: string) => hubspotMapping[r];

  const GET_SORT_VALUE = {
    dob: getDateValue,
    dateOfReferral: getDateValue,
    reasonNotBooked: getLabelFromReason,
  };

  const handleOrder = (column: string) => {
    const { orderedList, newOrder } = sortColumnsTable({
      list: [...model],
      column,
      deepColumn: null,
      // @ts-ignore
      actualOrder: order[column],
      specificOrder: null,
      // @ts-ignore
      extractFunction: GET_SORT_VALUE[column] || getExactValue,
    });
    setOrderedModel([...orderedList]);
    setOrder({ ...INITIAL_ORDER, [column]: newOrder });
  };

  const renderTableRow = (v: VirtualItem) => {
    const patient = orderedModel[v.index];
    return (
      <tr
        key={patient.key}
        className="recent-patients__table-row"
        data-hubspot-id={patient.hubspotId}
      >
        <td>
          <Typography
            variant="caption"
            color="abbey"
            style={{ display: 'inline-block', verticalAlign: 'middle' }}
          >
            {patient.name}
          </Typography>
        </td>
        <td>
          <Typography
            variant="caption"
            component="p"
            color="abbey"
            className=""
          >
            {patient.dob
              ? format(new Date(`${patient.dob}T00:00:00`), 'MM/dd/yyyy')
              : ''}
          </Typography>
        </td>
        <td>
          <Typography
            variant="caption"
            component="p"
            color="abbey"
            className=""
          >
            {format(new Date(patient.dateOfReferral), 'MM/dd/yyyy')}
          </Typography>
        </td>
        <td>
          <Typography
            variant="caption"
            component="p"
            color="abbey"
            className=""
          >
            {hubspotMapping[patient.reasonNotBooked] || ''}
          </Typography>
        </td>
      </tr>
    );
  };

  // The parent element whose inner-content is scrollable
  const tableContainerRef = React.useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: orderedModel.length,
    overscan: 10,
    estimateSize: React.useCallback(() => TABLE_BODY_ROW_HEIGHT, []),
  });

  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
      : 0;

  const isEmpty = virtualRows.length === 0;

  useEffect(() => {
    if (tableContainerRef.current) {
      setMaxHeight(tableContainerRef.current.clientHeight);
    }
  }, [tableContainerRef]);

  return (
    <div
      className="recent-patients__table-wrapper downgraded-patients__table-wrapper"
      ref={tableContainerRef}
      style={{ height: maxHeight > 0 ? Math.max(maxHeight, 152) : 'inherit' }}
    >
      <table
        className="recent-patients__table"
        style={{ height: isEmpty ? '100%' : 'auto' }}
      >
        <thead className="recent-patients__table-header">
          <tr>
            <th>
              <div className="recent-patients__table-header-patient header-with-filter">
                <Typography variant="caption" color="darkness" component="p">
                  Patient
                </Typography>
                <ConditionalComponent validate={!matchesMobileQuery}>
                  <input
                    className="user-input"
                    placeholder="Search for Patient"
                    value={store.searchTerm}
                    onChange={onSearchUpdated}
                  />
                </ConditionalComponent>
              </div>
            </th>
            <th onClick={() => handleOrder('dob')}>
              <div className="recent-patients__table-head">
                <Typography variant="caption" color="darkness" component="p">
                  Date of Birth
                </Typography>
                <OrderIcon order={order.dob} />
              </div>
            </th>
            <th onClick={() => handleOrder('dateOfReferral')}>
              <div className="recent-patients__table-head">
                <Typography variant="caption" color="darkness" component="p">
                  Date of Referral
                </Typography>
                <OrderIcon order={order.dateOfReferral} />
              </div>
            </th>
            <th onClick={() => handleOrder('reasonNotBooked')}>
              <div className="recent-patients__table-head">
                <Typography variant="caption" color="darkness" component="p">
                  Reason Not Booked
                </Typography>
                <OrderIcon order={order.reasonNotBooked} />
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="recent-patients__table-body">
          {paddingTop > 0 && (
            <tr>
              <td style={{ height: `${paddingTop}px` }} />
            </tr>
          )}
          {isEmpty ? <EmptyBody /> : virtualRows.map((v) => renderTableRow(v))}
          {paddingBottom > 0 && (
            <tr>
              <td style={{ height: `${paddingBottom}px` }} />
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
