import { SelectOption } from 'types';

import { ChangeInPainLevelStore } from '../store';

export class ChangeInPainLevelViewModel {
  constructor(private readonly store: ChangeInPainLevelStore) {}

  public get labels() {
    return this.store.cache!.data.map((_, idx) => {
      const sumThese = this.store.cache!.data.filter((__, idx_) => idx >= idx_);

      return parseFloat(
        sumThese.reduce((acc, current) => acc + current, 0).toFixed(1),
      );
    });
  }

  public get counter() {
    return Math.round(this.store.cache!.counter * 100) / 100;
  }

  public get sessionFilterOptions(): Array<SelectOption> {
    return [
      { value: 5, label: '5 sessions', selected: false, hubspotId: '' },
      { value: 10, label: '10 sessions', selected: false, hubspotId: '' },
    ];
  }

  public get sessionCount(): SelectOption {
    return this.sessionFilterOptions.find(
      (option) => option.value === this.store.sessionsCount,
    )!;
  }
}
