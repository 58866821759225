/* eslint-disable @typescript-eslint/no-explicit-any */
export function arrayObjectSort(property: string, order: 'asc' | 'desc') {
  let sortOrder = 1;
  if (order === 'desc') {
    sortOrder = -1;
  }

  return function sort(a: any, b: any) {
    if (a[property] < b[property]) {
      return -1 * sortOrder;
    }
    if (a[property] > b[property]) {
      return 1 * sortOrder;
    }
    return 0 * sortOrder;
  };
}
