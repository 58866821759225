import { useMachine } from '@xstate/react';
import { useCallback, useState } from 'react';
import { setup } from 'xstate';

type MachineEvent = {
  type: 'VIEW_MODE_TOGGLED';
};

export type ViewMode = 'chart' | 'table';

export const createBodyMachine = (id: string, initial: ViewMode) =>
  setup({
    types: {
      context: {},
      events: {} as MachineEvent,
    },
  }).createMachine({
    id,
    initial,
    states: {
      chart: {
        on: {
          VIEW_MODE_TOGGLED: 'table',
        },
      },
      table: {
        on: {
          VIEW_MODE_TOGGLED: 'chart',
        },
      },
    },
  });

export const useBodyMachine = (
  id: string,
  initial: ViewMode = 'chart',
): [ViewMode, VoidFunction] => {
  // Needed to avoid infinite re render
  const [machine] = useState(() => createBodyMachine(id, initial));
  const [current, send] = useMachine(machine);
  const onChangeViewMode = useCallback(
    () => send({ type: 'VIEW_MODE_TOGGLED' }),
    [send],
  );

  return [current.value, onChangeViewMode];
};
