interface BodyPart {
  labels: Array<string>;
  data: Array<number>;
  mcid: Array<number>;
}

export type Knee = BodyPart;

export type Hip = BodyPart;

export type LowerBack = BodyPart;

export type Shoulder = BodyPart;

export type Neck = BodyPart;

export type Hoos = BodyPart;

export type Koos = BodyPart;

export class Body {
  constructor(
    public readonly knee: Knee,
    public readonly hip: Hip,
    public readonly lowerBack: LowerBack,
    public readonly shoulder: Shoulder,
    public readonly neck: Neck,
    public readonly hoos: Hoos,
    public readonly koos: Koos,
  ) {}
}
