import { createContext } from 'react';

// eslint-disable-next-line import/no-cycle
import { ageDistributionStore } from 'features/age-distribution';
import { bodyStore } from 'features/body';
import { caseDistributionStore } from 'features/case-distribution';
import { changeInPainScaleStore } from 'features/change-in-pain-level';
import { changeInPsfsStore } from 'features/change-in-psfs-scale';
import { downgradedPatientsStore } from 'features/downgraded-patients';
import { npsStore } from 'features/nps';
import { openTasksStore } from 'features/open-tasks';
import { patientSatisfactionScore } from 'features/patient-satisfaction';
import { recentPatientsStore } from 'features/recent-patients';
import { visitsByInjuryTypeStore } from 'features/visits-by-injury';
import { MetricLogger } from 'lib/sendMetrics';

import { AppStore } from './app.store';
import { DashboardViewModel } from './dashboard.view-model';
import {
  ClinicsSerializer,
  PartnersSerializer,
  TherapistsSerializer,
} from './serializers';

export const appStore = new AppStore(
  caseDistributionStore,
  visitsByInjuryTypeStore,
  ageDistributionStore,
  changeInPainScaleStore,
  changeInPsfsStore,
  bodyStore,
  recentPatientsStore,
  downgradedPatientsStore,
  openTasksStore,
  npsStore,
  patientSatisfactionScore,
  new TherapistsSerializer(),
  new PartnersSerializer(),
  new ClinicsSerializer(),
);
export const metricLogger = new MetricLogger(appStore);
export const dashboardViewModel = new DashboardViewModel(
  appStore,
  ageDistributionStore,
);
export const DashboardViewModelAdapter = createContext(dashboardViewModel);
export const AppStoreAdapter = createContext(appStore);
