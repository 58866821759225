import { createContext, useContext } from 'react';

interface AccordionProps {
  activeIndex: number;
  onAccordionOpen: (index: number) => () => void;
}

interface AccordionItemProps {
  index: number;
  isActive: boolean;
}

export const AccordionContext = createContext<AccordionProps>(
  {} as AccordionProps,
);
export const AccordionItemContext = createContext<AccordionItemProps>(
  {} as AccordionItemProps,
);

export const useAccordion = (): AccordionProps => {
  const value = useContext(AccordionContext);
  if (!value) {
    throw new Error("Can't use useAccordion outside of an accordion provider");
  }
  return value;
};
export const useAccordionItem = (): AccordionItemProps => {
  const value = useContext(AccordionItemContext);
  if (!value) {
    throw new Error(
      "Can't use useAccordionItem outside of an accordion provider",
    );
  }
  return value;
};
