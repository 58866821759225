/* eslint-disable react/jsx-no-bind */
import { captureException } from '@sentry/browser';
// import { metricLogger } from 'bootstrap';
import clsx from 'clsx';
import { format } from 'date-fns/format';
import LogRocket from 'logrocket';
import React, { PropsWithChildren, useEffect } from 'react';

// eslint-disable-next-line import/no-cycle
import { metricLogger } from 'app/routes/Dashboard';
import { Button } from 'components/Button';
import { ConditionalComponent } from 'components/ConditionalComponent';
import { Dropdown, MenuOptions } from 'components/Dropdown';
import { WarningIcon } from 'components/Icons';
import { Modal } from 'components/Modal';
import { PDFViewer } from 'components/PDFViewer';
import { Typography } from 'components/Typography';
import { useToastContext } from 'context/toast-context';
import { ToastType } from 'types';

import { RecentPatientsStoreAdapter } from '../store';
import { ChartAttributes, ChartBox, DownloadMode } from '../types';

import { ChartsAccordion } from './ChartsAccordion';
import { computePhysicianName } from './utils';

interface SharedChartPatientProps {
  patientName: string;
  dob: string;
  physicianName: string;
  physicianId: string;
  patientId: string;
}

export interface SharedChartsProps {
  patientProps: SharedChartPatientProps;
  modalStatus: boolean;
  closeModal: VoidFunction;
  charts: Array<ChartBox>;
  title: string;
  canDownload: boolean;
  isAdmin: boolean;
}
type OptionButtonProps = { selected?: boolean; onClick: () => void };
function OptionButton({
  selected,
  onClick,
  children,
}: PropsWithChildren<OptionButtonProps>) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={clsx(['charts-table-option-button', selected && 'selected'])}
    >
      <span className="charts-table-option-button__text">{children}</span>
    </button>
  );
}

export const SharedCharts: React.FC<SharedChartsProps> = function SharedCharts(
  props,
) {
  const {
    charts,
    modalStatus,
    closeModal,
    title,
    canDownload,
    isAdmin,
    patientProps: { dob, patientName, physicianName, physicianId, patientId },
  } = props;
  const store = React.useContext(RecentPatientsStoreAdapter);
  const { addToast } = useToastContext();
  const [groupedCharts, setGroupedCharts] = React.useState<ChartBox[]>(charts);
  const [chartPDF, setChartPDF] = React.useState<string | null>(null);
  const [chartModal, setChartModal] = React.useState(false);
  const [sessionFilter, setSessionFilter] = React.useState<string>(
    canDownload ? '' : 'completed',
  );
  const [downloadMode, setDownloadMode] = React.useState<DownloadMode>(
    DownloadMode.None,
  );
  const [downloading, setDownloading] = React.useState(false);
  const [selectedCharts, setSelectedCharts] = React.useState<ChartAttributes[]>(
    [],
  );

  const handleChartModal = (url: string, id: string, planOfCareId: string) => {
    setChartPDF(url);
    setChartModal(true);
    metricLogger.sendMetrics({
      patient_name: patientName,
      provider_name: physicianName,
      provider_id: physicianId,
      kind: 'patient_chart',
      extras: {
        patient_id: patientId,
        chart_id: id,
        care_plan_id: planOfCareId,
      },
    });
  };

  useEffect(() => {
    if (downloadMode === DownloadMode.All) {
      setSelectedCharts(
        charts.flatMap((gc) =>
          gc.charts.map((c) => ({
            id: c.id,
            url: c.pdf_url || '',
            date: format(new Date(c.date), 'dd-MM-yyyy'),
          })),
        ),
      );
    }
  }, [downloadMode, charts]);

  useEffect(() => {
    if (
      selectedCharts.length !== charts.length &&
      DownloadMode.All === downloadMode
    ) {
      setDownloadMode(DownloadMode.Select);
    }
  }, [selectedCharts, charts.length, downloadMode]);

  const addOrRemoveSessionFilter = (filter: string) => (): void => {
    if (sessionFilter === filter) {
      setSessionFilter('');
      return;
    }
    setSessionFilter(filter);
  };

  useEffect(() => {
    if (!sessionFilter.length) {
      setGroupedCharts(charts);
      return;
    }
    setGroupedCharts(
      charts
        .map((c) => ({
          ...c,
          charts: c.charts.filter((chart) => chart.state === sessionFilter),
        }))
        .filter((c) => c.charts.length > 0),
    );
  }, [sessionFilter, charts]);

  const onDownloadCharts = (): void => {
    setDownloading(true);
    store
      .downloadAllCharts(selectedCharts, patientName)
      .then(() => {
        addToast('Check email for download', ToastType.SUCCESS);
      })
      .catch((err: Error) => {
        addToast(
          'Oops! There is an error in the download attempt',
          ToastType.ERROR,
        );
        metricLogger.sendMetrics({
          kind: 'error',
          patient_name: patientName,
          extras: {
            text: err.message,
            context: 'download charts',
          },
        });
        captureException(err);
      })
      .finally(() => {
        setDownloading(false);
      });
  };

  const getMessageWhenEmpty = (): string => {
    if (sessionFilter === 'pending') {
      return 'No pending appointments at this time';
    }
    if (sessionFilter === 'cancelled') {
      return 'No cancelled appointments at this time';
    }
    if (sessionFilter === 'completed') {
      return 'No completed appointments at this time';
    }
    return 'No charts available at this time';
  };

  function handleChartSelection(
    chart: ChartAttributes,
    mode: 'add' | 'remove',
  ) {
    if (mode === 'add') {
      setSelectedCharts([...selectedCharts, chart]);
    } else {
      setSelectedCharts(selectedCharts.filter((n) => n.id !== chart.id));
    }
  }
  // e.g: format "2000-01-01" is affected by negative timezone use T00:00:00 to avoid this
  const displayedDob = format(new Date(`${dob}T00:00:00`), 'MM/dd/yyyy');

  return (
    <>
      {chartModal && (
        <Modal
          show={chartModal}
          handleClose={() => setChartModal(false)}
          size="large"
          level={3}
        >
          <PDFViewer src={chartPDF || undefined} />
        </Modal>
      )}
      <Modal
        show={modalStatus}
        handleClose={closeModal}
        size="medium"
        title={title}
      >
        <div className="charts-table-header__container">
          <div className="charts-table__header-info">
            <Typography variant="caption" color="gray-chateau" component="p">
              Physician: {computePhysicianName(physicianName)}
            </Typography>
            <Typography variant="caption" color="gray-chateau" component="p">
              Patient: {patientName}
            </Typography>
            <Typography variant="caption" color="gray-chateau" component="p">
              DOB: {displayedDob}
            </Typography>
          </div>
          <ConditionalComponent
            validate={canDownload && !isAdmin && charts.length > 0}
          >
            <Dropdown
              MenuItem={MenuOptions}
              placeholder="Select to download"
              selectedOption={downloadMode}
              options={[
                {
                  label: 'Select to download',
                  index: 0,
                  value: DownloadMode.Select,
                },
                { label: 'Download all', index: 1, value: DownloadMode.All },
              ]}
              onChange={(val: DownloadMode) => {
                setDownloadMode(val);
              }}
            />
          </ConditionalComponent>
          <ConditionalComponent validate={!canDownload && charts.length > 0}>
            <div className="charts-table-session-options__container">
              <OptionButton
                onClick={addOrRemoveSessionFilter('completed')}
                selected={sessionFilter === 'completed'}
              >
                Completed
              </OptionButton>
              <OptionButton
                onClick={addOrRemoveSessionFilter('pending')}
                selected={sessionFilter === 'pending'}
              >
                Pending
              </OptionButton>
              <OptionButton
                onClick={addOrRemoveSessionFilter('cancelled')}
                selected={sessionFilter === 'cancelled'}
              >
                Cancelled
              </OptionButton>
            </div>

            <Dropdown
              MenuItem={MenuOptions}
              placeholder="Select an option"
              selectedOption=""
              options={[
                { label: 'Completed', index: 0, value: 'completed' },
                { label: 'Pending', index: 1, value: 'pending' },
                { label: 'Cancelled', index: 2, value: 'cancelled' },
              ]}
              onChange={(val: string) => {
                addOrRemoveSessionFilter(val)();
              }}
            />
          </ConditionalComponent>
        </div>
        {groupedCharts.length > 0 ? (
          <ChartsAccordion
            groupedCharts={groupedCharts}
            downloadMode={downloadMode}
            handleChartModal={handleChartModal}
            handleChartSelected={handleChartSelection}
            canDownload={canDownload}
            chartIsSelected={(id: string) =>
              selectedCharts.some((n) => n.id === id)
            }
          />
        ) : (
          <div className="empty-status__wrapper">
            <div className="empty-status-container">
              <div className="empty-status__icon-container">
                <WarningIcon className="warning__icon" />
              </div>
              <Typography
                className="empty-status__text"
                variant="body"
                color="darkness"
                component="p"
                fontWeight="bold"
              >
                {getMessageWhenEmpty()}
              </Typography>
            </div>
            <div className="empty-status__footer">
              <ConditionalComponent validate={charts.length === 0}>
                <Button
                  className="empty-status__close-button"
                  onClick={closeModal}
                >
                  Close
                </Button>
              </ConditionalComponent>
            </div>
          </div>
        )}
        <div className="charts-table-download-container">
          <span className="chart-table-download__items-selected">
            {downloadMode !== DownloadMode.None &&
              `${selectedCharts.length} items selected`}
          </span>
          <ConditionalComponent
            validate={canDownload && charts.length > 0 && !isAdmin}
          >
            <Button
              variant="primary"
              className="charts-table-download-button"
              onClick={onDownloadCharts}
              disabled={downloading || selectedCharts.length === 0}
            >
              Download
            </Button>
          </ConditionalComponent>
        </div>
      </Modal>
    </>
  );
};
