import { SerializerError } from 'errors';
import { PartnersResponse } from 'types';

import { Partner } from '../../models';

interface PartnerSerializerType {
  partners: Array<Partner>;
}

export class PartnersSerializer {
  deserialize(target: PartnersResponse): PartnerSerializerType {
    const {
      info: { data },
    } = target;

    if (data === undefined) {
      return { partners: [] };
    }

    data.forEach((partner) => {
      if (typeof partner.name !== 'string') {
        throw new SerializerError(
          'TherapistSerializer',
          `therapist names are expected to be string`,
        );
      }
    });

    const partners = data.map((partner) => new Partner(partner));
    return { partners };
  }
}
