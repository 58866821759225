import { capitalize } from './capitalize';

const specialCases: Record<string, string> = {
  bsw: 'BSW',
  scl: 'SCL',
  usph: 'USPh',
};

export const formatClinicName = (key: string) => {
  const formmatedKey = key
    .split('_')
    .map((w) => specialCases[w] || capitalize(w))
    .join(' ');
  return formmatedKey;
};
