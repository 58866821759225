import { SerializerError } from 'errors';
import { TherapistsResponse, PermissionsResponse } from 'types';

import { Therapist } from '../../models';

interface TherapistsSerializerType {
  therapists: Array<Therapist>;
  group?: {
    name: string;
    id: string;
  };
  partner?: {
    name: string;
    id: string;
  };
  clinic?: {
    name: string;
    id: string;
  };
  permissions: PermissionsResponse;
}

export class TherapistsSerializer {
  deserialize(target: TherapistsResponse): TherapistsSerializerType {
    const {
      info: { data, group, partner, permissions, clinic },
    } = target;

    data.forEach((therapist) => {
      if (typeof therapist.id !== 'string') {
        throw new SerializerError(
          'TherapistSerializer',
          'therapist ids are expected to be string',
        );
      }

      if (typeof therapist.name !== 'string') {
        throw new SerializerError(
          'TherapistSerializer',
          `therapist names are expected to be string`,
        );
      }

      if (typeof therapist.prefix !== 'string') {
        throw new SerializerError(
          'TherapistSerializer',
          'therapist prefixes are expected to be string',
        );
      }
      /* if (typeof therapist.hubspot_id !== 'string') {
        throw new SerializerError('TherapistSerializer', 'therapist hubspot ids are expected to be string');
      } */
    });

    const therapists = data.map((therapist) => new Therapist(therapist));
    return { therapists, group, partner, clinic, permissions };
  }
}
