/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import clsx from 'clsx';
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useVirtual, VirtualItem } from 'react-virtual';

import { ConditionalComponent } from 'components/ConditionalComponent';
import {
  InfoIcon,
  OrderIconOriginal,
  OrderIconSortDown,
  OrderIconSortUp,
} from 'components/Icons';
import { Typography } from 'components/Typography';
import useMediaQuery from 'hooks/useMediaQuery';
import { computeInjuryName, sortColumnsTable } from 'utils';

import { RecentPatientsStoreAdapter } from '../store';
import { PatientChartInfo, SuccessProps } from '../types';

import {
  computeFunctionScaleMessage,
  computePSFSAndPainScaleValue,
  isNullRomValue,
  parseRomValue,
} from './utils';

const TABLE_BODY_ROW_HEIGHT = 56;
const MAX_BODY_ROWS = 10;

interface IconProps {
  order: string;
}

const OrderIcon: React.FC<IconProps> = function OrderIcon({ order }) {
  if (order === 'original') {
    return <OrderIconOriginal />;
  }
  if (order === 'decrescent' || order === 'ordered') {
    return <OrderIconSortDown />;
  }

  return <OrderIconSortUp />;
};

function EmptyBody() {
  return (
    <tr>
      <td colSpan={6} style={{ textAlign: 'center', paddingTop: '1em' }}>
        <Typography variant="heading3">No Results</Typography>
        <Typography variant="parragraph" color="gray-chateau" component="p">
          There are no patients matching your search or filter criteria.
        </Typography>
      </td>
    </tr>
  );
}

const INITIAL_ORDER = {
  painScale: 'original',
  psfsScale: 'original',
  functionalScale: 'original',
  sessions: 'original',
  injuryName: 'original',
  rom_extension: 'original',
};
export function Success(props: SuccessProps) {
  const { model, handleCharts, shouldHideRom } = props;

  const matchesMobileQuery = useMediaQuery('(max-width: 768px)');

  const store = useContext(RecentPatientsStoreAdapter);

  function onSearchUpdated(e: ChangeEvent<HTMLInputElement>) {
    store.setFilteredPatients(e.target.value);
  }

  const ORDER_COLOR = ['dark-green', 'yellow', 'light-green'];

  const [orderedModel, setOrderedModel] = useState([...model]);
  const [order, setOrder] = useState(INITIAL_ORDER);
  const [maxHeight, setMaxHeight] = useState(-1);

  const getLatest = (raw: string) => parseInt(raw.split('/')[0], 10);
  const getIndexColor = (raw: string) => ORDER_COLOR.indexOf(raw);

  const getExactValue = (raw: string) => raw;

  const getRomValue = (extension: string) =>
    isNullRomValue(extension)
      ? Number.MIN_SAFE_INTEGER
      : parseInt(extension, 10);

  const GET_SORT_VALUE = {
    functionalScale: getIndexColor,
    injuryName: getExactValue,
    rom_extension: getRomValue,
  };

  useEffect(() => {
    setOrderedModel(model);
    setOrder(INITIAL_ORDER);
  }, [model]);

  const handleOrder = (column: string) => {
    const { orderedList, newOrder } = sortColumnsTable({
      list: [...model],
      column,
      deepColumn: column === 'functionalScale' ? 'color' : null,
      // @ts-ignore
      actualOrder: order[column],
      specificOrder: null,
      // @ts-ignore
      extractFunction: GET_SORT_VALUE[column] || getLatest,
    });
    setOrderedModel([...orderedList]);
    setOrder({ ...INITIAL_ORDER, [column]: newOrder });
  };

  const renderTableRow = (v: VirtualItem) => {
    const patient = orderedModel[v.index];
    const hasEscalatedData = !!patient.escalatedMessage;
    return (
      <tr
        key={patient.key}
        className={clsx([
          'recent-patients__table-row',
          patient.discharged && 'recent-patients__table-row--discharged',
        ])}
      >
        <td>
          <Typography
            variant="link"
            component="p"
            color="blue-link"
            className="recent-patients__name-column"
            style={{ display: 'inline-block', verticalAlign: 'middle' }}
            onClick={() => {
              const info: PatientChartInfo = {
                dob: patient.date_of_birth,
                patientName: patient.name,
                patientId: patient.patientId,
                physicianId: patient.physicianId,
                partnerId: patient.partnerId,
                physicianName: patient.physicianName,
              };
              handleCharts(info, true);
            }}
          >
            {patient.name}
          </Typography>
          {hasEscalatedData && (
            <>
              <InfoIcon
                id={`escalatedPatient-${patient.key}`}
                className="recent-patients__escalated-icon"
              />
              <ReactTooltip
                anchorSelect={`#escalatedPatient-${patient.key}`}
                variant="dark"
                place="top"
                className="patient-treated__tooltip"
              >
                <span>{patient.escalatedMessage}</span>
              </ReactTooltip>
            </>
          )}
        </td>
        <td>
          <Typography
            variant="caption"
            component="p"
            color="abbey"
            className="center__on-text"
          >
            {computeInjuryName(patient.injuryName)}
          </Typography>
        </td>
        <td>
          <Typography
            variant="caption"
            component="p"
            color="abbey"
            className="center__on-text"
          >
            {computePSFSAndPainScaleValue(patient.painScale)}
          </Typography>
        </td>
        <td>
          <Typography
            variant="caption"
            component="p"
            color="abbey"
            className="center__on-text"
          >
            {computePSFSAndPainScaleValue(patient.psfsScale)}
          </Typography>
        </td>
        {!shouldHideRom && (
          <td>
            <Typography
              variant="caption"
              component="p"
              color="abbey"
              className="center__on-text"
            >
              {parseRomValue(patient.rom_extension, patient.rom_flexion)}
            </Typography>
          </td>
        )}
        {patient.functionalScale !== null ? (
          <td>
            <div
              data-tooltip-id={`functionalScale-${patient.key}`}
              data-tooltip-content={computeFunctionScaleMessage(
                patient.functionalScale,
                patient,
              )}
              className={clsx(
                'recent-patients__functional-scale',
                patient.functionalScale &&
                  `recent-patients__functional-scale--${patient.functionalScale.color}`,
              )}
            />
            <ReactTooltip
              id={`functionalScale-${patient.key}`}
              place="bottom"
              variant="dark"
              className="recent-patients__functional-scale--tooltip"
            />
          </td>
        ) : (
          <td />
        )}
        <td>
          <Typography
            variant="link"
            component="p"
            color="blue-link"
            className="center__on-text"
            onClick={() => {
              const info: PatientChartInfo = {
                dob: patient.date_of_birth,
                patientName: patient.name,
                patientId: patient.patientId,
                physicianId: patient.physicianId,
                partnerId: patient.partnerId,
                physicianName: patient.physicianName,
              };
              handleCharts(info, false);
            }}
          >
            {patient.sessions}
          </Typography>
        </td>
      </tr>
    );
  };

  // The parent element whose inner-content is scrollable
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: orderedModel.length,
    overscan: 10,
    estimateSize: useCallback(() => TABLE_BODY_ROW_HEIGHT, []),
  });

  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
      : 0;

  const isEmpty = virtualRows.length === 0;

  useEffect(() => {
    if (tableContainerRef.current) {
      setMaxHeight(tableContainerRef.current.clientHeight);
    }
  }, [tableContainerRef]);

  return (
    <div
      className="recent-patients__table-wrapper"
      ref={tableContainerRef}
      style={{ height: maxHeight > 0 ? maxHeight : 'inherit' }}
    >
      <table
        className="recent-patients__table"
        style={{ height: isEmpty ? '100%' : 'auto' }}
      >
        <thead className="recent-patients__table-header">
          <tr>
            <th>
              <div className="recent-patients__table-header-patient header-with-filter">
                <Typography variant="caption" color="darkness" component="p">
                  Patient
                </Typography>
                <ConditionalComponent validate={!matchesMobileQuery}>
                  <input
                    className="user-input"
                    placeholder="Search for Patient"
                    value={store.searchTerm}
                    onChange={onSearchUpdated}
                  />
                </ConditionalComponent>
              </div>
            </th>
            <th onClick={() => handleOrder('injuryName')}>
              <div className="recent-patients__table-head">
                <Typography variant="caption" color="darkness" component="p">
                  Injury
                  <br />
                  Name
                </Typography>
                <OrderIcon order={order.injuryName} />
              </div>
            </th>
            <th onClick={() => handleOrder('painScale')}>
              <div className="recent-patients__table-head">
                <Typography variant="caption" color="darkness" component="p">
                  Pain Score
                  <br />
                  Latest/Intake
                </Typography>
                <OrderIcon order={order.painScale} />
              </div>
            </th>
            <th onClick={() => handleOrder('psfsScale')}>
              <div className="recent-patients__table-head">
                <Typography variant="caption" color="darkness" component="p">
                  PSFS Score
                  <br />
                  Latest/Intake
                </Typography>
                <OrderIcon order={order.psfsScale} />
              </div>
            </th>
            {!shouldHideRom && (
              <th onClick={() => handleOrder('rom_extension')}>
                <div className="recent-patients__table-head">
                  <Typography variant="caption" color="darkness" component="p">
                    ROM
                    <br />
                    Extension/Flexion
                  </Typography>
                  <OrderIcon order={order.rom_extension} />
                </div>
              </th>
            )}
            <th onClick={() => handleOrder('functionalScale')}>
              <div className="recent-patients__table-head">
                <Typography variant="caption" color="darkness" component="p">
                  Functional
                  <br />
                  Scale
                </Typography>
                <OrderIcon order={order.functionalScale} />
              </div>
            </th>
            <th onClick={() => handleOrder('sessions')}>
              <div className="recent-patients__table-head">
                <Typography variant="caption" color="darkness" component="p">
                  Sessions
                  <br />
                  Completed/Pending
                </Typography>
                <OrderIcon order={order.sessions} />
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="recent-patients__table-body">
          {paddingTop > 0 && (
            <tr>
              <td style={{ height: `${paddingTop}px` }} />
            </tr>
          )}
          {isEmpty ? <EmptyBody /> : virtualRows.map((v) => renderTableRow(v))}
          {paddingBottom > 0 && (
            <tr>
              <td style={{ height: `${paddingBottom}px` }} />
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
