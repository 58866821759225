import * as Sentry from '@sentry/browser';
import LogRocket from 'logrocket';

export default function initSentry() {
  if (process.env.NODE_ENV === 'production') {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN_FRONTEND || '',
      beforeSend(event) {
        const logRocketSession = LogRocket.sessionURL;
        if (logRocketSession !== null) {
          return {
            ...event,
            extra: {
              ...event.extra,
              LogRocket: logRocketSession,
            },
          };
        }
        return event;
      },
    });
  }
}
