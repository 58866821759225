import './BaseStyles.css';
import { BodyChartRow } from 'components/layout';

// eslint-disable-next-line import/no-cycle
import { HipView } from './Hip';
import { HoosView } from './Hoos';
import { KneeView } from './Knee';
import { KoosView } from './Koos';
import { LowerBackView } from './LowerBack';
import { NeckView } from './Neck';
import { ShoulderView } from './Shoulder';

export function BodyView() {
  return (
    <BodyChartRow>
      <KneeView />
      <HipView />
      <LowerBackView />
      <ShoulderView />
      <NeckView />
      <HoosView />
      <KoosView />
    </BodyChartRow>
  );
}
