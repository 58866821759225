/* eslint-disable @typescript-eslint/ban-ts-comment */
import { SerializerError } from 'errors';
import { NPSResponse } from 'types';

import { NPS } from '../nps.model';

export class NPSSerializer {
  deserialize(target: NPSResponse): NPS {
    const {
      info: { data },
    } = target;
    if (typeof data.nps !== 'number') {
      throw new SerializerError('NPSSerializer', 'data.nps should be a number');
    }
    Object.keys(data.promoters).forEach((v) => {
      // @ts-ignore
      if (typeof data.promoters[v] !== 'number') {
        throw new SerializerError(
          'NPSSerializer',
          `data.promoters.${v} should be a number`,
        );
      }
    });
    Object.keys(data.passives).forEach((v) => {
      // @ts-ignore
      if (typeof data.passives[v] !== 'number') {
        throw new SerializerError(
          'NPSSerializer',
          `data.passives.${v} should be a number`,
        );
      }
    });
    Object.keys(data.detractors).forEach((v) => {
      // @ts-ignore
      if (typeof data.detractors[v] !== 'number') {
        throw new SerializerError(
          'NPSSerializer',
          `data.detractors.${v} should be a number`,
        );
      }
    });
    return new NPS({
      data: [data.promoters.total, data.passives.total, data.detractors.total],
      percentages: [
        data.promoters.percentage,
        data.passives.percentage,
        data.detractors.percentage,
      ],
      value: data.nps,
    });
  }
}
