/* eslint-disable @typescript-eslint/no-explicit-any */
import { captureException } from '@sentry/browser';
import { useMachine } from '@xstate/react';
import React, { PropsWithChildren, useContext } from 'react';
import { SnapshotFrom, assign, setup } from 'xstate';

import { OpenTaskSerializer } from '../serializer';
import {
  MachineEvent,
  NoteViewMode,
  SignContext,
  SignMachineEvent,
  SignNoteOpenTaskSet,
  SignOpenTaskSet,
  SignOptions,
  ValidationNoteSet,
  ViewMode,
} from '../types';

import { OpenTasksStore } from './open-tasks.store';

export const openTasksStore = new OpenTasksStore(new OpenTaskSerializer());
export const OpenTasksStoreAdapter = React.createContext(openTasksStore);

interface OpenTaskContextProps {
  store: OpenTasksStore;
  onChangeViewMode: () => void;
  mode: ViewMode;
  currentSign: SnapshotFrom<typeof signMachine>;
  noteView: NoteViewMode;
  onChangeSignValue: (s: SignOptions) => void;
  onChangeNote: (v: string) => void;
  signOpenTaskEvent: () => Promise<void>;
  reset: () => void;
  isSigningPOC: boolean;
}

const machine = setup({
  types: {
    context: {},
    events: {} as MachineEvent,
  },
}).createMachine({
  id: 'Open Tasks view',
  initial: 'resume',
  states: {
    expanded: {
      on: {
        VIEW_MODE_TOGGLED: 'resume',
      },
    },
    resume: {
      on: {
        VIEW_MODE_TOGGLED: 'expanded',
      },
    },
  },
});

const signMachine = setup({
  types: {
    context: {} as SignContext,
    events: {} as SignMachineEvent,
  },
  actions: {
    setSignValue: assign({
      signValue: ({ event }) => {
        const { value } = event as SignOpenTaskSet;

        return value;
      },
    }),
    setSignNoteValue: assign({
      signNote: ({ event }) => {
        const { value } = event as SignNoteOpenTaskSet;

        return value;
      },
      validNote: true,
    }),
    resetToInitial: assign({
      signNote: '',
      signValue: 'approveSign',
      validNote: true,
    }),
    setValidationNote: assign({
      validNote: ({ event }) => {
        const { value } = event as ValidationNoteSet;

        return value;
      },
    }),
  },
}).createMachine({
  id: 'Open task note visibility',
  initial: 'sign',
  context: {
    signValue: 'approveSign',
    signNote: '',
    validNote: true,
  },
  states: {
    sign: {
      on: {
        VIEW_NOTE_TOGGLED: 'signWithNote',
        SIGN_OPEN_TASK_SET: {
          actions: 'setSignValue',
        },
        RESET_INITIAL: {
          actions: 'resetToInitial',
        },
      },
    },
    signWithNote: {
      on: {
        VIEW_NOTE_TOGGLED: 'sign',
        SIGN_OPEN_TASK_SET: {
          actions: 'setSignValue',
        },
        SIGN_NOTE_SET: {
          actions: 'setSignNoteValue',
        },
        RESET_INITIAL: {
          actions: 'resetToInitial',
        },
        SET_VALIDATION_NOTE: {
          actions: 'setValidationNote',
        },
      },
    },
  },
});

const OpenTaskContext = React.createContext<OpenTaskContextProps>(
  {} as OpenTaskContextProps,
);

export default OpenTaskContext;

export function OpenTaskContextProvider({ children }: PropsWithChildren) {
  const store = useContext(OpenTasksStoreAdapter);
  const [current, send] = useMachine(machine);
  const [currentSign, sendSign] = useMachine(signMachine);

  const noteView: NoteViewMode = currentSign.matches('sign')
    ? 'sign'
    : 'signWithNote';

  const onChangeNote = (value: string) =>
    sendSign({ type: 'SIGN_NOTE_SET', value });
  const onChangeSignValue = (value: SignOptions) => {
    if (
      (noteView === 'sign' && value === 'approveSignWithNote') ||
      (noteView === 'signWithNote' &&
        (value === 'approveSign' || value === 'noApproveSign'))
    ) {
      sendSign({ type: 'VIEW_NOTE_TOGGLED' });
    }
    sendSign({ type: 'SIGN_OPEN_TASK_SET', value });
  };

  const reset = () => {
    if (noteView === 'signWithNote') sendSign({ type: 'VIEW_NOTE_TOGGLED' });
    sendSign({ type: 'RESET_INITIAL' });
    store.setSignStatus('idle');
  };

  const signOpenTask = async () => {
    const sign =
      currentSign.context.signValue === 'approveSign' ||
      currentSign.context.signValue === 'approveSignWithNote';

    // In case
    if (
      currentSign.context.signValue === 'approveSignWithNote' &&
      currentSign.context.signNote === ''
    ) {
      sendSign({ type: 'SET_VALIDATION_NOTE', value: false });
    } else {
      await store.signOpenTask(sign, currentSign.context.signNote);
    }
  };

  const mode: ViewMode = current.matches('expanded') ? 'expanded' : 'resume';
  const onChangeViewMode = () => send({ type: 'VIEW_MODE_TOGGLED' });
  const [isSigning, setIsSigning] = React.useState(false);

  const signOpenTaskEvent = async () => {
    setIsSigning(true);
    try {
      await signOpenTask();
    } catch (err) {
      captureException(err);
      // rethrow error for metrics
      throw err;
    } finally {
      setIsSigning(false);
    }
  };

  return (
    <OpenTaskContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        store,
        currentSign,
        noteView,
        onChangeNote,
        onChangeSignValue,
        signOpenTaskEvent,
        reset,
        mode,
        onChangeViewMode,
        isSigningPOC: isSigning,
      }}
    >
      {children}
    </OpenTaskContext.Provider>
  );
}
