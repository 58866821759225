import React from 'react';

import { ChangeInPsfsScaleSerializer } from '../serializer';

import { ChangeInPsfsScaleStore } from './change-in-psfs-scale.store';

export const changeInPsfsStore = new ChangeInPsfsScaleStore(
  new ChangeInPsfsScaleSerializer(),
);

export const ChangeInPsfsScaleAdapter = React.createContext(changeInPsfsStore);
