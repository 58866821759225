import clsx from 'clsx';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { Checkbox } from 'components/Checkbox';
import { Typography } from 'components/Typography';
import { DropdownItemProps } from 'types';

export function RadioOptions({
  isSelected,
  tooltip,
  label,
  onClick,
}: DropdownItemProps) {
  return (
    <div
      className="select-menu__radio-container dropdown__radio"
      onClick={onClick}
      onKeyUp={onClick}
      role="option"
      aria-selected={isSelected}
      tabIndex={0}
    >
      <div
        className={clsx([
          'radioButton__container',
          isSelected && 'radioButton__containerChecked',
        ])}
      >
        <input
          type="radio"
          tabIndex={-1}
          className={clsx(['radioButton__real'])}
        />
      </div>
      <Typography
        component="span"
        color="light-dark"
        data-tooltip-content={tooltip}
        data-tooltip-id="radio-option-tooltip"
      >
        {label}
      </Typography>
      {tooltip && (
        <ReactTooltip
          id="radio-option-tooltip"
          place="bottom"
          variant="dark"
          className="recent-patients__functional-scale--tooltip"
        />
      )}
    </div>
  );
}

export function SquareOption({
  isSelected,
  label,
  onClick,
  tooltip,
}: DropdownItemProps) {
  return (
    <div
      className="menu-item"
      onClick={onClick}
      onKeyUp={onClick}
      role="option"
      tabIndex={0}
      aria-selected={isSelected}
    >
      <Checkbox selected={isSelected} />
      <span
        className="label"
        data-tooltip-content={tooltip}
        data-tooltip-id="square-option-tooltip"
      >
        {label}
      </span>
      {tooltip && (
        <ReactTooltip
          id="square-option-tooltip"
          place="right"
          variant="dark"
          className="recent-patients__functional-scale--tooltip"
        />
      )}
    </div>
  );
}

export function MenuOptions({
  isSelected,
  label,
  value,
  onClick,
  tooltip,
}: DropdownItemProps) {
  return (
    <div
      className="menu-item"
      onClick={onClick}
      onKeyUp={onClick}
      tabIndex={0}
      role="option"
      aria-selected={isSelected}
      data-tooltip-content={tooltip}
      data-tooltip-id="simple-option-tooltip"
    >
      <span className="label">{label}</span>
      {tooltip && (
        <ReactTooltip
          id="simple-option-tooltip"
          place="right"
          variant="dark"
          className="recent-patients__functional-scale--tooltip"
        />
      )}
    </div>
  );
}
