import { SerializerError } from 'errors';
import { AgeDistributionResponse } from 'types';

import { AgeDistribution } from '../age-distribution.model';

export class AgeDistributionSerializer {
  // eslint-disable-next-line class-methods-use-this
  deserialize(target: AgeDistributionResponse): AgeDistribution {
    const {
      info: { data },
    } = target;

    const femaleLabelsAreString = data.female.age_ranges.every(
      (label) => typeof label === 'string',
    );
    if (!femaleLabelsAreString) {
      throw new SerializerError(
        'AgeDistributionSerializer',
        'data.female.age_ranges items are expected to be string',
      );
    }

    const femaleDataIsNumeric = data.female.data.every(
      (x) => typeof x === 'number',
    );
    if (!femaleDataIsNumeric) {
      throw new SerializerError(
        'AgeDistributionSerializer',
        'data items are expected to be numeric arrays',
      );
    }

    const maleLabelsAreString = data.male.age_ranges.every(
      (label) => typeof label === 'string',
    );
    if (!maleLabelsAreString) {
      throw new SerializerError(
        'AgeDistributionSerializer',
        'data.female.age_ranges items are expected to be string',
      );
    }

    const maleDataIsNumeric = data.male.data.every(
      (x) => typeof x === 'number',
    );
    if (!maleDataIsNumeric) {
      throw new SerializerError(
        'AgeDistributionSerializer',
        'data items are expected to be numeric arrays',
      );
    }

    const totalPatientsIsNumeric = typeof data.total_patients === 'number';
    if (!totalPatientsIsNumeric) {
      throw new SerializerError(
        'AgeDistributionSerializer',
        'data total_patients is expected to be numeric',
      );
    }

    return new AgeDistribution({
      labels: data.male.age_ranges,
      data: [data.female.data, data.male.data],
      totalPatients: data.total_patients,
    });
  }
}
