export interface NPSModel {
  data: number[];
  percentages: number[];
  value: number;
}

export class NPS {
  public data: NPSModel['data'];

  public percentages: NPSModel['percentages'];

  public value: NPSModel['value'];

  constructor(opts: NPSModel) {
    this.data = opts.data;
    this.percentages = opts.percentages;
    this.value = opts.value;
  }

  public get isEmpty() {
    return this.data.reduce((acc, c) => acc + c, 0) < 3;
  }
}
