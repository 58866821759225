export interface ChangeInPsfsScaleOptions {
  data: Array<number>;
  counter: number;
}

export class ChangeInPsfsScale {
  public data: ChangeInPsfsScaleOptions['data'];

  public counter: ChangeInPsfsScaleOptions['counter'];

  constructor(opts: ChangeInPsfsScaleOptions) {
    this.data = opts.data;
    this.counter = opts.counter;
  }
}
