import { useCallback, useEffect, useRef, useState } from 'react';
import { useVirtual } from 'react-virtual';

import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionItemBody,
  AccordionItemBodyRow,
  AccordionItemHeader,
  AccordionItems,
} from 'components/Accordion';
import { Button } from 'components/Button';
import {
  DropdownArrowIcon,
  MinusCircleIcon,
  PlusCircleIcon,
} from 'components/Icons';
import { Typography } from 'components/Typography';
import { computeInjuryName } from 'utils';

import { SuccessProps } from '../types';

const OPEN_TASK_ROW_HEIGHT = 52;
const OPEN_TASK_ROW_OPEN = 236;

export function OpenTaskMobileView({
  model,
  mode,
  onChangeViewMode,
  handlePdfModal,
  handleSignModal,
  generalDashboard,
}: SuccessProps) {
  const newModel = mode === 'resume' ? model.slice(0, 3) : model;

  const containerRef = useRef<HTMLDivElement>(null);

  const [indexOpen, setIndexOpen] = useState(-1);

  const estimateSize = useCallback(
    (i: number) =>
      i === indexOpen ? OPEN_TASK_ROW_OPEN : OPEN_TASK_ROW_HEIGHT,
    [indexOpen],
  );

  const { virtualItems, totalSize } = useVirtual({
    parentRef: containerRef,
    size: newModel.length,
    overscan: 10,
    estimateSize,
  });

  return (
    <Accordion className="open-tasks__accordion" onItemOpen={setIndexOpen}>
      <AccordionHeader>
        <Typography variant="caption" color="gray-chateau" component="span">
          Patient
        </Typography>
        <Typography variant="caption" color="gray-chateau" component="span">
          Plan of Care
        </Typography>
      </AccordionHeader>
      <AccordionItems ref={containerRef} height={totalSize}>
        {virtualItems.map((v) => {
          const task = newModel[v.index];
          return (
            <AccordionItem
              key={task.key}
              index={v.index}
              style={{
                position: 'absolute',
                top: v.start,
                left: 0,
                width: '100%',
                height: v.size,
              }}
            >
              <AccordionItemHeader>
                <div className="open-task__patient-name-container">
                  <Typography variant="caption" component="p" color="abbey">
                    {task.patientName}
                  </Typography>
                </div>
                <div className="open_task__acordion-sign-in-container">
                  <Button
                    className="button-small button-center"
                    disabled={!generalDashboard}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSignModal(task);
                    }}
                  >
                    Sign
                  </Button>
                  <DropdownArrowIcon className="arrow-icon" />
                </div>
              </AccordionItemHeader>
              <AccordionItemBody>
                <AccordionItemBodyRow>
                  <Typography
                    variant="caption"
                    color="gray-chateau"
                    component="p"
                  >
                    Care
                  </Typography>
                  <Typography variant="caption" component="p" color="abbey">
                    {computeInjuryName(task.planOfCare)}
                  </Typography>
                </AccordionItemBodyRow>
                <AccordionItemBodyRow>
                  <Typography
                    variant="caption"
                    color="gray-chateau"
                    component="p"
                  >
                    Initial Visit
                  </Typography>
                  <Typography variant="caption" component="p" color="abbey">
                    {task.initialVisit}
                  </Typography>
                </AccordionItemBodyRow>
                <AccordionItemBodyRow>
                  <Typography
                    variant="caption"
                    color="gray-chateau"
                    component="p"
                  >
                    Visit Type
                  </Typography>
                  <Typography variant="caption" component="p" color="abbey">
                    {task.visitType}
                  </Typography>
                </AccordionItemBodyRow>
                <AccordionItemBodyRow>
                  <Typography
                    variant="link"
                    component="p"
                    color="blue-link"
                    onClick={() => handlePdfModal(task)}
                  >
                    View Chart
                  </Typography>
                </AccordionItemBodyRow>
              </AccordionItemBody>
            </AccordionItem>
          );
        })}
      </AccordionItems>
      {model.length > 3 && (
        <div className="open-tasks__show-more-container">
          <button
            type="button"
            className="open-tasks__show-more-button"
            onClick={onChangeViewMode}
          >
            {mode === 'resume' ? (
              <PlusCircleIcon className="open-tasks__show-more-icon" />
            ) : (
              <MinusCircleIcon className="open-tasks__show-more-icon" />
            )}
            <Typography variant="caption" component="p" color="abbey">
              Show {mode === 'resume' ? 'more' : 'less'}
            </Typography>
          </button>
        </div>
      )}
    </Accordion>
  );
}
