import { RecentPatient } from './recent-patient.model';

export interface ChartBox {
  id: string;
  injury_name: string;
  physician_name: string;
  surgery_date: string;
  charts: Array<ChartBoxVisit>;
}

export interface ChartBoxVisit {
  id: string;
  pdf_url?: string;
  date: string;
  satisfaction_score: 'thumbs_up' | 'thumbs_down' | 'null';
  state: 'completed' | 'pending' | 'cancelled';
}

export interface ChartBoxResponse {
  data: Array<ChartBox>;
}

export interface ChartAttributes {
  id: string;
  url: string;
  date: string;
}

export enum DownloadMode {
  Select,
  All,
  None,
}

export interface PatientChartInfo {
  patientName: string;
  dob: string;
  patientId: string;
  physicianId: string;
  partnerId: string;
  physicianName: string;
}

export interface SuccessProps {
  model: Array<RecentPatient>;
  handleCharts: (info: PatientChartInfo, completed: boolean) => void;
  shouldHideRom: boolean;
}

export interface Rom {
  flexion: string;
  extension: string;
}
