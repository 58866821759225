export const hubspotMapping: Record<string, string> = {
  'Patient - Suspect': 'Pending',
  'Patient - Qualified: Ready to book': 'Pending',
  'Patient - Qualified: Booked in Luxe': 'Booked',
  'Patient - Qualified: No therapist available': 'No Therapist Available',
  'Patient - Unqualified: Not ready yet': 'Pending',
  'Patient - Qualified: Future surgery': 'Pending',
  'Patient - Unqualified: Already been / being treated':
    'Treated by Another Clinic',
  'Patient - Unqualified: Bad contact info': 'Not Able to Connect',
  'Patient - Unqualified: Not interested in home PT': 'Not Interested in Care',
  'Patient - Unqualified: Not currently interested': 'Not Interested in Care',
  'Patient - Unqualified: Doesn’t want to pay': 'Insurance Coverage Issue',
  'Patient - Unqualified: Immobilized / Home health':
    'Not Appropriate for Outpatient',
  'Patient - Unqualified: Not able to connect': 'Not Able to Connect',
  'Patient - Unqualified: Out of area': 'Out of Area',
  'Patient - Unqualified: Unsupported Condition': 'Unsupported Condition',
  'Patient - Unqualified: Other': 'Other',
};
