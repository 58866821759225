import { Card } from 'components/Card';
import { Typography } from 'components/Typography';

export function EmptyState() {
  return (
    <Card className="recent-patients">
      <section>
        <div className="downgraded-patients__header">
          <Typography
            variant="body"
            color="darkness"
            component="h3"
            fontWeight="normal"
            className="recent-patients__title downgraded-patients__title"
          >
            Unseen Patients
          </Typography>
        </div>
        <br />
        <Typography
          variant="parragraph"
          color="abbey"
          className="downgraded-patients_empty-state-title"
        >
          No Patients to Report
        </Typography>
      </section>
    </Card>
  );
}
