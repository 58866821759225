import { createId as cuid } from '@paralleldrive/cuid2';
import { observer } from 'mobx-react-lite';
import { Fragment, useContext } from 'react';
import { Bar } from 'react-chartjs-2';

import { Button } from 'components/Button';
import { Card } from 'components/Card';
// eslint-disable-next-line import/no-cycle
import { ErrorBoundary } from 'components/ErrorBoundary';
import { Progress } from 'components/Progress';
import { Typography } from 'components/Typography';
import { BarProps } from 'types';

import { Shoulder } from '../body.model';
import { BodyAdapter } from '../store/body.context';

import { FallbackUI } from './Fallback';
import { useBodyMachine, ViewMode } from './useBodyMachine';

interface FetchingProps {
  mode: ViewMode;
  onChangeViewMode: VoidFunction;
}

const Fetching: React.FC<FetchingProps> = observer((props) => {
  const { onChangeViewMode, mode } = props;

  return (
    <Card className="knee">
      <section>
        <Typography variant="body" color="darkness" className="knee__title">
          Shoulder/Arm
        </Typography>

        <Button
          variant="link"
          onClick={onChangeViewMode}
          className="knee__view-toggler"
        >
          {mode === 'chart' ? 'View detail' : 'View chart'}
        </Button>

        <Progress show className="knee__placeholder" data-testid="progress" />
      </section>
    </Card>
  );
});

interface DetailTableProps {
  model: Shoulder;
}

const DetailTable: React.FC<DetailTableProps> = function DetailTable(props) {
  const { model } = props;

  return (
    <ul className="knee__detail-table">
      <li className="knee__detail-table-header">
        <ul>
          <li className="knee__detail-table-column">
            <Typography variant="caption" color="gray-chateau" component="p">
              Measure
            </Typography>
          </li>
          <li className="knee__detail-table-column">
            <Typography variant="caption" color="gray-chateau" component="p">
              Cases w/MCID
            </Typography>
          </li>
          <li className="knee__detail-table-column">
            <Typography variant="caption" color="gray-chateau" component="p">
              %
            </Typography>
          </li>
        </ul>
      </li>

      <hr />

      {model.data.map((datum, idx) => (
        <Fragment key={cuid()}>
          <li className="knee__detail-table-row">
            <ul>
              <li className="knee__detail-table-column">
                <Typography variant="parragraph" color="darkness" component="p">
                  {model.labels[idx]} Change
                </Typography>
              </li>

              <li className="knee__detail-table-column">
                <Typography variant="parragraph" color="darkness" component="p">
                  {model.mcid[idx]}
                </Typography>
              </li>

              <li className="knee__detail-table-column">
                <Typography variant="parragraph" color="darkness" component="p">
                  {datum}
                </Typography>
              </li>
            </ul>
          </li>

          {idx !== model.data.length - 1 && <hr />}
        </Fragment>
      ))}
    </ul>
  );
};

interface SuccessProps {
  onChangeViewMode: VoidFunction;
  mode: ViewMode;
  model: Shoulder;
}

const Success: React.FC<SuccessProps> = observer((props) => {
  const { model, onChangeViewMode, mode } = props;

  const isAnEmptyModel = model.data.length === 0;

  if (isAnEmptyModel) {
    return null;
  }

  const barProps: BarProps = {
    data: {
      labels: model.labels,
      datasets: [
        {
          barThickness: 24,
          backgroundColor: '#2FACBF',
          hoverBackgroundColor: '#4EC2D3',
          data: model.data,
        },
      ],
    },
    height: 203,
    options: {
      maintainAspectRatio: false,
      scales: {
        x: {
          grid: {
            display: false,
          },
          ticks: {
            color: '#3C4858',
          },
          beginAtZero: true,
        },
        y: {
          grid: {
            drawBorder: false,
          },
          ticks: {
            stepSize: 20,
            callback(value) {
              return `${value}%`;
            },
            padding: 12,
            color: '#3C4858',
          },
          min: 0,
          max: 100,
          beginAtZero: true,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label(tooltipItem) {
              return `${tooltipItem.parsed.y}%`;
            },
          },
        },
      },
    },
  };

  switch (mode) {
    case 'chart':
      return (
        <Card className="knee">
          <section>
            <Typography
              variant="body"
              color="darkness"
              className="knee__title"
              data-state={mode}
            >
              Shoulder/Arm
            </Typography>

            <Button
              variant="link"
              onClick={onChangeViewMode}
              className="knee__view-toggler"
            >
              View detail
            </Button>

            <div className="knee__chart-container">
              <Typography
                variant="caption"
                color="darkness"
                className="knee__label--y"
              >
                Patients with MCID
              </Typography>
              <div className="knee__chart">
                <Bar {...barProps} data-testid="chart" />
              </div>
            </div>
          </section>
        </Card>
      );

    case 'table':
      return (
        <Card className="knee">
          <section>
            <Typography
              variant="body"
              color="darkness"
              className="knee__title"
              data-state={mode}
            >
              Shoulder/Arm
            </Typography>

            {isAnEmptyModel ? (
              <div className="knee__empty-placeholder">
                <Typography variant="body" color="dark">
                  No data available
                </Typography>
              </div>
            ) : (
              <>
                <Button
                  variant="link"
                  onClick={onChangeViewMode}
                  className="knee__view-toggler"
                >
                  View chart
                </Button>

                <div className="knee__chart-container">
                  <DetailTable model={model} />
                </div>
              </>
            )}
          </section>
        </Card>
      );
    default:
      return null;
  }
});

interface FailedProps {
  onChangeViewMode: VoidFunction;
  mode: ViewMode;
  onRetry: VoidFunction;
  error?: Error;
}

const Failed: React.FC<FailedProps> = observer((props) => {
  const { onRetry, onChangeViewMode, mode } = props;

  return (
    <Card className="knee">
      <section>
        <Typography variant="body" color="darkness" className="knee__title">
          Shoulder/Arm
        </Typography>

        <Button
          variant="link"
          onClick={onChangeViewMode}
          className="knee__view-toggler"
        >
          {mode === 'chart' ? 'View detail' : 'View chart'}
        </Button>

        <div className="knee__placeholder">
          <Typography variant="body" color="dark" className="knee__message">
            An error prevented us from loading this chart
          </Typography>

          <Button variant="primary" onClick={onRetry}>
            Try Again
          </Button>
        </div>
      </section>
    </Card>
  );
});

const ShoulderComponent: React.FC = observer(() => {
  const store = useContext(BodyAdapter);

  const [mode, onChangeViewMode] = useBodyMachine('Shoulder');

  if (store.fetching) {
    return <Fetching mode={mode} onChangeViewMode={onChangeViewMode} />;
  }
  if (store.fetched) {
    return (
      <Success
        mode={mode}
        onChangeViewMode={onChangeViewMode}
        model={store.cache!.shoulder}
      />
    );
  }
  if (store.failure) {
    return (
      <Failed
        mode={mode}
        onChangeViewMode={onChangeViewMode}
        onRetry={() => store.refetch()}
        error={store.error}
      />
    );
  }
  return null;
});

export function ShoulderView() {
  return (
    <ErrorBoundary place="Shoulder" renderError={<FallbackUI />}>
      <ShoulderComponent />
    </ErrorBoundary>
  );
}
